import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Form, Icon, Input, Button, notification, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./antd.css";
import styled from "styled-components";
import LogInBg from "../assets/03_community/login_bg.png";
import Helmet from "react-helmet";
import Header from "../Components/Header";
import MobileHeader from "../Components/MobileHeader";
import TextData from "../Json/forgotPasswordJson.json";

/** Presentational */
import FormWrapper from "./FormWrapper";
import { handleError } from "./HandleError";

/** App theme */
import { colors } from "./Theme";
import "./responsiveforgotpwd.css";
const JoinLayout = styled.div`
  position: absolute;
  /* top: 100px; */
  /* width: 1200px; */
  /* height: 750px; */
  background-color: #1a1a1a;
  /* margin-left: 330px; */
  border: solid 5px #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BackgroundImg = styled.div`
  position: absolute;
  width: 650px;
  height: 650px;
  background-image: url(${LogInBg});
  background-size: cover;
  opacity: 0.4;
`;
const TitleBox = styled.div`
  /* width: 200px; */
  /* height: 10px; */
  position: relative;
  /* top: 40px; */
  /* margin-left: 30px; */
  font-family: "Alfredo";
  /* font-size: 15px; */
  font-weight: 600;
`;
class ForgotPasswordContainer extends React.Component {
  state = {
    username: "",
    redirect: false,
    loading: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { username } = values;

        this.setState({
          loading: true,
          username,
        });

        Auth.forgotPassword(username)
          .then((data) => {
            notification.success({
              message: TextData.success_msg,
              description: TextData.success_description,
              placement: "topRight",
              duration: 1.5,
              onClose: () => {
                this.setState({ redirect: true });
              },
            });
          })
          .catch((err) => {
            notification.error({
              message: TextData.error_msg,
              description: handleError(err.message),
              placement: "topRight",
              duration: 1.5,
            });
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    let mobilebranch;
    const { getFieldDecorator } = this.props.form;
    const { loading, redirect, username } = this.state;
    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>ForgotPassword | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}

        <JoinLayout id="forgot_layout">
          {/* <BackgroundImg /> */}
          <Col lg={55}>
            <TitleBox id="forgot_title">FORGOT PASSWORD</TitleBox>
            <FormWrapper
              onSubmit={this.handleSubmit}
              className="forgot-form"
              // className="login-form"
            >
              <Form.Item className="forgot_form_item">
                {getFieldDecorator("username", {
                  rules: [
                    {
                      required: true,
                      message: TextData.forgot_error01,
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon
                        type="mail"
                        style={{
                          color: colors.white,
                          marginRight: "20px",
                          fontSize: "15px",
                        }}
                      />
                    }
                    placeholder={TextData.forgot_email}
                  />
                )}
              </Form.Item>
              <Form.Item
              //  className="text-center"
              >
                <Row className="forgot_under_nav_box">
                  <Col lg={24}>
                    <Button
                      className="forgot_submit_btn"
                      // style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      // className="login-form-button"
                    >
                      {loading ? (
                        <LoadingOutlined spin style={{ fontSize: "4vw" }} />
                      ) : (
                        TextData.forgot_auth
                      )}
                    </Button>
                  </Col>
                  <Col lg={24}>
                    <Link to="/login">{TextData.forgot_pageB}</Link>
                  </Col>
                </Row>
              </Form.Item>
            </FormWrapper>
            {redirect && (
              <Redirect
                to={{
                  pathname: "/reset-password",
                  search: `?username=${username}`,
                }}
              />
            )}
          </Col>
        </JoinLayout>
      </React.Fragment>
    );
  }
}

export default Form.create()(ForgotPasswordContainer);
