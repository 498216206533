import React from "react";
import bgImage from "../assets/all/pc_bg.jpg";
import styled, { keyframes } from "styled-components";

const blink = keyframes`
0%{
    opacity:0;
}
40%{
    opacity:0.6;
}
80%{
    opacity:1
}
100%{
    opacity:0.4
}`;

const Container = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: url(${bgImage});
  @media screen and (max-width: 1600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }
`;

const Text = styled.div`
  font-family: "Alfredo";
  animation: ${blink} 2s ease-in-out infinite;
  color: white;
  opacity: 0.4;
  display: flex;
`;

const MediaScreen = () => (
  <Container>
    <Text>Editing to</Text>
    <Text>Responsive web</Text>
  </Container>
);

export default MediaScreen;
