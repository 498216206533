import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import MobileHeader from "../../Components/MobileHeader";
import Loader from "../../Components/Loader";
import {
  SLink,
  PF_PortfolioMenuContents as PortfolioMenuContents,
  PF_PortfolioSearchDropBox as PortfolioSearchDropBox,
  PF_PortfolioSearchDropText as PortfolioSearchDropText,
  PF_OverFlowBox as OverFlowBox,
  PF_TagForm as TagForm,
  PF_TagRadioSection as TagRadioSection,
  PF_TagRadioLabel as TagRadioLabel,
  PF_PortfolioSearchInput as PortfolioSearchInput,
  PF_PortfolioDetail as PortfolioDetail,
  PF_PortfolioInnerContainer as PortfolioInnerContainer,
  PF_GreedBox as GreedBox,
  PF_BoxLayout as BoxLayout,
  PF_TagBox as TagBox,
  PF_TagSpan as TagSpan,
  PF_TagContent as TagContent,
  PF_ThumbnailBox as ThumbnailBox,
  PF_ThumbnailContent as ThumbnailContent,
  PF_PortfolioDescriptionContainer as PortfolioDescriptionContainer,
  PF_PortfolioCompanyBox as PortfolioCompanyBox,
  PF_PortfolioCompanyContent as PortfolioCompanyContent,
  PF_PortfolioOneDescriptionBox as PortfolioOneDescriptionBox,
  PF_PortfolioOneDescriptionContent as PortfolioOneDescriptionContent,
} from "../../Components/Layout";
import { IntroLayout, LIntroLayoutMenuBox } from "../../Components/Layout";
import TextData from "../../Json/portfolioJson.json";
import { API, Storage } from "aws-amplify";
import { listPortfolios } from "../../graphql/queries";
import "./responsive.css";

class PortfolioPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      error: null,
      data: [],
      thumbnail: [],
      sortbydate: false,
      sortbyChar: false,
      tagselector: [],
      searchTerm: "",
    };
  }
  // 요것도 한번만 쓰이니깐 따로 호출 안함
  async fetchPortfolio() {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listPortfolios,
        variables: { filter: { pf_permission: { eq: true } }, limit: 1000 },
      })
        .then((portfolio) => {
          if (portfolio) {
            resolve(portfolio.data.listPortfolios.items);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }
  async fetchThumbnail(data) {
    return await new Promise((resolve, reject) => {
      Storage.get(data, {
        contentType: "url",
      }).then((data) => {
        try {
          if (data) {
            this.setState({
              thumbnail: [...this.state.thumbnail, data],
              isloading: true,
            });
            resolve(data);
          }
        } catch (err) {
          console.log(err);
          resolve(null);
        } finally {
          this.setState({ isloading: false });
        }
      });
    });
  }

  async tagSearch(data) {
    let filtered;
    let filterData;
    try {
      filterData = this.state.data.filter((el) =>
        Object.values(el.portfolio_tag).includes(data)
      );
      this.setState({
        data: filterData,
        tagselector: [...this.state.tagselector, data],
      });
      if (this.state.tagselector.includes(data)) {
        filtered = this.state.tagselector.filter((el) => el !== data);

        filterData = await this.state.origin.filter((el) =>
          Object.values(el.portfolio_tag).includes(filtered[0])
        );

        if (filtered.length === 0) {
          filterData = await this.state.origin;
        }

        this.setState({
          data: filterData,
          tagselector: filtered,
        });
      }
      if (this.state.tagselector.length === 2) {
        filterData = this.state.origin.filter((el) =>
          Object.values(el.portfolio_tag).includes(data)
        );
        this.setState({
          data: filterData,
          tagselector: [data],
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  istagSelected(data) {
    if (this.state.tagselector.includes(data)) {
      return true;
    }
    return false;
  }

  async handleSearch(e) {
    let searchdata = [];
    if (e.key === "Enter") {
      searchdata = this.state.data.filter((el) =>
        el.company_name.toLowerCase().includes(e.target.value)
      );
      if (e.target.value.length === 0) {
        this.setState({ data: this.state.origin });
      } else {
        this.setState({ data: searchdata });
      }
    }
  }
  async handleSearchReset(e) {
    if (e.length === 0) {
      this.setState({ data: this.state.origin });
    }
  }

  async sortclick(e) {
    let newarr;
    try {
      if (this.state.sortbydate) {
        return;
      }
      newarr = [...this.state.data].sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      this.setState({
        sortbydate: true,
        sortbyChar: false,
        isloading: true,
        data: newarr,
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        isloading: false,
      });
    }
  }

  async sortChar(e) {
    let newarr;
    try {
      if (this.state.sortbyChar) {
        return;
      }
      newarr = [...this.state.data].sort((a, b) => {
        let fa = a.company_name.toLowerCase(),
          fb = b.company_name.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      this.setState({
        sortbyChar: true,
        sortbydate: false,
        isloading: true,
        data: newarr,
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
    }
  }

  async componentDidMount() {
    let portfolioData;
    try {
      portfolioData = await this.fetchPortfolio();
      if (portfolioData) {
        await portfolioData.map((data) =>
          this.fetchThumbnail(data.portfolio_thumbnail_url)
        );
      }
      this.setState({
        data: portfolioData,
        origin: portfolioData,
      });
    } catch {
    } finally {
      this.setState({ isloading: false });
    }
  }
  render() {
    let mobilebranch;
    const { data, isloading } = this.state;
    function handleScroll(e) {
      var handleHeight = document.getElementById("portfolio_view_container");
      let countvar =
        (handleHeight.scrollHeight - handleHeight.clientHeight) / 360;
      let headerImg = document.getElementById("FFF_LOGO");
      headerImg.style.transform =
        "rotate(" + handleHeight.scrollTop / countvar + "deg)";
    }
    mobilebranch = window.innerWidth;
    if (mobilebranch >= 1280) {
      mobilebranch = false;
    } else if (mobilebranch < 1280) {
      mobilebranch = true;
    }
    return (
      <>
        <Helmet>
          <title>PORTFOLIO | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <IntroLayout id="intro_layout">
          {isloading ? (
            <>
              <Loader />{" "}
            </>
          ) : (
            <>
              <LIntroLayoutMenuBox id="portfolio_view_menubox">
                <PortfolioMenuContents
                  id="portfolio_view_menu_contents"
                  current={this.state.sortbydate}
                  onClick={(e) => this.sortclick(e)}
                >
                  {TextData.PortfolioMenuOption01}
                </PortfolioMenuContents>
                <PortfolioMenuContents
                  id="portfolio_view_menu_contents"
                  current={this.state.sortbyChar}
                  onClick={(e) => this.sortChar(e)}
                >
                  {TextData.PortfolioMenuOption02}
                </PortfolioMenuContents>
              </LIntroLayoutMenuBox>
              <PortfolioDetail
                id="portfolio_view_detail"
                onScroll={(e) => handleScroll(e)}
              >
                <PortfolioInnerContainer id="portfolio_view_container">
                  <GreedBox id="grid_or_flex">
                    {data.map((data, index) => {
                      return (
                        <BoxLayout
                          id="portfolio_innergrid_box_layout"
                          key={index}
                        >
                          <TagBox id="portfolio_innergrid_tagbox">
                            <TagContent
                              className="portfolio_innergrid_tags"
                              contents={data.portfolio_tag.tag_content01}
                            >
                              <TagSpan
                                contents={data.portfolio_tag.tag_content01}
                              >
                                {data.portfolio_tag.tag_content01}
                              </TagSpan>
                            </TagContent>
                            <TagContent
                              className="portfolio_innergrid_tags"
                              contents={data.portfolio_tag.tag_content02}
                            >
                              <TagSpan
                                contents={data.portfolio_tag.tag_content02}
                              >
                                {data.portfolio_tag.tag_content02}
                              </TagSpan>
                            </TagContent>
                            <TagContent
                              className="portfolio_innergrid_tags"
                              contents={data.portfolio_tag.tag_content03}
                            >
                              <TagSpan
                                contents={data.portfolio_tag.tag_content02}
                              >
                                {data.portfolio_tag.tag_content03}
                              </TagSpan>
                            </TagContent>
                          </TagBox>
                          <SLink to={`/portfoliodetail/${data.id}`}>
                            <ThumbnailBox id="portfolio_view_thumbnailBox">
                              <ThumbnailContent
                                id="portfolio_view_thumbnailContent"
                                thumbnail={
                                  this.state.thumbnail[
                                    this.state.thumbnail.findIndex((a) =>
                                      a.includes(
                                        data.id.split("pf-ap-northeast-2:")[1]
                                      )
                                    )
                                  ]
                                }
                              />
                            </ThumbnailBox>
                          </SLink>
                          <PortfolioDescriptionContainer>
                            <PortfolioCompanyBox id="portfolio_company_box">
                              <PortfolioCompanyContent id="portfolio_view_companyname">
                                {data.company_name}
                              </PortfolioCompanyContent>
                            </PortfolioCompanyBox>
                            <PortfolioOneDescriptionBox id="portfolio_desc_box">
                              <PortfolioOneDescriptionContent id="portfolio_view_oneline">
                                {data.portfolio_oneline_description}
                              </PortfolioOneDescriptionContent>
                            </PortfolioOneDescriptionBox>
                          </PortfolioDescriptionContainer>
                        </BoxLayout>
                      );
                    })}
                  </GreedBox>
                </PortfolioInnerContainer>
              </PortfolioDetail>
              <PortfolioSearchDropBox id="portfolioSearchBox">
                <PortfolioSearchDropText className="portfolioSearchDropText">
                  {TextData.PortfolioDropText01}
                </PortfolioSearchDropText>
                <OverFlowBox id="portfolio_overflow">
                  <TagForm>
                    <TagRadioSection>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_01
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_01}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_02
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_02}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_03
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_03}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_04
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_04}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_05
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_05}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_06
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_06}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_07
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_07}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_08
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_08}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_09
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_09}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_10
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_10}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_11
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_11}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_12
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_12}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_13
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_13}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_14
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_14}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_15
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_15}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_16
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_16}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_17
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_17}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_18
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_18}
                      </TagRadioLabel>
                      <TagRadioLabel
                        className="portfolio_taglabels"
                        selected={this.istagSelected(
                          TextData.Portfolio_Label_19
                        )}
                        onClick={(e) =>
                          this.tagSearch(e.currentTarget.innerHTML)
                        }
                      >
                        {TextData.Portfolio_Label_19}
                      </TagRadioLabel>
                    </TagRadioSection>
                  </TagForm>
                </OverFlowBox>
                <PortfolioSearchDropText className="secondSearchDropText">
                  {TextData.PortfolioDropText02}
                </PortfolioSearchDropText>
                <PortfolioSearchInput
                  id="portfolioSearchInput"
                  onKeyDown={(e) => this.handleSearch(e)}
                  onChange={(e) => this.handleSearchReset(e.target.value)}
                />
              </PortfolioSearchDropBox>
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

export default PortfolioPresenter;
