import React from "react";
import styled from "styled-components";
import {
  ErrorBox,
  BoardUnauthorizedBox,
  BoardUnauthorizedBg,
  BoardTextFlexbox,
  BoardUnauthorizedText,
} from "./Layout";
import "./mobileError.css";

const ErrorContent = styled.div`
  width: 100%;
  height: 70%;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 600;
`;
const ErrorBack = styled.div`
  width: 25%;
  height: 3vh;
  border: 1px solid #00ee13;
  background-color: transparent;
  color: #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 1.3vh;
  font-weight: 600;
  cursor: grab;
  /* margin-left: 115px; */
  /* margin-top: 80px; */
  /* white-space: pre-line; */
  margin: 0 auto;
  margin-top: 10px;
  line-height: 1.5rem;
`;

export default ({ error }) => {
  console.log(error);
  function handleOnclick() {
    window.history.back();
  }
  return (
    <>
      <ErrorBox>
        <ErrorContent id="ErrorRes">
          <BoardUnauthorizedBox>
            <BoardUnauthorizedBg />
            <BoardTextFlexbox>
              <BoardUnauthorizedText>{error}</BoardUnauthorizedText>
            </BoardTextFlexbox>
            <ErrorBack onClick={(e) => handleOnclick()}>돌아가기</ErrorBack>
          </BoardUnauthorizedBox>
        </ErrorContent>
      </ErrorBox>
    </>
  );
};
