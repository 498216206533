/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      nick_name
      real_name
      avatar_url
      belong_to
      field
      permission {
        auth_realname
        auth_belongto
        auth_field
        auth_homepage
      }
      homepage
      description
      isAdmin
      phone_number
      membership
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      nick_name
      real_name
      avatar_url
      belong_to
      field
      permission {
        auth_realname
        auth_belongto
        auth_field
        auth_homepage
      }
      homepage
      description
      isAdmin
      phone_number
      membership
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      nick_name
      real_name
      avatar_url
      belong_to
      field
      permission {
        auth_realname
        auth_belongto
        auth_field
        auth_homepage
      }
      homepage
      description
      isAdmin
      phone_number
      membership
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPortfolio = /* GraphQL */ `
  mutation CreatePortfolio(
    $input: CreatePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    createPortfolio(input: $input, condition: $condition) {
      id
      company_name
      business_license
      portfolio_tag {
        tag_content01
        tag_content02
        tag_content03
      }
      portfolio_oneline_description
      portfolio_website
      portfolio_email
      portfolio_attach01 {
        portfolio_link01
        portfolio_url01
      }
      portfolio_attach02 {
        portfolio_link02
        portfolio_url02
      }
      portfolio_attach03 {
        portfolio_link03
        portfolio_url03
      }
      portfolio_full_description
      portfolio_thumbnail_url
      updatedAt
      pf_permission
      user_phone_number
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const updatePortfolio = /* GraphQL */ `
  mutation UpdatePortfolio(
    $input: UpdatePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    updatePortfolio(input: $input, condition: $condition) {
      id
      company_name
      business_license
      portfolio_tag {
        tag_content01
        tag_content02
        tag_content03
      }
      portfolio_oneline_description
      portfolio_website
      portfolio_email
      portfolio_attach01 {
        portfolio_link01
        portfolio_url01
      }
      portfolio_attach02 {
        portfolio_link02
        portfolio_url02
      }
      portfolio_attach03 {
        portfolio_link03
        portfolio_url03
      }
      portfolio_full_description
      portfolio_thumbnail_url
      updatedAt
      pf_permission
      user_phone_number
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const deletePortfolio = /* GraphQL */ `
  mutation DeletePortfolio(
    $input: DeletePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    deletePortfolio(input: $input, condition: $condition) {
      id
      company_name
      business_license
      portfolio_tag {
        tag_content01
        tag_content02
        tag_content03
      }
      portfolio_oneline_description
      portfolio_website
      portfolio_email
      portfolio_attach01 {
        portfolio_link01
        portfolio_url01
      }
      portfolio_attach02 {
        portfolio_link02
        portfolio_url02
      }
      portfolio_attach03 {
        portfolio_link03
        portfolio_url03
      }
      portfolio_full_description
      portfolio_thumbnail_url
      updatedAt
      pf_permission
      user_phone_number
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question_title
      question
      answer_title
      answer
      phone_number
      real_name
      email
      nick_name
      updatedAt
      holding
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question_title
      question
      answer_title
      answer
      phone_number
      real_name
      email
      nick_name
      updatedAt
      holding
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      question_title
      question
      answer_title
      answer
      phone_number
      real_name
      email
      nick_name
      updatedAt
      holding
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      isAdmin
      category
      recruitmentField
      quotedCost
      writer
      title
      description
      file
      link
      updatedAt
      ReadCount
      isLiked {
        id
        postID
        isLiked
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      comments {
        id
        postID
        nick_name
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      isAdmin
      category
      recruitmentField
      quotedCost
      writer
      title
      description
      file
      link
      updatedAt
      ReadCount
      isLiked {
        id
        postID
        isLiked
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      comments {
        id
        postID
        nick_name
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      isAdmin
      category
      recruitmentField
      quotedCost
      writer
      title
      description
      file
      link
      updatedAt
      ReadCount
      isLiked {
        id
        postID
        isLiked
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      comments {
        id
        postID
        nick_name
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      postID
      nick_name
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      postID
      nick_name
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      postID
      nick_name
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const createAdditionalComment = /* GraphQL */ `
  mutation CreateAdditionalComment(
    $input: CreateAdditionalCommentInput!
    $condition: ModelAdditionalCommentConditionInput
  ) {
    createAdditionalComment(input: $input, condition: $condition) {
      id
      postID
      commentID
      nick_name
      content
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAdditionalComment = /* GraphQL */ `
  mutation UpdateAdditionalComment(
    $input: UpdateAdditionalCommentInput!
    $condition: ModelAdditionalCommentConditionInput
  ) {
    updateAdditionalComment(input: $input, condition: $condition) {
      id
      postID
      commentID
      nick_name
      content
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAdditionalComment = /* GraphQL */ `
  mutation DeleteAdditionalComment(
    $input: DeleteAdditionalCommentInput!
    $condition: ModelAdditionalCommentConditionInput
  ) {
    deleteAdditionalComment(input: $input, condition: $condition) {
      id
      postID
      commentID
      nick_name
      content
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createLiked = /* GraphQL */ `
  mutation CreateLiked(
    $input: CreateLikedInput!
    $condition: ModelLikedConditionInput
  ) {
    createLiked(input: $input, condition: $condition) {
      id
      postID
      isLiked
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateLiked = /* GraphQL */ `
  mutation UpdateLiked(
    $input: UpdateLikedInput!
    $condition: ModelLikedConditionInput
  ) {
    updateLiked(input: $input, condition: $condition) {
      id
      postID
      isLiked
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteLiked = /* GraphQL */ `
  mutation DeleteLiked(
    $input: DeleteLikedInput!
    $condition: ModelLikedConditionInput
  ) {
    deleteLiked(input: $input, condition: $condition) {
      id
      postID
      isLiked
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
