import * as React from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Form, Icon, Input, Button, notification, Col, Row } from "antd";
import styled from "styled-components";
import "./antd.css";
import LogInBg from "../assets/03_community/login_bg.png";
import { handleError } from "./HandleError";
/** Presentational */
import FormWrapper from "./FormWrapper";
import Helmet from "react-helmet";
import Header from "../Components/Header";
import MobileHeader from "../Components/MobileHeader";

/** App theme */
import { colors } from "./Theme";
import { LoadingOutlined } from "@ant-design/icons";
import TextData from "../Json/loginJson.json";

/** App constants */
import { AUTH_USER_TOKEN_KEY } from "./constant";
import "./responsivelogin.css";

const JoinLayout = styled.div`
  position: absolute;
  /* top: 100px; */
  /* width: 1200px; */
  /* height: 750px; */
  background-color: #1a1a1a;
  /* margin-left: 330px; */
  border: solid 5px #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BackgroundImg = styled.div`
  position: absolute;
  /* width: 650px; */
  /* height: 650px; */
  background-image: url(${LogInBg});
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.4;
`;
const TitleBox = styled.div`
  /* width: 200px; */
  /* height: 10px; */
  position: relative;
  /* top: 40px; */
  /* margin-left: 35px; */
  font-family: "Alfredo";
  /* font-size: 15px; */
  font-weight: 600;
`;
class LoginContainer extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { username, password } = values;

        this.setState({ loading: true });

        Auth.signIn(username, password)
          .then((user) => {
            localStorage.setItem(
              AUTH_USER_TOKEN_KEY,
              user.signInUserSession.accessToken.jwtToken
            );

            notification.success({
              message: TextData.success_msg,
              description: TextData.success_description,
              placement: "bottom",
              duration: 2.5,
            });
            window.location.replace(`/`);
          })
          .catch((err) => {
            console.log("err Occured");
            console.log(err);
            notification.error({
              message: TextData.error_msg,
              // description: handleError(err.message),
              description: err.message,
              placement: "bottom",
              duration: 1.5,
            });

            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    let mobilebranch;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>LOGIN | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <JoinLayout id="login_layout">
          {/* <BackgroundImg id="background_img" /> */}
          <Col lg={44}>
            <TitleBox id="login_title">
              {/* {TextData.login_content} */}
              LOGIN
            </TitleBox>
            <FormWrapper onSubmit={this.handleSubmit} className="login-form">
              <Form.Item
                className="login_form_item"
                // style={{ marginBottom: "20px" }}
              >
                {getFieldDecorator("username", {
                  rules: [
                    {
                      required: true,
                      message: TextData.email_confirm,
                    },
                  ],
                })(
                  <Input
                    className="login_form_email"
                    prefix={
                      <Icon
                        type="mail"
                        style={{
                          color: colors.white,
                          marginRight: "20px",
                          fontSize: "15px",
                        }}
                      />
                    }
                    placeholder={TextData.placeholder_email}
                  />
                )}
              </Form.Item>
              <Form.Item
                className="login_form_item"
                // style={{ marginBottom: "20px" }}
              >
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: TextData.pwd_confirm,
                    },
                  ],
                })(
                  <Input
                    className="login_form_pwd"
                    prefix={
                      <Icon
                        type="lock"
                        style={{
                          color: colors.white,
                          marginRight: "20px",
                          fontSize: "15px",
                        }}
                      />
                    }
                    type="password"
                    placeholder={TextData.placeholder_pwd}
                  />
                )}
              </Form.Item>
              <Form.Item
                className="login_submit_area"
                // className="text-center"
              >
                <Row type="flex" gutter={16}>
                  <Col lg={24}>
                    <Button
                      // style={{ width: "100%" }}
                      type="primary"
                      disabled={loading}
                      htmlType="submit"
                      className="login_submit_btn"
                      // className="login-form-button"
                    >
                      {loading ? (
                        <LoadingOutlined spin style={{ fontSize: "4vw" }} />
                      ) : (
                        "로그인"
                      )}
                    </Button>
                  </Col>
                  <Col lg={30} className="under_nav_box">
                    <Col lg={24}>
                      <Link to="/join">{TextData.join_content}</Link>
                    </Col>
                    <Col lg={24}>
                      <Link
                        style={{ float: "right" }}
                        className="login_forgot"
                        // className="login-form-forgot"
                        to="/forgot-password"
                      >
                        {TextData.forgot_pwd_content}
                      </Link>
                    </Col>
                  </Col>
                </Row>
              </Form.Item>
            </FormWrapper>
          </Col>
        </JoinLayout>
      </React.Fragment>
    );
  }
}

export default Form.create()(LoginContainer);
