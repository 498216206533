import React from "react";
import Helmet from "react-helmet";
import {
  IntroLayout,
  IntroLayoutMenuOption,
  SLink,
  ProfileContainer,
  BoardLowerMenuContainer,
  MyInfoNavLogoutContainer,
  MyInfoNavLogoutLeftBox,
  MyInfoNavLogoutLeftImg,
  MyInfoNavLogoutLeftContent,
  MyInfoNavLogoutRightBox,
  MyInfoNavLogoutBtn,
  My_Q_SubmitCon as SubmitCon,
  My_Q_IntroDetail as IntroDetail,
  My_Q_MyQuestionContainer as MyQuestionContainer,
  My_Q_MyQuestionBox as MyQuestionBox,
  My_Q_QuestionHeader as QuestionHeader,
  My_Q_MyQuestionTitle as MyQuestionTitle,
  My_Q_MyQuestionDescription as MyQuestionDescription,
  My_Q_QuestionSubmitBtn as QuestionSubmitBtn,
  My_Q_MyAnswerBox as MyAnswerBox,
  My_Q_AnswerHeader as AnswerHeader,
  My_Q_MyAnswerTitle as MyAnswerTitle,
  My_Q_MyAnswerDescription as MyAnswerDescription,
  My_Q_HoldingBox as HoldingBox,
  My_Q_HoldingText as HoldingText,
} from "../../../Components/Layout";
import { withRouter } from "react-router-dom";
import Header from "../../../Components/Header";
import MobileHeader from "../../../Components/MobileHeader";
import TextData from "../../../Json/myquestion.json";
import { API, Storage } from "aws-amplify";
import Loader from "../../../Components/Loader";
import Error from "../../../Components/Error";
import { getQuestion } from "../../../graphql/queries";
import {
  createQuestion as createQuestionM,
  updateQuestion as updateQuestionM,
} from "../../../graphql/mutations";
import {
  getCurrentUserInfo,
  fetchOneUserById,
  onSignOutClick,
} from "../../../Controllers/amplifyController";
import "./questionresponsive.css";

const initialFormState = {
  id: "",
  question_title: "",
  question: "",
  answer_title: "",
  answer: "",
  phone_number: "",
  real_name: "",
  email: "",
  nick_name: "",
  updatedAt: "",
  holding: true,
};

class QuestionPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      formData: initialFormState,
      error: false,
      isCreate: true,
      errmsg: "",
    };
  }
  async handleTime() {
    const { formData } = this.state;
    const currentTime = new Date();
    this.setState({
      formData: { ...formData, updatedAt: currentTime, holding: true },
    });
  }
  async handleSave() {
    const { isCreate } = this.state;
    try {
      await this.handleTime();
      if (isCreate) {
        this.createQuestion(this.state.formData);
      } else {
        this.updateQuestion(this.state.formData);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async createQuestion(formData) {
    if (!formData.id || !formData.question) return;
    try {
      await API.graphql({
        query: createQuestionM,
        variables: { input: formData },
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ formData: initialFormState });
      window.location.reload();
    }
  }

  async updateQuestion(formData) {
    const { questionData } = this.state;
    try {
      await API.graphql({
        query: updateQuestionM,
        variables: {
          input: {
            id: questionData.id,
            _version: questionData._version,
            question_title: formData.question_title,
            question: formData.question,
            updatedAt: formData.updatedAt,
            holding: true,
          },
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ formData: initialFormState });
      window.location.reload();
    }
  }

  async fetchQuestions(id) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: getQuestion,
        variables: { id },
      })
        .then((qna) => {
          if (qna.data.getQuestion) {
            this.setState({
              isCreate: false,
              formData: {
                ...this.state.formData,
                question_title: qna.data.getQuestion.question_title,
                question: qna.data.getQuestion.question,
                answer_title: qna.data.getQuestion.answer_title,
                answer: qna.data.getQuestion.answer,
              },
            });
            resolve(qna.data.getQuestion);
          } else {
            this.setState({ isCreate: true });
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  async handleKeyPress(e) {
    if (e.charCode === 13) {
      e.preventDefault();
    }
  }

  async componentDidMount() {
    let user;
    let profileData;
    let questionData;
    let fetchImg;
    try {
      user = await getCurrentUserInfo();
      questionData = await this.fetchQuestions(`question-${user.id}`);
      profileData = await fetchOneUserById(user.id);
      if (
        profileData === null ||
        profileData === undefined ||
        Object.values(profileData).filter((data) => data === "").length > 2
      ) {
        this.setState({
          error: true,
          errmsg: "프로필을 먼저 완성해주세요!",
          user,
        });
      }
      if (
        (profileData && !profileData.membership) ||
        profileData.membership === null
      ) {
        this.setState({
          error: true,
          errmsg: "관리자 승인 후 이용 가능합니다.",
        });
      }
      if (profileData && profileData.avatar_url) {
        fetchImg = await Storage.get(profileData.avatar_url, {
          contentType: "url",
        });
      }
      if (user !== null) {
        if (questionData) {
          this.setState({
            isloading: true,
            user,
            profileData,
            fetchImg,
            formData: {
              ...this.state.formData,
              id: `question-${user.id}`,
              phone_number: profileData.phone_number,
              real_name: user.attributes.name,
              nick_name: profileData.nick_name,
              email: user.username,
            },
            questionData,
          });
        } else {
          this.setState({
            isloading: true,
            user,
            profileData,
            fetchImg,
            formData: {
              ...this.state.formData,
              id: `question-${user.id}`,
              phone_number: profileData.phone_number,
              real_name: user.attributes.name,
              nick_name: profileData.nick_name,
              email: user.username,
            },
          });
        }
      } else {
        this.setState({
          error: true,
          errmsg: "알 수 없는 에러가 발생하였습니다.",
          user,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false, user });
    }
  }

  render() {
    let mobilebranch;
    const {
      user,
      fetchImg,
      questionData,
      isloading,
      error,
      formData,
    } = this.state;
    const {
      location: { pathname },
      info,
    } = this.props;
    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    return (
      <>
        <Helmet>
          <title>Questions | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <IntroLayout id="question_introlayout">
          {isloading ? (
            <Loader />
          ) : error ? (
            <Error error={this.state.errmsg} />
          ) : (
            <>
              <ProfileContainer id="question_profileContainer">
                <IntroLayoutMenuOption
                  className="question_profileMenuOptions"
                  current={pathname === `/profile/${info}`}
                >
                  <SLink to={`/profile/${info}`}>
                    {TextData.link_to_profile}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="question_profileMenuOptions"
                  current={pathname === `/mypost/${info}`}
                >
                  <SLink to={`/mypost/${info}`}>
                    {TextData.link_to_mypost}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="question_profileMenuOptions"
                  current={pathname === `/questions/${info}`}
                >
                  <SLink to={`/questions/${info}`}>
                    {TextData.link_to_question}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="question_profileMenuOptions"
                  current={pathname === `/myportfolio/${info}`}
                >
                  <SLink to={`/myportfolio/${info}`}>
                    {TextData.link_to_myportfolio}
                  </SLink>
                </IntroLayoutMenuOption>
              </ProfileContainer>
              <IntroDetail>
                <MyQuestionContainer id="question_container">
                  <MyQuestionBox id="question_myqbox">
                    <QuestionHeader className="myq_header">
                      {TextData.MyQuestion_Header}
                    </QuestionHeader>
                    <MyQuestionTitle
                      className="myq_title"
                      onKeyPress={(e) => this.handleKeyPress(e)}
                      placeholder={
                        questionData && questionData.question_title
                          ? questionData.question_title
                          : "질문 제목"
                      }
                      maxLength="60"
                      onChange={(e) =>
                        this.setState({
                          formData: {
                            ...formData,
                            question_title: e.target.value,
                          },
                        })
                      }
                    ></MyQuestionTitle>
                    <MyQuestionDescription
                      className="myq_desc"
                      placeholder={
                        questionData && questionData.question
                          ? questionData.question
                          : "질문 내용"
                      }
                      maxLength="800"
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...formData,
                            question: e.target.value,
                          },
                        });
                      }}
                    ></MyQuestionDescription>
                    <SubmitCon>
                      <QuestionSubmitBtn
                        className="myq_btn"
                        onClick={() => this.handleSave()}
                      >
                        {TextData.MyQuestion_Btn}
                      </QuestionSubmitBtn>
                    </SubmitCon>
                  </MyQuestionBox>
                  <MyAnswerBox id="question_myabox">
                    <AnswerHeader className="myq_header">
                      {TextData.MyAnswer_Header}
                    </AnswerHeader>
                    {!questionData ? (
                      <>
                        <HoldingBox className="myq_holdingbox"></HoldingBox>
                        <HoldingText className="myq_holdingtext">
                          {TextData.MyAnswer_Text}
                        </HoldingText>
                      </>
                    ) : questionData && questionData.holding === true ? (
                      <>
                        <HoldingBox className="myq_holdingbox"></HoldingBox>
                        <HoldingText className="myq_holdingtext">
                          {TextData.MyAnswer_Holding_State}
                        </HoldingText>
                      </>
                    ) : questionData && questionData.holding === false ? (
                      <>
                        <MyAnswerTitle
                          className="myq_title"
                          defaultValue={
                            questionData && questionData.answer_title
                              ? questionData.answer_title
                              : "데이터 없는 상태"
                          }
                          disabled
                        ></MyAnswerTitle>
                        <MyAnswerDescription
                          className="myq_desc"
                          disabled
                          defaultValue={
                            questionData && questionData.answer
                              ? questionData.answer
                              : "데이터 없는 상태"
                          }
                        ></MyAnswerDescription>
                      </>
                    ) : (
                      <></>
                    )}
                  </MyAnswerBox>
                </MyQuestionContainer>
              </IntroDetail>
              <BoardLowerMenuContainer id="myinfo_togglemenu">
                <MyInfoNavLogoutContainer>
                  <MyInfoNavLogoutLeftBox>
                    <MyInfoNavLogoutLeftImg
                      avatar_url={fetchImg ? fetchImg : ""}
                    ></MyInfoNavLogoutLeftImg>
                    <MyInfoNavLogoutLeftContent>
                      {user ? user.attributes.name : "아무개"}님
                    </MyInfoNavLogoutLeftContent>
                  </MyInfoNavLogoutLeftBox>
                  <MyInfoNavLogoutRightBox>
                    {/* <MyInfoNavLogoutBtn onClick={onSignOutClick}> */}
                    {/* {TextData.NeedLogout} */}
                    {/* </MyInfoNavLogoutBtn> */}
                  </MyInfoNavLogoutRightBox>
                </MyInfoNavLogoutContainer>
              </BoardLowerMenuContainer>
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterQuestions = withRouter(QuestionPresenter);
export default withRouterQuestions;
