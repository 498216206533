import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import "./loadresponsive.css";

const LoadingInLayoutContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LodingInLayoutBox = styled.div`
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default () => (
  <>
    <LoadingInLayoutContainer>
      <LodingInLayoutBox>
        {/* <LoadingOutlined spin style={{ fontSize: "10vw", marginLeft: 120 }} /> */}
        <LoadingOutlined spin style={{ fontSize: "20vw" }} />
      </LodingInLayoutBox>
    </LoadingInLayoutContainer>
  </>
);
