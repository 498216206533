import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import {
  IntroLayout,
  SLink,
  IntroLayoutMenuOption,
  BoardUpperMenuContainer,
  UP_POST_IntroDetail as IntroDetail,
  UP_POST_UploadContainer as UploadContainer,
  UP_POST_UploadBox as UploadBox,
  UP_POST_UploadColumn as UploadColumn,
  UP_POST_UploadListContainer as UploadListContainer,
  UP_POST_UploadList as UploadList,
  UP_POST_UploadTitle as UploadTitle,
  UP_POST_UploadWriter as UploadWriter,
  UP_POST_UploadWriterContent as UploadWriterContent,
  UP_POST_UploadWriterImg as UploadWriterImg,
  UP_POST_UploadWriterNickName as UploadWriterNickName,
  UP_POST_UploadLink as UploadLink,
  UP_POST_UploadField as UploadField,
  UP_POST_UploadCost as UploadCost,
  UP_POST_UploadDescription as UploadDescription,
  UP_POST_UploadFile as UploadFile,
  UP_POST_FileBox as FileBox,
  UP_POST_UploadAttachLabel as UploadAttachLabel,
  UP_POST_UploadAttachContent as UploadAttachContent,
  UP_POST_UploadAttachSpan as UploadAttachSpan,
  UP_POST_FlexBox as FlexBox,
  UP_POST_BackBtn as BackBtn,
  UP_POST_ErrMsg as ErrMsg,
  UP_POST_CategoryCircle as CategoryCircle,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import Error from "../../Components/Error";
import TextData from "../../Json/boardJson.json";
import UpdateTextData from "../../Json/UpdateJson.json";
import { API, Storage } from "aws-amplify";
import { updatePost as updatePostM } from "../../graphql/mutations";
import {
  getCurrentUserInfo,
  fetchOneUserById,
  fetchOnePostById,
} from "../../Controllers/amplifyController";
import urlRegex from "url-regex";
import "./updateresponsive.css";
const initialFormSate = {
  id: "",
  isAdmin: false,
  recruitmentField: "",
  quotedCost: "",
  writer: "",
  link: "",
  title: "",
  description: "",
  file: "",
};
class UpdatePostPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: initialFormSate,
      isloading: true,
      error: false,
      errorMsg: "",
    };
  }

  async updatePost() {
    const { formData, postData } = this.state;
    if (!formData.id) return;
    if (
      formData.link !== "" &&
      !urlRegex({ exact: true }).test(formData.link)
    ) {
      alert("올바른 url을 입력하세요.");
      return;
    }
    try {
      await API.graphql({
        query: updatePostM,
        variables: {
          input: {
            id: formData.id,
            _version: postData._version,
            recruitmentField: formData.recruitmentField,
            quotedCost: formData.quotedCost,
            link: formData.link,
            title: formData.title,
            description: formData.description,
            file: formData.file,
          },
        },
      });
      if (this.state.postFile) {
        await Storage.put(formData.file, this.state.postFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
      window.history.back();
    }
  }
  async onChangePostFile(e) {
    if (!e.target.files[0]) return;
    const file = await e.target.files[0];
    const fileIdName = `${this.state.formData.id}postFile${file.name}`;
    this.setState({
      formData: {
        ...this.state.formData,
        file: fileIdName,
      },
      fetchFileName: file.name,
      postFile: file,
    });
  }

  async componentDidMount() {
    let user;
    let profileData;
    let postData;
    let fetchImg;
    let postId;
    let fetchFileName;
    const {
      location: { pathname },
    } = this.props;
    postId = pathname.split("/editpost/")[1];
    try {
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(user.id);
      postData = await fetchOnePostById(postId);
      if (
        profileData === null ||
        Object.values(profileData).filter((data) => data === "").length > 2
      ) {
        this.setState({ error: true, user });
      }
      if (postId.split("post-")[1] !== user.id) {
        this.setState({
          error: true,
          user,
          errorMsg: "작성자만 수정할 수 있습니다.",
        });
      } else if (
        (profileData && !profileData.membership) ||
        profileData.membership === null
      ) {
        this.setState({
          error: true,
          errorMsg: "관리자 승인 후 이용 가능합니다.",
        });
      } else if (profileData) {
        if (profileData && profileData.avatar_url) {
          fetchImg = await Storage.get(profileData.avatar_url, {
            contentType: "url",
          });
        }
        if (postData && postData.file) {
          fetchFileName = postData.file.split("postFile")[1];
        }
        this.setState({
          isloading: true,
          user,
          formData: {
            ...this.state.formData,
            id: postId,
            isAdmin: profileData.isAdmin,
            writer: profileData.nick_name,
            link: postData.link,
            recruitmentField: postData.recruitmentField,
            quotedCost: postData.quotedCost,
            file: postData.file,
            title: postData.title,
            description: postData.description,
          },
          profileData,
          fetchImg,
          postData,
          fetchFileName,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false, user, postData });
    }
  }
  handleBackPage() {
    window.history.back();
  }
  render() {
    let mobilebranch;
    let loweripad;
    const {
      isloading,
      profileData,
      formData,
      error,
      fetchImg,
      errorMsg,
      postData,
      fetchFileName,
    } = this.state;
    const {
      location: { pathname },
    } = this.props;
    if (window.innerWidth < 1440) {
      mobilebranch = true;
    } else if (window.innerWidth >= 1440) {
      mobilebranch = false;
    }
    if (window.innerWidth < 768) {
      loweripad = true;
    } else if (window.innerWidth >= 768) {
      loweripad = false;
    }
    return (
      <>
        <Helmet>
          <title>EditPost | FFF</title>
        </Helmet>
        {/* <Header /> */}
        <IntroLayout id="post_detaillayout">
          {/* <BoardUpperMenuContainer>
            <IntroLayoutMenuOption current={pathname === "/jboard/index=?1"}>
              <SLink to="/jboard/index=?1">
                {TextData.BoardUpperMenuContent01}
              </SLink>
            </IntroLayoutMenuOption>
          </BoardUpperMenuContainer> */}
          {isloading ? (
            <Loader />
          ) : !error ? (
            <>
              <IntroDetail>
                <UploadContainer className="post_upload_container">
                  <UploadBox className="post_upload_box">
                    <UploadListContainer>
                      <UploadList className="post_upload_list">
                        <UploadColumn className="post_upload_column">
                          {UpdateTextData.Update_Header}
                        </UploadColumn>
                        <CategoryCircle className="post_upload_categroy_circle" />
                        <UploadWriter className="up_post_upload_category">
                          <UploadWriterContent className="post_upload_cateogry_inner">
                            {postData && postData.category
                              ? postData.category
                              : "조회 불가"}
                          </UploadWriterContent>
                        </UploadWriter>
                        <UploadColumn
                          freecategory={loweripad ? true : false}
                          className="post_upload_column"
                        >
                          {UpdateTextData.Update_Column01}
                        </UploadColumn>
                        <UploadWriter className="post_upload_wrtier">
                          <UploadWriterContent className="post_upload_writer_content">
                            <UploadWriterImg
                              className="post_upload_wrtier_img"
                              fetchImg={fetchImg ? fetchImg : ""}
                            ></UploadWriterImg>
                            <UploadWriterNickName className="post_upload_writer_nickname">
                              {profileData && profileData.nick_name
                                ? profileData.nick_name
                                : "조회 불가"}
                            </UploadWriterNickName>
                          </UploadWriterContent>
                        </UploadWriter>
                        <UploadColumn className="post_upload_column">
                          {UpdateTextData.Update_Column02}
                        </UploadColumn>
                        <UploadLink
                          className="post_upload_link"
                          defaultValue={
                            postData && postData.link ? postData.link : ""
                          }
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                link: e.target.value,
                              },
                            })
                          }
                        ></UploadLink>
                      </UploadList>
                      <UploadList className="post_upload_list">
                        <UploadColumn
                          className="post_upload_column_second"
                          freecategory={
                            postData.category === "공지" ||
                            postData.category === "아무말" ||
                            postData.category === "질문" ||
                            postData.category === "팁"
                              ? true
                              : false
                          }
                        >
                          {UpdateTextData.Update_Column03}
                        </UploadColumn>
                        <UploadField
                          className="post_upload_field"
                          freecategory={
                            postData.category === "공지" ||
                            postData.category === "아무말" ||
                            postData.category === "질문" ||
                            postData.category === "팁"
                              ? true
                              : false
                          }
                          defaultValue={
                            postData && postData.recruitmentField
                              ? postData.recruitmentField
                              : ""
                          }
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                recruitmentField: e.target.value,
                              },
                            })
                          }
                        ></UploadField>
                        <UploadColumn
                          className="post_upload_column_second"
                          freecategory={
                            postData.category === "공지" ||
                            postData.category === "아무말" ||
                            postData.category === "질문" ||
                            postData.category === "팁"
                              ? true
                              : false
                          }
                        >
                          {UpdateTextData.Update_Column04}
                        </UploadColumn>
                        <UploadCost
                          className="post_upload_cost"
                          freecategory={
                            postData.category === "공지" ||
                            postData.category === "아무말" ||
                            postData.category === "질문" ||
                            postData.category === "팁"
                              ? true
                              : false
                          }
                          defaultValue={
                            postData && postData.quotedCost
                              ? postData.quotedCost
                              : ""
                          }
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                quotedCost: e.target.value,
                              },
                            })
                          }
                        ></UploadCost>
                        {loweripad ? (
                          <></>
                        ) : (
                          <FileBox className="post_upload_fileBox">
                            <UploadAttachLabel>
                              <UploadAttachContent className="post_upload_attachContent">
                                {UpdateTextData.Update_Attach}
                              </UploadAttachContent>
                              <UploadFile
                                type="file"
                                id="post_file"
                                name="post_file"
                                onChange={(e) => this.onChangePostFile(e)}
                              ></UploadFile>
                              <UploadAttachSpan className="post_upload_attachSpan">
                                {fetchFileName
                                  ? `${fetchFileName.substr(0, 11)}...`
                                  : ""}
                              </UploadAttachSpan>
                            </UploadAttachLabel>
                          </FileBox>
                        )}
                      </UploadList>
                      <UploadList className="post_upload_list">
                        <UploadColumn className="post_upload_column_second">
                          {UpdateTextData.Update_Column05}
                        </UploadColumn>
                        <UploadTitle
                          className="post_upload_title"
                          // placeholder={
                          //   postData && postData.title ? postData.title : ""
                          // }
                          defaultValue={
                            postData && postData.title ? postData.title : ""
                          }
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                title: e.target.value,
                              },
                            })
                          }
                        ></UploadTitle>
                      </UploadList>
                      <UploadList className="post_upload_list">
                        <UploadColumn className="post_upload_column_second">
                          {UpdateTextData.Update_Column06}
                        </UploadColumn>
                        <UploadDescription
                          className="post_upload_description"
                          // placeholder={
                          // postData && postData.description
                          //   ? postData.description
                          //   : ""
                          // }
                          defaultValue={
                            postData && postData.description
                              ? postData.description
                              : ""
                          }
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                description: e.target.value,
                              },
                            })
                          }
                        ></UploadDescription>
                      </UploadList>
                    </UploadListContainer>
                  </UploadBox>
                  <FlexBox className="post_btn_box">
                    <BackBtn
                      className="post_btn"
                      onClick={(e) => this.handleBackPage(e)}
                    >
                      {UpdateTextData.Update_Btn_01}
                    </BackBtn>

                    <BackBtn
                      className="post_btn"
                      onClick={(e) => this.updatePost()}
                    >
                      {UpdateTextData.Update_Btn_02}
                    </BackBtn>
                  </FlexBox>
                  {this.state.errorMsg ? (
                    <ErrMsg>{this.state.errorMsg}</ErrMsg>
                  ) : (
                    <></>
                  )}
                </UploadContainer>
              </IntroDetail>
            </>
          ) : (
            <>
              <Error error={errorMsg ? errorMsg : TextData.Access_Deny} />
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouteUpdatePost = withRouter(UpdatePostPresenter);
export default withRouteUpdatePost;
