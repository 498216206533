import React from "react";
import Helmet from "react-helmet";

import Header from "../../Components/Header";
import MobileHeader from "../../Components/MobileHeader";
import {
  IntroLayout,
  IntroDetail,
  IntroLayoutMenuOption,
  SLink,
  ProfileContainer,
  BoardLowerMenuContainer,
  MyInfoContainer,
  MyInfoLeftBox,
  MyInfoRightBox,
  MyProfileImg,
  ProfileNameUnderImg,
  MembershipState,
  ProfileRightContent,
  MyInfoNavContainer,
  MyInfoNavSaveBtn,
  MyInfoNavNotSaveBtn,
  ProfileListContainer,
  ProfileList,
  ProfileListColumn,
  ProfileListInput,
  ProfileListDisabled,
  ProfileListisPublicBox,
  MyInfoNavLogoutLeftBox,
  MyInfoNavLogoutLeftImg,
  MyInfoNavLogoutLeftContent,
  MyInfoNavLogoutRightBox,
  MyInfoNavLogoutContainer,
  MyInfoNavLogoutBtn,
  RadioContainer,
  RadioForm,
  RadioSection,
  RadioInput,
  RadioLabel,
  ImageClickLabel,
  ImageClickInput,
  ImageClickBox,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import TextData from "../../Json/profileJson.json";
import Loader from "../../Components/Loader";
import { API, Storage } from "aws-amplify";
import { getUser } from "../../graphql/queries";
import {
  createUser as createUserProfile,
  updateUser as updateUserProfile,
} from "../../graphql/mutations";
import {
  getCurrentUserInfo,
  onSignOutClick,
} from "../../Controllers/amplifyController";
import urlRegex from "url-regex";
import "./profileresponsive.css";
const initialFormState = {
  id: "",
  username: "",
  nick_name: "",
  real_name: "",
  avatar_url: "",
  belong_to: "",
  field: "",
  homepage: "",
  description: "",
  permission: "",
  isAdmin: false,
};

class ProfilePresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: initialFormState,
      isloading: true,
      isCreate: true,
      auth: {
        permission: {
          auth_realname: false,
          auth_belongto: false,
          auth_field: false,
          auth_homepage: false,
        },
      },
    };
  }

  // ## isCreate 옵션 때문에 따로 빼서 사용 x
  async fetchUser(id) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: getUser,
        variables: { id },
      })
        .then((user) => {
          if (user.data.getUser) {
            this.setState({ isCreate: false });
            resolve(user.data.getUser);
          } else {
            this.setState({ isCreate: true });
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  async handleSave() {
    const { isCreate, formData, auth, avatar_url, user } = this.state;
    let newObj = {
      ...formData,
      ...auth,
      avatar_url,
      phone_number: user.attributes.phone_number,
    };
    if (isCreate) {
      this.createUser(newObj);
    } else {
      this.updateUser(newObj);
    }
  }
  async handleCancel() {
    window.location.replace("/#intro");
  }

  async onChange(e) {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    const fileIdName = `${this.state.formData.id}profile`;
    this.setState({
      formData: { ...this.state.formData },
      avatar_url: fileIdName,
      profileFile: file,
      profileFileURL: URL.createObjectURL(file),
    });
  }

  handleUpdate(newinfo, oldinfo) {
    if (newinfo === "") {
      return oldinfo;
    }
    return newinfo;
  }

  async updateUser(formData) {
    const { profileData } = this.state;
    if (
      formData.homepage !== "" &&
      !urlRegex({ exact: true }).test(formData.homepage)
    ) {
      alert("올바른 url을 입력해주세요.");
      return;
    }
    try {
      await API.graphql({
        query: updateUserProfile,
        variables: {
          input: {
            id: profileData.id,
            nick_name: this.handleUpdate(
              formData.nick_name,
              profileData.nick_name
            ),
            avatar_url: this.handleUpdate(
              formData.avatar_url,
              profileData.avatar_url
            ),
            belong_to: this.handleUpdate(
              formData.belong_to,
              profileData.belong_to
            ),
            field: this.handleUpdate(formData.field, profileData.field),
            homepage: this.handleUpdate(
              formData.homepage,
              profileData.homepage
            ),
            description: this.handleUpdate(
              formData.description,
              profileData.description
            ),
            _version: profileData._version,
            permission: {
              auth_realname: this.handleUpdate(
                formData.permission.auth_realname,
                profileData.permission.auth_realname
              ),
              auth_belongto: this.handleUpdate(
                formData.permission.auth_belongto,
                profileData.permission.auth_belongto
              ),
              auth_field: this.handleUpdate(
                formData.permission.auth_field,
                profileData.permission.auth_field
              ),
              auth_homepage: this.handleUpdate(
                formData.permission.auth_homepage,
                profileData.permission.auth_homepage
              ),
            },
          },
        },
      });
      if (profileData.avatar_url) {
        await Storage.put(formData.avatar_url, this.state.profileFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ formData: initialFormState });
      window.location.reload();
    }
  }

  async createUser(formData) {
    let addmembership;
    addmembership = {
      ...formData,
      membership: false,
    };
    const { profileFile } = this.state;
    if (!addmembership.id || !addmembership.username) return;

    if (addmembership.nick_name === "") {
      alert("닉네임을 작성해주세요!");
      return;
    }
    if (addmembership.belong_to === "") {
      alert("해당 소속을 작성해주세요!");
      return;
    }
    if (addmembership.field === "") {
      alert("활동분야를 작성해주세요!");
      return;
    }
    if (
      addmembership.homepage !== "" &&
      !urlRegex({ exact: true }).test(addmembership.homepage)
    ) {
      alert("알맞은 url을 입력해주세요.");
      return;
    }
    if (addmembership.description === "") {
      alert("한줄소개를 작성해주세요!");
      return;
    }
    try {
      if (addmembership.avatar_url) {
        await Storage.put(addmembership.avatar_url, profileFile);
      }
      await API.graphql({
        query: createUserProfile,
        variables: { input: addmembership },
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ formData: initialFormState });
      window.location.reload();
    }
  }

  async componentDidMount() {
    let user;
    let profileData;
    let fetchImg;
    try {
      user = await getCurrentUserInfo();
      profileData = await this.fetchUser(user.id);
      if (profileData) {
        if (profileData.avatar_url) {
          fetchImg = await Storage.get(profileData.avatar_url, {
            contentType: "url",
          });
        }
        this.setState({
          isloading: true,
          formData: {
            ...this.state.formData,
            id: user.id,
            username: user.attributes.email,
            real_name: user.attributes.name,
          },
          auth: {
            permission: {
              ...this.state.auth.permission,
              ...profileData.permission,
            },
          },
          user,
          profileData,
          fetchImg,
        });
      } else {
        this.setState({
          isloading: true,
          formData: {
            ...this.state.formData,
            id: user.id,
            username: user.attributes.email,
            real_name: user.attributes.name,
          },
          auth: {
            permission: {
              ...this.state.auth.permission,
            },
          },
          user,
          profileData,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false, user });
    }
  }

  render() {
    let mobilebranch;
    let mymobileNewbranch;
    const { formData, profileData, isloading, user, auth } = this.state;
    const {
      location: { pathname },
      info,
    } = this.props;
    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    if (window.innerWidth <= 425) {
      mymobileNewbranch = true;
    } else {
      mymobileNewbranch = false;
    }
    return (
      <>
        <Helmet>
          <title>Profile | FFF</title>
        </Helmet>
        {profileData && profileData.isAdmin ? (
          <Header isadmin={true} info={info} />
        ) : mobilebranch ? (
          <MobileHeader />
        ) : (
          <Header isadmin={false} />
        )}
        <IntroLayout id="myprofile_introlayout">
          {isloading ? (
            <>
              <Loader />
            </>
          ) : (
            <>
              <ProfileContainer id="myprofile_profileContainer">
                <IntroLayoutMenuOption
                  className="myprofile_profileMenuOptions"
                  current={pathname === `/profile/${info}`}
                >
                  <SLink to={`/profile/${info}`}>
                    {TextData.link_to_profile}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="myprofile_profileMenuOptions"
                  current={pathname === `/mypost/${info}`}
                >
                  <SLink to={`/mypost/${info}`}>
                    {TextData.link_to_mypost}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="myprofile_profileMenuOptions"
                  current={pathname === `/questions/${info}`}
                >
                  <SLink to={`/questions/${info}`}>
                    {TextData.link_to_question}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="myprofile_profileMenuOptions"
                  current={pathname === `/myportfolio/${info}`}
                >
                  <SLink to={`/myportfolio/${info}`}>
                    {TextData.link_to_myportfolio}
                  </SLink>
                </IntroLayoutMenuOption>
              </ProfileContainer>
              <IntroDetail id="myprofile_infoDetail">
                <MyInfoContainer id="myprofile_infoContainer">
                  <MyInfoLeftBox id="myprofile_infoLeftBox">
                    <ImageClickBox id="myprofile_imageClickBox">
                      <ImageClickLabel id="myprofile_imageClickLabel">
                        <MyProfileImg
                          id="myprofile_image"
                          avatar_url={
                            this.state.profileFileURL
                              ? this.state.profileFileURL
                              : this.state.fetchImg
                              ? this.state.fetchImg
                              : ""
                          }
                        />
                        <ImageClickInput
                          type="file"
                          id="profile_pic"
                          name="profile_pic"
                          accept=".jpg, .jpeg, .png"
                          onChange={(e) => this.onChange(e)}
                        />
                      </ImageClickLabel>
                    </ImageClickBox>
                    <ProfileNameUnderImg id="myprofile_myname">
                      {user && user.attributes
                        ? user.attributes.name
                        : "아무개"}{" "}
                      {TextData.profile_our_nim}
                    </ProfileNameUnderImg>
                    <MembershipState id="myprofile_membershipstate">
                      {profileData &&
                      profileData.membership &&
                      profileData.membership !== null
                        ? `허가상태`
                        : `비허가상태`}
                    </MembershipState>
                  </MyInfoLeftBox>
                  <MyInfoRightBox id="myprofile_rightbox">
                    <ProfileRightContent id="myprofile_rightcontent">
                      <ProfileListContainer className="myprofile_list">
                        <ProfileList className="myprofile_profilelist">
                          <ProfileListColumn className="myprofile_profilelist_column">
                            {TextData.profile_list01}
                          </ProfileListColumn>
                          <ProfileListDisabled className="myprofile_profilelist_disabled">
                            {user && user.username ? user.username : ""}
                          </ProfileListDisabled>
                          <ProfileListisPublicBox className="myprofile_profile_publicBox" />
                        </ProfileList>
                        {mymobileNewbranch ? (
                          <></>
                        ) : (
                          <ProfileList className="myprofile_profilelist">
                            <ProfileListColumn className="myprofile_profilelist_column">
                              {TextData.profile_list02}
                            </ProfileListColumn>
                            <ProfileListDisabled className="myprofile_profilelist_disabled">
                              {user && user.attributes.name
                                ? user.attributes.name
                                : ""}
                            </ProfileListDisabled>
                            <ProfileListisPublicBox className="myprofile_profile_publicBox">
                              <RadioContainer className="myprofile_radioContainer">
                                <RadioForm className="myprofile_radioForm">
                                  <RadioSection className="myprofile_radioSection">
                                    <RadioInput
                                      type="radio"
                                      name="name1"
                                      id="id1"
                                      value="value1"
                                    />
                                    <RadioLabel
                                      className="myprofile_radiolabel"
                                      selected={
                                        auth.permission.auth_realname
                                          ? true
                                          : null
                                      }
                                      htmlFor="test1"
                                      onClick={(e) =>
                                        this.setState({
                                          formData: {
                                            ...formData,
                                          },
                                          auth: {
                                            permission: {
                                              ...auth.permission,
                                              auth_realname: true,
                                            },
                                          },
                                        })
                                      }
                                    >
                                      {TextData.profile_ispublic}
                                    </RadioLabel>
                                    <RadioInput
                                      type="radio"
                                      name="name2"
                                      id="id2"
                                      value="value2"
                                    />
                                    <RadioLabel
                                      className="myprofile_radiolabel"
                                      selected={
                                        !auth.permission.auth_realname
                                          ? true
                                          : null
                                      }
                                      htmlFor="name2"
                                      onClick={(e) =>
                                        this.setState({
                                          formData: {
                                            ...formData,
                                          },
                                          auth: {
                                            permission: {
                                              ...auth.permission,
                                              auth_realname: false,
                                            },
                                          },
                                        })
                                      }
                                    >
                                      {TextData.profile_isprivate}
                                    </RadioLabel>
                                  </RadioSection>
                                </RadioForm>
                              </RadioContainer>
                            </ProfileListisPublicBox>
                          </ProfileList>
                        )}
                        <ProfileList className="myprofile_profilelist">
                          <ProfileListColumn className="myprofile_profilelist_column">
                            {TextData.profile_list03}
                          </ProfileListColumn>
                          <ProfileListInput
                            className="myprofile_profilelist_input_req mobile_bolder"
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  nick_name: e.target.value,
                                },
                              })
                            }
                            // defaultValue
                            // value={formData.nick_name}
                            // placeholder={
                            //   profileData && profileData.nick_name
                            //     ? profileData.nick_name
                            //     : "닉네임을 입력하세요."
                            // }
                            defaultValue={
                              profileData && profileData.nick_name
                                ? profileData.nick_name
                                : ""
                            }
                            placeholder={
                              profileData && profileData.nick_name
                                ? ""
                                : "닉네임을 입력하세요."
                            }
                            maxLength="8"
                          ></ProfileListInput>
                          <ProfileListisPublicBox className="myprofile_profile_publicBox" />
                        </ProfileList>
                        <ProfileList className="myprofile_profilelist">
                          <ProfileListColumn className="myprofile_profilelist_column">
                            {TextData.profile_list04}
                          </ProfileListColumn>
                          <ProfileListInput
                            className="myprofile_profilelist_input"
                            maxLength="15"
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  belong_to: e.target.value,
                                },
                              })
                            }
                            // value={formData.belong_to}
                            // placeholder={
                            //   profileData && profileData.belong_to
                            //     ? profileData.belong_to
                            //     : "소속을 입력하세요."
                            // }
                            defaultValue={
                              profileData && profileData.belong_to
                                ? profileData.belong_to
                                : ""
                            }
                            placeholder={
                              profileData && profileData.belong_to
                                ? ""
                                : "소속을 입력하세요."
                            }
                          ></ProfileListInput>
                          <ProfileListisPublicBox className="myprofile_profile_publicBox">
                            <RadioContainer className="myprofile_radioContainer">
                              <RadioForm className="myprofile_radioForm">
                                <RadioSection>
                                  <RadioInput
                                    type="radio"
                                    name="name3"
                                    id="id3"
                                    value="value3"
                                  />
                                  <RadioLabel
                                    className="myprofile_radiolabel"
                                    selected={
                                      auth.permission.auth_belongto
                                        ? true
                                        : null
                                    }
                                    htmlFor="name3"
                                    onClick={(e) =>
                                      this.setState({
                                        formData: {
                                          ...formData,
                                        },
                                        auth: {
                                          permission: {
                                            ...auth.permission,
                                            auth_belongto: true,
                                          },
                                        },
                                      })
                                    }
                                  >
                                    {TextData.profile_ispublic}
                                  </RadioLabel>
                                  <RadioInput
                                    type="radio"
                                    name="name4"
                                    id="id4"
                                    value="value4"
                                  />
                                  <RadioLabel
                                    className="myprofile_radiolabel"
                                    selected={
                                      !auth.permission.auth_belongto
                                        ? true
                                        : null
                                    }
                                    htmlFor="name4"
                                    onClick={(e) =>
                                      this.setState({
                                        formData: {
                                          ...formData,
                                        },
                                        auth: {
                                          permission: {
                                            ...auth.permission,
                                            auth_belongto: false,
                                          },
                                        },
                                      })
                                    }
                                  >
                                    {TextData.profile_isprivate}
                                  </RadioLabel>
                                </RadioSection>
                              </RadioForm>
                            </RadioContainer>
                          </ProfileListisPublicBox>
                        </ProfileList>
                        <ProfileList className="myprofile_profilelist">
                          <ProfileListColumn className="myprofile_profilelist_column">
                            {TextData.profile_list05}
                          </ProfileListColumn>
                          <ProfileListInput
                            className="myprofile_profilelist_input_req"
                            maxLength="30"
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  field: e.target.value,
                                },
                              })
                            }
                            // value={formData.field}
                            // placeholder={
                            //   profileData && profileData.field
                            //     ? profileData.field
                            //     : "활동분야를 입력하세요."
                            // }
                            defaultValue={
                              profileData && profileData.field
                                ? profileData.field
                                : ""
                            }
                            placeholder={
                              profileData && profileData.field
                                ? ""
                                : "활동분야를 입력하세요."
                            }
                          ></ProfileListInput>
                          <ProfileListisPublicBox className="myprofile_profile_publicBox">
                            <RadioContainer className="myprofile_radioContainer">
                              <RadioForm className="myprofile_radioForm">
                                <RadioSection>
                                  <RadioInput
                                    type="radio"
                                    name="name5"
                                    id="id5"
                                    value="value5"
                                  />
                                  <RadioLabel
                                    className="myprofile_radiolabel"
                                    selected={
                                      auth.permission.auth_field ? true : null
                                    }
                                    htmlFor="name5"
                                    onClick={(e) =>
                                      this.setState({
                                        formData: {
                                          ...formData,
                                        },
                                        auth: {
                                          permission: {
                                            ...auth.permission,
                                            auth_field: true,
                                          },
                                        },
                                      })
                                    }
                                  >
                                    {TextData.profile_ispublic}
                                  </RadioLabel>
                                  <RadioInput
                                    type="radio"
                                    name="name6"
                                    id="id6"
                                    value="value6"
                                  />
                                  <RadioLabel
                                    className="myprofile_radiolabel"
                                    selected={
                                      !auth.permission.auth_field ? true : null
                                    }
                                    htmlFor="name4"
                                    onClick={(e) =>
                                      this.setState({
                                        formData: {
                                          ...formData,
                                        },
                                        auth: {
                                          permission: {
                                            ...auth.permission,
                                            auth_field: false,
                                          },
                                        },
                                      })
                                    }
                                  >
                                    {TextData.profile_isprivate}
                                  </RadioLabel>
                                </RadioSection>
                              </RadioForm>
                            </RadioContainer>
                          </ProfileListisPublicBox>
                        </ProfileList>
                        <ProfileList className="myprofile_profilelist">
                          <ProfileListColumn className="myprofile_profilelist_column">
                            {TextData.profile_list06}
                          </ProfileListColumn>
                          <ProfileListInput
                            className="myprofile_profilelist_input"
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  homepage: e.target.value,
                                },
                              })
                            }
                            // value={formData.homepage}
                            // placeholder={
                            //   profileData && profileData.homepage
                            //     ? profileData.homepage
                            //     : "홈페이지를 입력하세요."
                            // }
                            defaultValue={
                              profileData && profileData.homepage
                                ? profileData.homepage
                                : ""
                            }
                            placeholder={
                              profileData && profileData.homepage
                                ? ""
                                : "홈페이지를 입력하세요."
                            }
                          ></ProfileListInput>
                          <ProfileListisPublicBox className="myprofile_profile_publicBox">
                            <RadioContainer className="myprofile_radioContainer">
                              <RadioForm className="myprofile_radioForm">
                                <RadioSection>
                                  <RadioInput
                                    type="radio"
                                    name="name7"
                                    id="id7"
                                    value="value7"
                                  />
                                  <RadioLabel
                                    className="myprofile_radiolabel"
                                    selected={
                                      auth.permission.auth_homepage
                                        ? true
                                        : null
                                    }
                                    htmlFor="name7"
                                    onClick={(e) =>
                                      this.setState({
                                        formData: {
                                          ...formData,
                                        },
                                        auth: {
                                          permission: {
                                            ...auth.permission,
                                            auth_homepage: true,
                                          },
                                        },
                                      })
                                    }
                                  >
                                    {TextData.profile_ispublic}
                                  </RadioLabel>
                                  <RadioInput
                                    type="radio"
                                    name="name8"
                                    id="id8"
                                    value="value8"
                                  />
                                  <RadioLabel
                                    className="myprofile_radiolabel"
                                    selected={
                                      !auth.permission.auth_homepage
                                        ? true
                                        : null
                                    }
                                    htmlFor="name8"
                                    onClick={(e) =>
                                      this.setState({
                                        formData: {
                                          ...formData,
                                        },
                                        auth: {
                                          permission: {
                                            ...auth.permission,
                                            auth_homepage: false,
                                          },
                                        },
                                      })
                                    }
                                  >
                                    {TextData.profile_isprivate}
                                  </RadioLabel>
                                </RadioSection>
                              </RadioForm>
                            </RadioContainer>
                          </ProfileListisPublicBox>
                        </ProfileList>
                        <ProfileList className="myprofile_profilelist">
                          <ProfileListColumn className="myprofile_profilelist_column">
                            {TextData.profile_list07}
                          </ProfileListColumn>
                          <ProfileListInput
                            className="myprofile_profilelist_input"
                            maxLength="45"
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  description: e.target.value,
                                },
                              })
                            }
                            // defaultvalue 적용해보기
                            // value={formData.description}
                            // placeholder={
                            //   profileData && profileData.description
                            //     ? profileData.description
                            //     : "간단한 자기소개를 부탁드려요."
                            // }
                            defaultValue={
                              profileData && profileData.description
                                ? profileData.description
                                : ""
                            }
                            placeholder={
                              profileData && profileData.description
                                ? ""
                                : "간단한 자기소개를 부탁드려요."
                            }
                          ></ProfileListInput>
                          <ProfileListisPublicBox className="myprofile_profile_publicBox" />
                        </ProfileList>
                      </ProfileListContainer>
                    </ProfileRightContent>
                    <MyInfoNavContainer id="myprofile_navContainer">
                      <MyInfoNavSaveBtn
                        className="myprofile_navbtn"
                        onClick={() => this.handleSave()}
                      >
                        {TextData.profile_btn01}
                      </MyInfoNavSaveBtn>
                      <MyInfoNavNotSaveBtn
                        className="myprofile_navbtn"
                        onClick={this.handleCancel}
                      >
                        {TextData.profile_btn02}
                      </MyInfoNavNotSaveBtn>
                    </MyInfoNavContainer>
                  </MyInfoRightBox>
                </MyInfoContainer>
              </IntroDetail>
              <BoardLowerMenuContainer id="myinfo_togglemenu">
                <MyInfoNavLogoutContainer>
                  <MyInfoNavLogoutLeftBox>
                    <MyInfoNavLogoutLeftImg
                      avatar_url={
                        this.state.profileFileURL
                          ? this.state.profileFileURL
                          : this.state.fetchImg
                          ? this.state.fetchImg
                          : ""
                      }
                    />

                    <MyInfoNavLogoutLeftContent>
                      {user && user.attributes.name
                        ? user.attributes.name
                        : "아무개"}
                      님
                    </MyInfoNavLogoutLeftContent>
                  </MyInfoNavLogoutLeftBox>
                  <MyInfoNavLogoutRightBox onClick={onSignOutClick}>
                    {/* <MyInfoNavLogoutBtn onClick={onSignOutClick}> */}
                    {/* {TextData.NeedLogout} */}
                    {/* </MyInfoNavLogoutBtn> */}
                  </MyInfoNavLogoutRightBox>
                </MyInfoNavLogoutContainer>
              </BoardLowerMenuContainer>
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterProfile = withRouter(ProfilePresenter);
export default withRouterProfile;
