import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import {
  IntroLayout,
  SLink,
  Post_Detail_IntroDetail as IntroDetail,
  Post_Detail_UploadContainer as UploadContainer,
  Post_Detail_UploadBox as UploadBox,
  Post_Detail_UploadColumn as UploadColumn,
  Post_Detail_UploadListContainer as UploadListContainer,
  Post_Detail_IsLikedContainer as IsLikedContainer,
  Post_Detail_IsLikedBox as IsLikedBox,
  Post_Detail_IsLikedCount as IsLikedCount,
  Post_Detail_IsLikedImg as IsLikedImg,
  Post_Detail_CommentListContainer as CommentListContainer,
  Post_Detail_CommentList as CommentList,
  Post_Detail_CommentContent as CommentContent,
  Post_Detail_CommentCreatedAt as CommentCreatedAt,
  Post_Detail_CommentWriteContainer as CommentWriteContainer,
  Post_Detail_CommentWriterProfile as CommentWriterProfile,
  Post_Detail_CommentWriteInput as CommentWriteInput,
  Post_Detail_CommentWriteBtn as CommentWriteBtn,
  Post_Detail_UploadList as UploadList,
  Post_Detail_UploadTitle as UploadTitle,
  Post_Detail_UploadWriterImg as UploadWriterImg,
  Post_Detail_UploadWriter as UploadWriter,
  Post_Detail_UploadTime as UploadTime,
  Post_Detail_UploadLink as UploadLink,
  Post_Detail_UploadField as UploadField,
  Post_Detail_UploadCost as UploadCost,
  Post_Detail_UploadDescription as UploadDescription,
  Post_Detail_UploadImgView as UploadImageView,
  Post_Detail_UploadAttachSpan as UploadAttachSpan,
  Post_Detail_FlexBox as FlexBox,
  Post_Detail_BackBtn as BackBtn,
  Post_Detail_AdditonalCommentBtn as AdditonalCommentBtn,
  Post_Detail_RenewalBtn as RenewalBtn,
  Post_Detail_AdditionalCommentContent as AdditionalCommentContent,
  Post_Detail_AdditionalCommentImg as AdditionalCommentImg,
  Post_Detail_AdditionalCommentList as AdditionalCommentList,
  Post_Detail_AdditionalCommentCreatedAt as AdditionalCommentCreatedAt,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import Error from "../../Components/Error";
import TextData from "../../Json/boardJson.json";
import PostDetailTextData from "../../Json/postDetailJson.json";
import urlRegex from "url-regex";
import { API, Storage } from "aws-amplify";
import {
  getLiked,
  listLikeds,
  listComments,
  listAdditionalComments,
} from "../../graphql/queries";
import {
  updatePost as updatePostM,
  deletePost as deletePostM,
  createLiked as createLikedM,
  createAdditionalComment as createAdditionalCommentM,
  updateLiked as updatedLikedM,
  createComment as createCommentM,
  deleteAdditionalComment as deleteAdditionalCommentM,
  deleteComment as deleteCommentM,
} from "../../graphql/mutations";
import "./postresponsive.css";
// import "./postdetail.css";
import {
  getCurrentUserInfo,
  fetchOneUserById,
  fetchOnePostById,
} from "../../Controllers/amplifyController";

class PostPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      error: false,
      errorMsg: "",
      editAuthor: false,
      additionalCommentsId: "",
      isadditional: false,
    };
  }
  async createAddtionalComment(postId, commentId, content) {
    let fetchAddCommentsById;
    let data;
    let splitCommentId;
    let newId;
    let randomVariables;

    const { fetchAdditionalComments, user, fetchMe, postNumber ,NotDeletefetchAdditionalComments} = this.state;
    if (!postId || !commentId) return;
    randomVariables = Math.random(1).toFixed(4).slice(2);

    if (NotDeletefetchAdditionalComments) {
      fetchAddCommentsById = await NotDeletefetchAdditionalComments.filter(
        (data) => data.commentID === commentId
      ).length;
      splitCommentId = await commentId.split("ap-northeast-2")[0].split("-")[0];
      if (fetchAddCommentsById === 0) {
        newId = `${splitCommentId}-1additional-${postNumber}-${user.id}-${randomVariables}`;
      } else {
        newId = `${splitCommentId}-${
          fetchAddCommentsById + 1
        }additional-${postNumber}-${user.id}-${randomVariables}`;
      }
    }
    data = {
      id: newId,
      postID: postId,
      commentID: commentId,
      content,
      nick_name: fetchMe.nick_name,
    };
    try {
      await API.graphql({
        query: createAdditionalCommentM,
        variables: { input: data },
      });
    } catch (err) {
      console.log(err);
    } finally {
      window.location.reload();
    }
  }

  async additionalCommentClick(e, bool) {
    let commentId = e.target.id;
    const { fetchMe, postDetailId } = this.state;
    const additional_comment = document.createElement("li");
    additional_comment.setAttribute("class", "after_btn_additional_comment");
    const additional_comment_nickname = document.createElement("div");
    additional_comment_nickname.setAttribute(
      "class",
      "after_btn_additional_comment_nickname"
    );
    const additional_comment_content = document.createElement("input");
    additional_comment_content.setAttribute(
      "class",
      "after_btn_additional_comment_content"
    );
    const additional_comment_submit = document.createElement("div");
    additional_comment_submit.setAttribute(
      "class",
      "after_btn_additional_comment_submit"
    );

    if (bool) {
      const listParent = document.getElementsByClassName(e.target.id)[0];
      const newlistParent = listParent.insertBefore(
        additional_comment,
        listParent.firstChild.nextSibling
      );

      newlistParent.appendChild(additional_comment_nickname);
      newlistParent.appendChild(additional_comment_content);
      newlistParent.appendChild(additional_comment_submit);

      additional_comment_nickname.innerHTML = fetchMe.nick_name
        ? fetchMe.nick_name
        : "조회 불가";
      additional_comment_submit.innerHTML = "쓰기";
      additional_comment_content.addEventListener("change", (e) =>
        additional_comment_content.setAttribute("value", e.target.value)
      );
      additional_comment_submit.addEventListener("click", (e) =>
        this.createAddtionalComment(
          postDetailId,
          commentId,
          additional_comment_content.value
        )
      );
    } else {
      let element = document.getElementsByClassName(
        "after_btn_additional_comment"
      )[0];
      if (element.parentNode) {
        element.parentNode.removeChild(element);
      }
    }
  }

  async deleteAdditionalComment(e) {
    let newdata;
    const { fetchAdditionalComments } = this.state;
    newdata = await fetchAdditionalComments.filter(
      // (data) => data.id === e.target.parentNode.id
      (data) => data.id === e.target.id
    );
    if (newdata) {
      try {
        await API.graphql({
          query: deleteAdditionalCommentM,
          variables: {
            input: {
              id: newdata[0].id,
              _version: newdata[0]._version,
            },
          },
        });
      } catch (err) {
        console.log(err);
      } finally {
        window.location.reload();
      }
    }
  }
  async deleteComment(e) {
    let newdata;
    const { notDeletedFetchComments } = this.state;
    newdata = await notDeletedFetchComments.filter(
      (data) => data.id === e.target.id.split(`btn-`)[1]
      // (data) => data.id === e.target.parentNode.id.split(`btn-`)[1]
    );
    if (newdata) {
      try {
        await API.graphql({
          query: deleteCommentM,
          variables: {
            input: {
              id: newdata[0].id,
              _version: newdata[0]._version,
            },
          },
        });
      } catch (err) {
        console.log(err);
      } finally {
        window.location.reload();
      }
    }
  }

  async createComment() {
    let data;
    let newId;
    const {
      postDetailId,
      fetchMe,
      userComment,
      fetchCommentsAndCalcId,
      user,
      postNumber,
    } = this.state;
    if (!userComment || !postDetailId || !fetchMe) return;
    if (!fetchCommentsAndCalcId) {
      newId = `1comment-${postNumber}-${user.id}`;
    } else if (fetchCommentsAndCalcId) {
      newId = `${fetchCommentsAndCalcId + 1}comment-${postNumber}-${user.id}`;
    }
    data = {
      id: newId,
      postID: postDetailId,
      nick_name: fetchMe.nick_name,
      content: userComment,
    };
    try {
      await API.graphql({
        query: createCommentM,
        variables: { input: data },
      });
    } catch (err) {
      console.log(err);
    } finally {
      window.location.reload();
    }
  }
  async NotDeletefetchAdditionalComments(id) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listAdditionalComments,
        variables: { filter: { postID: { eq: id } } },
      })
        .then((comments) => {
          if (
            comments.data.listAdditionalComments &&
            comments.data.listAdditionalComments.items
          ) {
            resolve(comments.data.listAdditionalComments.items);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }
  async fetchAdditionalComments(id) {
    let noDeleteComments;
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listAdditionalComments,
        variables: { filter: { postID: { eq: id } } },
      })
        .then((comments) => {
          noDeleteComments = comments.data.listAdditionalComments.items.filter(
            (data) => data._deleted !== true
          );
          if (
            comments.data.listAdditionalComments &&
            comments.data.listAdditionalComments.items
          ) {
            resolve(noDeleteComments);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }
  async fetchComments(id) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listComments,
        variables: { filter: { postID: { contains: id } }, limit: 1000 },
      })
        .then((comments) => {
          if (comments.data.listComments && comments.data.listComments.items) {
            resolve(comments.data.listComments.items);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  async fetchLiked(id) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: getLiked,
        variables: { id },
      })
        .then((liked) => {
          if (liked.data.getLiked) {
            resolve(liked.data.getLiked);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }
  async fetchLikedList(id) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listLikeds,
        variables: { filter: { postID: { eq: id } }, limit: 1000 },
      })
        .then((likeds) => {
          if (likeds.data.listLikeds.items) {
            resolve(likeds.data.listLikeds.items);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  async createLiked(data) {
    if (!data.id || !data.postID) return;
    try {
      await API.graphql({
        query: createLikedM,
        variables: { input: data },
      });
    } catch (err) {
      console.log(err);
    } finally {
      window.location.reload();
    }
  }
  async updateLiked(data) {
    const { fetchLiked } = this.state;
    try {
      await API.graphql({
        query: updatedLikedM,
        variables: {
          input: {
            id: data.id,
            _version: fetchLiked._version,
            postID: data.postID,
            isLiked: data.isLiked,
          },
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      window.location.reload();
    }
  }

  async handleLiked(e) {
    const { fetchLiked, likedId, postDetailId } = this.state;
    let newobj;
    newobj = {
      id: likedId,
      postID: postDetailId,
      isLiked: !fetchLiked
        ? true
        : fetchLiked && fetchLiked.isLiked === true
        ? false
        : true,
    };
    if (fetchLiked === null) {
      this.createLiked(newobj);
    } else {
      this.updateLiked(newobj);
    }
    console.log(e);
  }

  async handleDelete(postData) {
    if (postData) {
      try {
        await API.graphql({
          query: deletePostM,
          variables: {
            input: {
              _version: postData._version + 1,
              id: postData.id,
            },
          },
        });
      } catch (err) {
        console.log(err);
      } finally {
        window.history.back();
      }
    }
  }
  async updatePost(data) {
    let newCount;
    if (data && data.ReadCount === null) {
      newCount = 1;
    } else {
      newCount = data.ReadCount + 1;
    }
    try {
      await API.graphql({
        query: updatePostM,
        variables: {
          input: {
            id: data.id,
            _version: data._version,
            ReadCount: newCount,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidMount() {
    let user;
    let profileData;
    let postData;
    let fetchWriterImg;
    let postDetailId;
    let postNumber;
    let postWriterProfileId;
    let postCreatedAt;
    let fetchMe;
    let fetchFile;
    let fetchLiked;
    let likedId;
    let fetchLikeds;
    let fetchCount;
    let fetchComments;
    let notDeletedFetchComments;
    let fetchCommentsAndCalcId;
    let fetchAdditionalComments;
    let NotDeletefetchAdditionalComments;
    let fetchCommentCount;
    const {
      location: { pathname },
    } = this.props;
    postDetailId = pathname.split("/postdetail/")[1];
    postNumber = postDetailId.split("-ap-northeast-2")[0];
    postWriterProfileId = pathname.split("post-")[1];
    try {
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(postWriterProfileId);
      fetchMe = await fetchOneUserById(user.id);

      if (!fetchMe) {
        this.setState({ error: true, errorMsg: "프로필을 먼저 완성해주세요!" });
      } else if (
        (fetchMe && !fetchMe.membership) ||
        fetchMe.membership === null
      ) {
        this.setState({
          error: true,
          errorMsg: "관리자 승인 후 이용 가능합니다.",
        });
      }
      postData = await fetchOnePostById(postDetailId);
      likedId = `${pathname.split("/postdetail/")[1].split("post-")[0]}liked-${
        user.id
      }`;
      //현재 로그인한 내가 좋아요 누른 무엇
      fetchLiked = await this.fetchLiked(likedId);
      //해당 게시글에 있는 좋아요 리스트들
      fetchLikeds = await this.fetchLikedList(postDetailId);
      fetchComments = await this.fetchComments(postDetailId);
      //댓글 count
      fetchCommentCount = fetchComments.filter((data) => data._deleted !== true)
        .length;

      fetchAdditionalComments = await this.fetchAdditionalComments(
        postDetailId
      );
      NotDeletefetchAdditionalComments = await this.NotDeletefetchAdditionalComments(postDetailId);
      if (fetchAdditionalComments) {
        // 대댓글 역순 정리
        fetchAdditionalComments.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      }
      if (fetchComments) {
        notDeletedFetchComments = fetchComments.filter(
          (data) => data._deleted !== true
        );
        fetchCommentsAndCalcId = fetchComments.length;
        fetchComments.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        notDeletedFetchComments.sort(
          // 댓글 역순 정렬
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );

        this.setState({ fetchCommentsAndCalcId, fetchComments });
      }
      if (fetchLikeds) {
        fetchCount = fetchLikeds.filter((data) => data.isLiked === true).length;
      }
      if (postData && postData.createdAt) {
        var d = new Date(postData.createdAt);
        postCreatedAt = `${d.getUTCFullYear()}-${
          (d.getUTCMonth() + 1).toString().length === 1
            ? `0${d.getUTCMonth() + 1}`
            : d.getUTCMonth() + 1
        }-${
          d.getUTCDate().toString().length === 1
            ? `0${d.getUTCDate()}`
            : d.getUTCDate()
        } ${
          d.getHours().toString().length === 1
            ? `0${d.getHours()}`
            : d.getHours()
        }:${
          d.getMinutes().toString().length === 1
            ? `0${d.getMinutes()}`
            : d.getMinutes()
        }`;
        this.updatePost(postData);

        if (postData.file && postData.file !== null) {
          fetchFile = await Storage.get(postData.file, {
            contentType: "url",
          });
        }
      }
      if (postData._deleted === true) {
        this.setState({ error: true, errorMsg: "이미 삭제된 게시글입니다." });
      }
      if (profileData && profileData.avatar_url) {
        fetchWriterImg = await Storage.get(profileData.avatar_url, {
          contentType: "url",
        });
      }
      if (user.id === profileData.id) {
        this.setState({ isloading: true, editAuthor: true });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        isloading: false,
        user,
        postData,
        postCreatedAt,
        fetchMe,
        fetchWriterImg,
        fetchFile,
        fetchLiked,
        likedId,
        postDetailId,
        postNumber,
        fetchCount,
        fetchAdditionalComments,
        NotDeletefetchAdditionalComments,
        notDeletedFetchComments,
        fetchCommentCount,
      });
    }
  }
  handleBackPage() {
    window.history.back();
  }
  render() {
    let newDescriptionArr;
    let mobilebranch;
    let loweripad;
    let minimumDevice;
    const {
      isloading,
      postDetailId,
      fetchLiked,
      error,
      postData,
      fetchWriterImg,
      fetchMe,
      editAuthor,
      postCreatedAt,
      fetchFile,
      fetchCount,
      notDeletedFetchComments,
      fetchAdditionalComments,
      fetchCommentCount,
    } = this.state;
    const {
      location: { pathname },
    } = this.props;

    let newPostId = pathname.split("/postdetail/")[1];
    if (postData && postData.description !== "") {
      newDescriptionArr = postData.description.split("\n");
    }
    function additionalCommentsByCommentId(id) {
      return fetchAdditionalComments.filter((data) => data.commentID === id);
    }
    function handleScroll(e) {
      var handleHeight = document.getElementById("post_IntroDetail");
      let countvar =
        (handleHeight.scrollHeight - handleHeight.clientHeight) / 360;
      let headerImg = document.getElementById("FFF_LOGO");
      headerImg.style.transform =
        "rotate(" + handleHeight.scrollTop / countvar + "deg)";
    }
    if (window.innerWidth < 1440) {
      mobilebranch = true;
    } else if (window.innerWidth >= 1440) {
      mobilebranch = false;
    }
    if (window.innerWidth < 768) {
      loweripad = true;
    } else if (window.innerWidth >= 768) {
      loweripad = false;
    }
    if (window.innerWidth <= 320) {
      minimumDevice = true;
    } else if (window.innerWidth >= 320) {
      minimumDevice = false;
    }
    return (
      <>
        <Helmet>
          <title>PostDetail | FFF</title>
        </Helmet>
        {/* Header분기 */}
        {mobilebranch ? <></> : <Header />}
        <IntroLayout id="post_detaillayout">
          {isloading ? (
            <Loader />
          ) : !error ? (
            <IntroDetail
              id="post_IntroDetail"
              onScroll={(e) => {
                if (!mobilebranch) {
                  console.log("pc View");
                  handleScroll(e);
                } else {
                  console.log("mobile View");
                  return;
                }
              }}
            >
              <UploadContainer>
                <UploadBox id="post_UploadBox">
                  <UploadListContainer id="post_uploadUl">
                    <UploadList className="upload_post_list">
                      <UploadColumn
                        freecategory={loweripad ? true : false}
                        className="upload_column"
                      >
                        {PostDetailTextData.Post_Detail_Column01}
                      </UploadColumn>
                      <UploadTitle className="upload_title">
                        {postData && postData.title
                          ? `[${postData.category}]${postData.title}`
                          : "조회 불가"}
                        <span id="count_comment">{fetchCommentCount}</span>
                      </UploadTitle>
                      <UploadColumn
                        freecategory={loweripad ? true : false}
                        className="upload_column upload_second_left"
                      >
                        {PostDetailTextData.Post_Detail_Column02}
                      </UploadColumn>
                      <UploadWriter className="upload_writer">
                        <UploadWriterImg
                          className="upload_image"
                          current={fetchWriterImg ? fetchWriterImg : ""}
                        />
                        <SLink
                          to={`/userdetail/${postDetailId.split("post-")[1]}`}
                        >
                          {postData && postData.writer
                            ? postData.writer
                            : "조회 불가"}
                        </SLink>
                      </UploadWriter>
                      {loweripad ? (
                        <>
                          <UploadTime className="upload_time_createdAt">
                            {postCreatedAt ? postCreatedAt : "조회 불가"}
                          </UploadTime>
                        </>
                      ) : (
                        <>
                          {/* <UploadColumn className="upload_fifth_column">
                            {PostDetailTextData.Post_Detail_Column03}
                          </UploadColumn>
                          <UploadTime className="upload_time_createdAt">
                            {postCreatedAt ? postCreatedAt : ""}
                          </UploadTime> */}
                        </>
                      )}
                    </UploadList>
                    <UploadList className="upload_post_list upload_post_list_second">
                      <UploadColumn
                        className="upload_second_column"
                        freecategory={
                          loweripad
                            ? true
                            : postData.category === "공지" ||
                              postData.category === "아무말" ||
                              postData.category === "질문" ||
                              postData.category === "팁"
                            ? true
                            : false
                        }
                      >
                        {PostDetailTextData.Post_Detail_Column04}
                      </UploadColumn>
                      <UploadField
                        className="upload_field"
                        freecategory={
                          postData.category === "공지" ||
                          postData.category === "아무말" ||
                          postData.category === "질문" ||
                          postData.category === "팁"
                            ? true
                            : false
                        }
                      >
                        {postData && postData.recruitmentField
                          ? postData.recruitmentField
                          : ""}
                      </UploadField>
                      <UploadColumn
                        className="upload_third_column upload_second_left"
                        freecategory={
                          loweripad
                            ? true
                            : postData.category === "공지" ||
                              postData.category === "아무말" ||
                              postData.category === "질문" ||
                              postData.category === "팁"
                            ? true
                            : false
                        }
                      >
                        {PostDetailTextData.Post_Detail_Column05}
                      </UploadColumn>
                      <UploadCost
                        className="upload_quotedCost"
                        freecategory={
                          postData.category === "공지" ||
                          postData.category === "아무말" ||
                          postData.category === "질문" ||
                          postData.category === "팁"
                            ? true
                            : false
                        }
                      >
                        {postData && postData.quotedCost
                          ? postData.quotedCost
                          : ""}
                      </UploadCost>
                      <UploadColumn
                        className="upload_third_column upload_second_left"
                        freecategory={loweripad ? true : false}
                      >
                        {PostDetailTextData.Post_Detail_Column06}
                      </UploadColumn>
                      <UploadLink
                        className="upload_link"
                        current={postData && postData.link ? true : false}
                        freecategory={
                          postData.category === "공지" ||
                          postData.category === "아무말" ||
                          postData.category === "질문" ||
                          postData.category === "팁"
                            ? true
                            : false
                        }
                      >
                        {postData && postData.link ? (
                          <a
                            className="link_me"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              postData.link.includes("https://") ||
                              postData.link.includes("http://")
                                ? postData.link
                                : `https://${postData.link}`
                            }
                          >
                            {postData.link}
                          </a>
                        ) : (
                          ""
                        )}
                      </UploadLink>
                    </UploadList>
                    {mobilebranch ? (
                      <></>
                    ) : (
                      <>
                        {/* <UploadList className="upload_post_list">
                          <UploadColumn className="upload_fourth_column">
                            {PostDetailTextData.Post_Detail_Column07}
                          </UploadColumn>
                          <UploadAttachSpan className="upload_attach_span">
                            {fetchFile ? "파일 있음" : "파일 없음"}
                          </UploadAttachSpan>
                        </UploadList> */}
                      </>
                    )}

                    <UploadList className="upload_post_list">
                      <UploadColumn
                        freecategory={loweripad ? true : false}
                        className="upload_desc_column"
                      >
                        {PostDetailTextData.Post_Detail_Column08}
                      </UploadColumn>
                      <UploadDescription className="upload_description">
                        {fetchFile ? (
                          <UploadImageView
                            className="upload_desc_image"
                            current={fetchFile ? fetchFile : ""}
                          />
                        ) : (
                          <></>
                        )}
                        {postData && postData.description
                          ? newDescriptionArr.map((data, index) =>
                              urlRegex({ exact: true }).test(data) ? (
                                <div key={index}>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="postdetail_link"
                                    href={
                                      data.includes("https://") ||
                                      data.includes("http://")
                                        ? data
                                        : `https://${data}`
                                    }
                                  >
                                    {data}
                                  </a>
                                  <br />
                                </div>
                              ) : (
                                <div key={index}>
                                  <span>{data}</span>
                                  <br />
                                </div>
                              )
                            )
                          : "조회 불가"}
                      </UploadDescription>
                    </UploadList>
                  </UploadListContainer>
                  <IsLikedContainer id="isliked_container">
                    <IsLikedBox id="isliked_box">
                      <IsLikedCount id="islikedCount">
                        {fetchCount ? fetchCount : 0}
                      </IsLikedCount>
                      <IsLikedImg
                        id="islikedImg"
                        isliked={
                          fetchLiked === null || fetchLiked === undefined
                            ? false
                            : fetchLiked && fetchLiked.isLiked
                            ? true
                            : false
                        }
                        onClick={(e) => this.handleLiked(e)}
                      ></IsLikedImg>
                    </IsLikedBox>
                  </IsLikedContainer>
                  <CommentListContainer id="comment_list_container">
                    {notDeletedFetchComments ? (
                      notDeletedFetchComments.map((data, index) => (
                        <div key={index} className={data.id}>
                          <CommentList className="comment_list" key={index}>
                            <CommentWriterProfile className="comment_writer">
                              <SLink
                                to={`/userdetail/${data.id.split(`post-`)[1]}`}
                              >
                                {data.nick_name ? data.nick_name : "조회 불가"}
                              </SLink>
                            </CommentWriterProfile>
                            <CommentContent
                              className="comment_content"
                              current={
                                data.id.includes(fetchMe.id) ? true : false
                              }
                            >
                              {data.content ? data.content : "조회 불가"}
                            </CommentContent>
                            <AdditonalCommentBtn
                              className="additionalCommentBtn"
                              current={
                                data.id.includes(fetchMe.id) ? true : false
                              }
                              id={data.id}
                              onClick={(e) => {
                                if (!this.state.isadditional) {
                                  this.setState({ isadditional: true });
                                  this.additionalCommentClick(e, true);
                                } else {
                                  this.setState({ isadditional: false });
                                  this.additionalCommentClick(e, false);
                                }
                              }}
                            >
                              {/* {
                                PostDetailTextData.Post_Detail_AdditionalComment_Btn
                              } */}
                              답글
                            </AdditonalCommentBtn>

                            {data.id.includes(fetchMe.id) ? (
                              <RenewalBtn
                                className="renewal_btn_comment"
                                id={`btn-${data.id}`}
                                onClick={(e) => this.deleteComment(e)}
                              >
                                {/* <span role="img" aria-label="emoji">
                                  🗑
                                </span> */}
                                삭제
                                {/* {
                                  PostDetailTextData.Post_Detail_AdditionalComment_Delete
                                } */}
                              </RenewalBtn>
                            ) : (
                              <></>
                            )}
                            {loweripad ? (
                              <></>
                            ) : (
                              <CommentCreatedAt
                                className="comment_createdAt"
                                current={
                                  data.id.includes(fetchMe.id) ? true : false
                                }
                              >
                                {data.createdAt.split("T")[0]}
                              </CommentCreatedAt>
                            )}
                          </CommentList>
                          {fetchAdditionalComments ? (
                            additionalCommentsByCommentId(data.id).map(
                              (data, index) => (
                                <div key={index}>
                                  <AdditionalCommentList id="additionalComment_list">
                                    <CommentWriterProfile className="additional_comment_writer">
                                      <SLink
                                        to={`/userdetail/${
                                          data.id.split("post-")[1]
                                        }`}
                                      >
                                        {data.nick_name}
                                      </SLink>
                                    </CommentWriterProfile>
                                    <AdditionalCommentImg className="additional_comment_img"></AdditionalCommentImg>
                                    <AdditionalCommentContent className="additional_comment_content">
                                      {data.content}
                                    </AdditionalCommentContent>
                                    {data.id.includes(fetchMe.id) ? (
                                      <RenewalBtn
                                        className="additional_comment_renewalbtn"
                                        additional={true}
                                        id={data.id}
                                        onClick={(e) =>
                                          this.deleteAdditionalComment(e)
                                        }
                                      >
                                        {/* X */}
                                        {/* <span role="img" aria-label="emoji">
                                          🗑
                                        </span> */}
                                        삭제
                                      </RenewalBtn>
                                    ) : (
                                      <></>
                                    )}
                                    {loweripad ? (
                                      <></>
                                    ) : (
                                      <AdditionalCommentCreatedAt className="additional_comment_createdAt">
                                        {data.createdAt.split("T")[0]}
                                      </AdditionalCommentCreatedAt>
                                    )}
                                  </AdditionalCommentList>
                                </div>
                              )
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </CommentListContainer>
                  <CommentWriteContainer id="comment_write_under_con">
                    <CommentWriterProfile className="comment_write_under_profile">
                      {fetchMe && fetchMe.nick_name
                        ? fetchMe.nick_name
                        : "조회 불가"}
                    </CommentWriterProfile>
                    <CommentWriteInput
                      className="comment_write_under_input"
                      onChange={(e) =>
                        this.setState({
                          userComment: e.target.value,
                        })
                      }
                    ></CommentWriteInput>
                    <CommentWriteBtn
                      className="comment_write_under_btn"
                      onClick={(e) => this.createComment()}
                    >
                      {minimumDevice
                        ? "댓글"
                        : PostDetailTextData.Post_Detail_Add_Comment}
                    </CommentWriteBtn>
                  </CommentWriteContainer>
                  <FlexBox className="post_detail_under_flexbox">
                    {editAuthor ? (
                      <>
                        <BackBtn
                          className="post_detail_under_btn"
                          // onClick={(e) => this.handleDelete(postData)}
                          onClick={(e) =>
                            window.confirm("삭제하시겠습니까?")
                              ? this.handleDelete(postData)
                              : ""
                          }
                        >
                          {minimumDevice
                            ? "삭제"
                            : PostDetailTextData.Post_Detail_Nav_Btn_02}
                        </BackBtn>
                        <BackBtn
                          className="post_detail_under_btn"
                          onClick={(e) => this.handleBackPage()}
                        >
                          {minimumDevice
                            ? "목록"
                            : PostDetailTextData.Post_Detail_Nav_Btn_01}
                        </BackBtn>
                        <BackBtn className="post_detail_under_btn">
                          <SLink to={`/editpost/${newPostId}`}>
                            {minimumDevice
                              ? "수정"
                              : PostDetailTextData.Post_Detail_Nav_Btn_03}
                          </SLink>
                        </BackBtn>
                      </>
                    ) : (
                      <BackBtn
                        className="post_detail_under_btn"
                        onClick={(e) => this.handleBackPage()}
                      >
                        {minimumDevice
                          ? "목록"
                          : PostDetailTextData.Post_Detail_Nav_Btn_01}
                      </BackBtn>
                    )}
                  </FlexBox>
                </UploadBox>
              </UploadContainer>
            </IntroDetail>
          ) : (
            <Error
              error={
                this.state.errorMsg !== ""
                  ? this.state.errorMsg
                  : TextData.Access_Deny
              }
            />
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterPost = withRouter(PostPresenter);
export default withRouterPost;
