import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import HeaderImg from "../assets/all/newlogo.png";
import { opacity, turn_y_axis } from "../Animations";
import TextData from "../Json/headerJson.json";
import { SLink } from "./Layout";
import "./headerponsive.css";

// 반응형 고민
// import { device } from "../Devices/devices";

const HeaderOptions = styled.ul`
  /* width: 200px; */
  /* height: 200px; */
  background-color: #1a1a1a;
  opacity: 0;
  /* margin-left: 70px; */
  /* border: solid 5px #00ee13; */
  z-index: 2;
`;

// 이미지 fit 적용
const HeaderLogo = styled.div`
  background-image: url(${HeaderImg});
  background-repeat: no-repeat;
  /* object-fit: contain; */
`;

const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 220px; */
  &:first-child:hover {
    ${HeaderOptions} {
      animation: ${opacity} 0.7s ease-in-out;
      opacity: 1;
    }
  }
  /* @media screen and (max-width: 1600px) {
    display: none;
  } */
`;

const HeaderOption = styled.li`
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.current ? "1" : "0.5")};
  /* margin-top: 15px; */
  /* margin-left: 25px; */
  font-family: "Noto Sans KR";
  /* font-weight: 600; */
  /* font-size: 20px; */
  /* 
  &:first-child {
    margin-top: 25px;
  } */

  :hover {
    animation: ${opacity} 0.7s ease-in-out;
    opacity: 1;
  }
`;

const CommunityOptionText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00ee13;
  /* width: 45px; */
  /* height: 20px; */
  /* margin-left: 5px; */
  font-family: "Noto Sans KR";
  font-weight: 600;
  /* font-size: 12px; */
  color: #1a1a1a;
`;

const HeaderContainer = styled.div`
  /* 반응형 만지려고 하는중 */
  position: absolute;
  top: 0;
`;

const Header = withRouter(({ location: { pathname }, isadmin, info }) => {
  if (isadmin) {
    return (
      <HeaderContainer className="HeaderContainer">
        <LogoBox className="logoBox">
          <SLink to="/">
            <HeaderLogo
              className="headerLogo"
              src={HeaderImg}
              alt="FFF_LOGO"
              id="FFF_LOGO"
            />
          </SLink>
          <HeaderOptions className="headerul">
            <HeaderOption className="headeropt" current={pathname === "/intro"}>
              <SLink to="/intro">{TextData.IndexNav01}</SLink>
            </HeaderOption>
            <HeaderOption
              className="headeropt"
              current={pathname === "/portfolio"}
            >
              <SLink to="/portfolio">{TextData.IndexNav02}</SLink>
            </HeaderOption>
            <HeaderOption
              className="headeropt"
              current={pathname === `/jboard/index=?1`}
            >
              <SLink to={`/jboard/index=?1`}>{TextData.IndexNav03}</SLink>
              <CommunityOptionText className="headerNavText">
                {TextData.IndexNav03_add}
              </CommunityOptionText>
            </HeaderOption>
            <HeaderOption
              className="headeropt"
              current={pathname === `/admin/${info}`}
            >
              <SLink to={`/admin/${info}`}>어드민권한</SLink>
            </HeaderOption>
          </HeaderOptions>
        </LogoBox>
      </HeaderContainer>
    );
  } else {
    return (
      <HeaderContainer className="HeaderContainer">
        <LogoBox className="logoBox">
          <SLink to="/">
            <HeaderLogo
              className="headerLogo"
              // src={HeaderImg}
              alt="FFF_LOGO"
              id="FFF_LOGO"
            />
          </SLink>
          <HeaderOptions className="headerul">
            <HeaderOption className="headeropt" current={pathname === "/intro"}>
              <SLink to="/intro">{TextData.IndexNav01}</SLink>
            </HeaderOption>
            <HeaderOption
              className="headeropt"
              current={pathname === "/portfolio"}
            >
              <SLink to="/portfolio">{TextData.IndexNav02}</SLink>
            </HeaderOption>
            <HeaderOption
              className="headeropt"
              current={pathname === "/jboard/index=?1"}
            >
              <SLink to="/jboard/index=?1">{TextData.IndexNav03}</SLink>
              <CommunityOptionText className="headerNavText">
                {TextData.IndexNav03_add}
              </CommunityOptionText>
            </HeaderOption>
            {/* <HeaderOption
              className="headeropt"
              current={pathname === `/admin/${info}`}
            >
              <SLink to={`/admin/${info}`}>어드민권한</SLink>
            </HeaderOption> */}
          </HeaderOptions>
        </LogoBox>
      </HeaderContainer>
    );
  }
});

export default Header;
