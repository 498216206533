import React from "react";
import PortfolioPresenter from "./PortfolioPresenter";
import DefaultBg from "../../assets/all/pc_bg.jpg";

export default class extends React.Component {
  render() {
    const bodyselector = document.getElementsByTagName("body")[0];
    bodyselector.style.backgroundImage = `url(${DefaultBg})`;
    return <PortfolioPresenter />;
  }
}
