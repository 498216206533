import { keyframes } from "styled-components";

// 우측하단에서 등장하는 애니메이션
export const right_under_ani = keyframes`
0%{
transform:translate(1000px,500px);
opacity:0;
}
100%{
transform:none;
opacity:1;
}
`;

// 아이콘 y축 회전
export const turn_y_axis = keyframes`
from{
    transform:none;
    opacity:0;
}
to{
    transform:rotateY(1turn);
    opacity:1;
}`;

// opacity 0 to 1
export const opacity = keyframes`
from{
    opacity:0;
}
to{
    opacity:1;
}`;

// 배경 내려오는 것

export const bgDownAni = keyframes`
from{
    transform:translateY(-400px)
}
to{
    transform:none;
}
`;
