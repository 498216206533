import * as React from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, notification, Input, Col } from "antd";
import Helmet from "react-helmet";
import Header from "../Components/Header";
import MobileHeader from "../Components/MobileHeader";
import "./antd.css";
import styled from "styled-components";
// amplify
import { Auth } from "aws-amplify";
import { handleError } from "./HandleError";
import { LoadingOutlined } from "@ant-design/icons";
/** Presentational */
import FullWidthWrapper from "./FullWidthWrapper";
import EmailConfirmFormWrapper from "./EmailConfirmFormWrapper";
import LogInBg from "../assets/03_community/login_bg.png";
import TextData from "../Json/confirmEmailJson.json";
import "./responsiveConfirm.css";
const JoinLayout = styled.div`
  position: absolute;
  /* top: 100px; */
  /* width: 1200px; */
  /* height: 750px; */
  background-color: #1a1a1a;
  /* margin-left: 330px; */
  border: solid 5px #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BackgroundImg = styled.div`
  position: absolute;
  width: 650px;
  height: 650px;
  background-image: url(${LogInBg});
  background-size: cover;
  opacity: 0.4;
`;

class ConfirmEmailContainer extends React.Component {
  state = {
    username: "",
    loading: false,
    redirect: false,
    confirmationCode: "",
    error: "",
  };

  async componentDidMount() {
    if (window.location.hash) {
      // get username from url param
      let username = window.location.hash.split("=")[1];
      this.setState({ username });
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { confirmationCode } = this.state;

    // show progress spinner
    this.setState({ loading: true });

    Auth.confirmSignUp(this.state.username, confirmationCode)
      .then(() => {
        this.handleOpenNotification(
          TextData.success_title,
          TextData.success_description01,
          TextData.success_description02
        );
      })
      .catch((err) => {
        console.log(err);
        this.handleOpenNotification("error", handleError(err.message));
        this.setState({
          loading: false,
        });
      });
  };

  handleOpenNotification = (type, title, message) => {
    switch (type) {
      case "success":
        notification["success"]({
          message: title,
          description: message,
          placement: "bottom",
          duration: 3.5,
          onClose: () => {
            this.setState({ redirect: true });
          },
        });
        break;

      case "error":
        notification["error"]({
          message: title,
          description: message,
          placement: "bottom",
          duration: 1.5,
        });
        break;
      default:
        console.log("nope");
    }
  };

  handleOnPaste = (event) => {
    event.preventDefault();

    let code = event.clipboardData.getData("Text").trim();

    /** Update input */
    this.setState({ confirmationCode: code });

    // regex to check if string is numbers only
    const reg = new RegExp("^[0-9]+$");

    if (reg.test(code) && code.length === 6) {
      // code is a valid number

      this.setState({ loading: true });

      Auth.confirmSignUp(this.state.username, code)
        .then(() => {
          this.handleOpenNotification(
            TextData.success_title,
            TextData.success_description01,
            TextData.success_description02
          );
        })
        .catch((err) => {
          this.handleOpenNotification(
            TextData.error_title,
            TextData.error_description,
            err.message
          );
          this.setState({
            loading: false,
          });
        });
    }
  };

  handleChange = (event) => {
    this.setState({ confirmationCode: event.currentTarget.value });
  };

  render() {
    let mobilebranch;
    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    const { loading, error, confirmationCode, redirect } = this.state;

    return (
      <>
        <Helmet>
          <title>Confirmation | FFFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <JoinLayout id="confirm_layout">
          {/* <BackgroundImg /> */}
          <FullWidthWrapper align="center">
            <EmailConfirmFormWrapper
              className="emailconfirmWrapper"
              onSubmit={this.handleSubmit}
            >
              <Col md={24} lg={18}>
                <div className="confirm-txt">
                  <h2>{TextData.confirm_title}</h2>
                  <p>{TextData.confirm_msg}</p>
                </div>
                <Form.Item
                  validateStatus={error && "error"}
                  help={error}
                  label={TextData.label_text}
                >
                  <Input
                    size="large"
                    type="number"
                    placeholder="Enter confirmation code"
                    onChange={this.handleChange}
                    onPaste={this.handleOnPaste}
                    value={confirmationCode}
                  />
                </Form.Item>
              </Col>
              <Col md={24} lg={12} className="confirm_flex">
                <Button
                  type="primary"
                  disabled={loading}
                  htmlType="submit"
                  size="large"
                  className="confirm_submit_btn"
                >
                  {loading ? (
                    <LoadingOutlined spin style={{ fontSize: "4vw" }} />
                  ) : (
                    "이메일 인증하기"
                  )}
                </Button>
              </Col>
            </EmailConfirmFormWrapper>
            {redirect && <Redirect to={{ pathname: "/login" }} />}
          </FullWidthWrapper>
        </JoinLayout>
      </>
    );
  }
}

export default ConfirmEmailContainer;
