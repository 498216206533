import { Auth, API } from "aws-amplify";
import {
  getUser,
  getPortfolio,
  getPost,
  getQuestion,
  listPosts,
} from "../graphql/queries";

export const getCurrentUsername = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user.username) {
          resolve(user);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};

export const getCurrentUsernameGetSub = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user.username) {
          resolve(user.attributes.sub);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};
export const getCurrentUserInfo = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentUserInfo()
      .then((user) => {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};

export const fetchOneUserById = async (id) => {
  return new Promise((resolve, reject) => {
    API.graphql({
      query: getUser,
      variables: { id },
    })
      .then((user) => {
        if (user.data.getUser) {
          resolve(user.data.getUser);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};

export const fetchOnePortFolioById = async (id) => {
  return new Promise((resolve, reject) => {
    API.graphql({
      query: getPortfolio,
      variables: { id },
    })
      .then((post) => {
        if (post.data.getPortfolio) {
          resolve(post.data.getPortfolio);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};

export const fetchOnePostById = async (id) => {
  return new Promise((resolve, reject) => {
    API.graphql({
      query: getPost,
      variables: { id },
    })
      .then((post) => {
        if (post.data.getPost) {
          resolve(post.data.getPost);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};

export const fetchOneQuestionById = async (id) => {
  return new Promise((resolve, reject) => {
    API.graphql({
      query: getQuestion,
      variables: { id },
    })
      .then((question) => {
        if (question.data.getQuestion) {
          resolve(question.data.getQuestion);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};

export const onSignOutClick = async () => {
  await Auth.signOut({ global: true })
    .then((data) => {
      console.log(`SignOut Success : ${data}`);
      window.location.reload();
    })
    .catch((err) => console.log(err));
};

export const fetchAllPostById = async (id) => {
  return new Promise((resolve, reject) => {
    API.graphql({
      query: listPosts,
      variables: { filter: { id: { contains: id } }, limit: 1000 },
    })
      .then((post) => {
        if (post.data.listPosts && post.data.listPosts.items) {
          resolve(post.data.listPosts.items);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};

// 조건없이 모든 컨디션 조회
export const fetchAllPostT = async () => {
  return new Promise((resolve, reject) => {
    API.graphql({
      query: listPosts,
      variables: { limit: 1000 },
    })
      .then((post) => {
        if (post.data.listPosts && post.data.listPosts.items) {
          resolve(post.data.listPosts.items);
        } else {
          resolve(null);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve(null);
      });
  });
};
