import * as React from "react";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Form,
  Input,
  Icon,
  Button,
  notification,
  Popover,
  Row,
  Col,
} from "antd";
import "./antd.css";
import { LoadingOutlined } from "@ant-design/icons";
import Helmet from "react-helmet";
import Header from "../Components/Header";
import MobileHeader from "../Components/MobileHeader";

import styled from "styled-components";
import LogInBg from "../assets/03_community/login_bg.png";
import { handleError } from "./HandleError";
/** App theme */
import { colors } from "./Theme";
import FormWrapper from "./FormWrapper";
import TextData from "../Json/resetPasswordJson.json";
import "./responsivepwdreset.css";

const JoinLayout = styled.div`
  position: absolute;
  /* top: 100px; */
  /* width: 1200px; */
  /* height: 750px; */
  background-color: #1a1a1a;
  /* margin-left: 330px; */
  border: solid 5px #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BackgroundImg = styled.div`
  position: absolute;
  width: 650px;
  height: 650px;
  background-image: url(${LogInBg});
  background-size: cover;
  opacity: 0.4;
`;
const passwordValidator = require("password-validator");
const schema = new passwordValidator();
schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

class PasswordResetContainer extends React.Component {
  state = {
    confirmDirty: false,
    redirect: false,
    loading: false,
  };

  handleBlur = (event) => {
    const value = event.currentTarget.value;

    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;

    if (value && value !== form.getFieldValue("password")) {
      callback("비밀번호가 일치하지 않습니다.");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    const validationRulesErrors = schema.validate(value, { list: true });

    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    if (validationRulesErrors.length > 0) {
      callback(this.formatPasswordValidateError(validationRulesErrors));
    }
    callback();
  };

  formatPasswordValidateError = (errors) => {
    for (let i = 0; i < errors.length; i++) {
      if (errors[i] === "min") {
        return "오류: 비밀번호는 반드시 8자 이상이여야 합니다.";
      } else if (errors[i] === "lowercase") {
        return "오류: 비밀번호는 반드시 영문 소문자를 포함해야 합니다.";
      } else if (errors[i] === "uppercase") {
        return "오류: 비밀번호는 반드시 영문 대문자를 포함해야 합니다.";
      } else if (errors[i] === "digits") {
        return "오류: 비밀번호는 반드시 숫자가 포함되어야 합니다.";
      } else if (errors[i] === "symbols") {
        return "오류: 비밀번호는 반드시 특수문자가 포함되어야 합니다.";
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let { password, code } = values;
        let username = window.location.hash.split("=")[1];
        Auth.forgotPasswordSubmit(username.trim(), code.trim(), password.trim())
          .then(() => {
            notification.success({
              message: TextData.success_msg,
              description: TextData.success_description,
              placement: "topRight",
              duration: 1.5,
              onClose: () => {
                this.setState({ redirect: true });
              },
            });
          })
          .catch((err) => {
            console.log(err);
            notification["error"]({
              message: TextData.err_msg,
              description: handleError(err.message),
              placement: "topRight",
              duration: 1.5,
            });

            this.setState({ loading: false });
          });

        // show loader
        this.setState({ loading: true });
      }
    });
  };

  render() {
    let mobilebranch;
    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    const { getFieldDecorator } = this.props.form;
    const { redirect, loading } = this.state;
    const title = TextData.reset_pwd_info;
    const passwordPolicyContent = (
      <React.Fragment>
        <Col lg={45}>
          <ul>
            <li>{TextData.reset_pwd_list01}</li>
            <li>{TextData.reset_pwd_list02}</li>
            <li>{TextData.reset_pwd_list03}</li>
            <li>{TextData.reset_pwd_list04}</li>
            <li>{TextData.reset_pwd_list05}</li>
          </ul>
        </Col>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>ResetPassword | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <JoinLayout id="reset_layout">
          {/* <BackgroundImg /> */}
          <Col lg={66}>
            <FormWrapper className="reset-form" onSubmit={this.handleSubmit}>
              <div className="reset_popup">
                <p>{TextData.reset_after_send_code}</p>
              </div>
              <Form.Item
                className="reset_form_item"
                // style={{ marginBottom: "35px" }}
              >
                <Row>
                  <Col lg={24}>
                    {getFieldDecorator("code", {
                      rules: [
                        {
                          required: true,
                          message: TextData.err_description,
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{
                              color: colors.white,
                              marginRight: "20px",
                              fontSize: "15px",
                            }}
                          />
                        }
                        type="number"
                        placeholder={TextData.placeholder_confirm_code}
                      />
                    )}
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                className="reset_form_item"

                // style={{ marginBottom: "35px" }}
              >
                <Popover
                  placement="left"
                  title={title}
                  content={passwordPolicyContent}
                  trigger="focus"
                >
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: TextData.pwd_err_description,
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{
                            color: colors.white,
                            marginRight: "20px",
                            fontSize: "15px",
                          }}
                        />
                      }
                      type="password"
                      placeholder={TextData.placeholder_change_pwd}
                    />
                  )}
                </Popover>
              </Form.Item>

              <Form.Item
                className="reset_form_item"

                // style={{ marginBottom: "35px" }}
              >
                <Row>
                  <Col lg={24}>
                    {getFieldDecorator("confirm", {
                      rules: [
                        {
                          required: true,
                          message: TextData.confirm_pwd_err_description,
                        },
                        {
                          validator: this.compareToFirstPassword,
                        },
                      ],
                    })(
                      <Input
                        prefix={
                          <Icon
                            type="lock"
                            style={{
                              color: colors.white,
                              marginRight: "20px",
                              fontSize: "15px",
                            }}
                          />
                        }
                        type="password"
                        placeholder={TextData.placeholder_confirm_pwd}
                        onBlur={this.handleBlur}
                      />
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item className="reset_under_nav_box">
                <Row>
                  <Col lg={24}>
                    <Button
                      // style={{ width: "70%", marginLeft: "70px" }}
                      type="primary"
                      htmlType="submit"
                      className="reset_submit_btn"
                      // className="login-form-button"
                    >
                      {loading ? (
                        <LoadingOutlined spin style={{ fontSize: "4vw" }} />
                      ) : (
                        "비밀번호 변경하기"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </FormWrapper>
            {redirect && <Redirect to={{ pathname: "/login" }} />}
          </Col>
        </JoinLayout>
      </React.Fragment>
    );
  }
}

export default Form.create()(PasswordResetContainer);
