export function handleError(error) {
  switch (error) {
    case "User already exists":
      return "이미 사용되고 있는 이메일입니다. 관리자에게 문의하세요.";
    case "Invalid email address format.":
      return "올바른 이메일 형식이 아닙니다.";
    case "Invalid phone number format.":
      return "올바른 전화번호가 아닙니다. 다시 입력하세요.";
    case "Invalid verification code provided, please try again.":
      return "올바른 인증번호가 아닙니다. 다시 입력해주세요.";
    case "Incorrect username or password.":
      return "이메일과 비밀번호를 다시 입력해주세요.";
    case "User does not exist.":
      return "존재하는 유저가 아닙니다. 회원 가입 후 이용해주세요.";
    case "Username/client id combination not found.":
      return "해당되는 유저를 찾을 수 없습니다. 확인 후 다시 입력해주세요.";
    case "Password does not conform to policy: Password must have symbol characters":
      return "비밀번호는 특수문자를 반드시 포함하여야 합니다.";
    case "Attempt limit exceeded, please try after some time.":
      return "계속되는 오류에 따라 잠시동안 이용이 불가합니다. 잠시 후 다시 시도해주세요.";
    case "User is not confirmed.":
      return "비허가 유저입니다. 관리자에게 문의 바랍니다.";
    default:
      return "알 수 없는 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.";
  }
}
