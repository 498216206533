/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-northeast-2",
  aws_cognito_identity_pool_id:
    "ap-northeast-2:9c6a1781-f5f7-476f-810d-d18187849e11",
  aws_cognito_region: "ap-northeast-2",
  aws_user_pools_id: "ap-northeast-2_e9Tvlu7oO",
  aws_user_pools_web_client_id: "5snoe7stq960k5abb5jdv1o3ji",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://6w5bdgkyvfgofj6wjqv7epixhi.appsync-api.ap-northeast-2.amazonaws.com/graphql",
  aws_appsync_region: "ap-northeast-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-hd2flkv2tzgcjmeuiulc24cq2e",
  aws_user_files_s3_bucket: "fffprofile165010-dev",
  aws_user_files_s3_bucket_region: "ap-northeast-2",
};

export default awsmobile;
