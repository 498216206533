import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import {
  AdminIntroLayout as IntroLayout,
  Q_Detail_IntroDetail as IntroDetail,
  Q_Detail_MyQuestionContainer as MyQuestionContainer,
  Q_Detail_MyQuestionBox as MyQuestionBox,
  Q_Detail_QuestionHeader as QuestionHeader,
  Q_Detail_MyQuestionTitle as MyQuestionTitle,
  Q_Detail_MyQuestionDescription as MyQuestionDescription,
  Q_Detail_AnswerSubmitBtn as AnswerSubmitBtn,
  Q_Detail_MyAnswerBox as MyAnswerBox,
  Q_Detail_AnswerHeader as AnswerHeader,
  Q_Detail_MyAnswerTitle as MyAnswerTitle,
  Q_Detail_MyAnswerDescription as MyAnswerDescription,
  Q_Detail_AnswerBtnBox as AnswerBtnBox,
  Q_Detail_ClientInfoBox as ClientInfoBox,
  Q_Detail_ClientColumn as ClientColumn,
  Q_Detail_ClientContent as ClientContent,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import Error from "../../Components/Error";
import TextData from "../../Json/AdminJson.json";
import { API } from "aws-amplify";
import { updateQuestion as updateQuestionM } from "../../graphql/mutations";
import {
  getCurrentUsernameGetSub,
  getCurrentUserInfo,
  fetchOneUserById,
  fetchOneQuestionById,
} from "../../Controllers/amplifyController";

const initialFormState = {
  id: "",
  answer_title: "",
  answer: "",
  _version: "",
  holding: true,
};

class QuestionDetailPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      isAdmin: false,
      formData: initialFormState,
    };
  }

  async handleKeyPress(e) {
    if (e.charCode === 13) {
      e.preventDefault();
    }
  }
  async handleUpdate() {
    const { formData: data } = this.state;
    try {
      await API.graphql({
        query: updateQuestionM,
        variables: {
          input: {
            id: data.id,
            _version: data._version,
            answer_title: data.answer_title,
            answer: data.answer,
            holding: false,
          },
        },
      });
      this.setState({ isloading: true });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
      window.history.back();
    }
  }

  async handleHolding() {
    const { formData: data } = this.state;
    try {
      await API.graphql({
        query: updateQuestionM,
        variables: {
          input: {
            id: data.id,
            _version: data._version,
            answer_title: "",
            answer: "",
            holding: true,
          },
        },
      });
      this.setState({ isloading: true });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
      window.history.back();
    }
  }

  async componentDidMount() {
    let user;
    let info;
    let profileData;
    let questionData;
    let newDate;
    const {
      location: { pathname },
    } = this.props;
    const questionId = await pathname.split("/admin_question_details/")[1];

    try {
      info = await getCurrentUsernameGetSub();
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(user.id);
      questionData = await fetchOneQuestionById(questionId);
      if (questionData && questionData !== null) {
        var d = new Date(questionData.createdAt);
        newDate = `${d.getUTCFullYear()}-${
          d.getUTCMonth().toString().length === 1
            ? `0${d.getUTCMonth()}`
            : d.getUTCMonth()
        }-${d.getUTCDate()} ${
          d.getHours().toString().length === 1
            ? `0${d.getHours()}`
            : d.getHours()
        }:${
          d.getMinutes().toString().length === 1
            ? `0${d.getMinutes()}`
            : d.getMinutes()
        }`;
      }

      if (profileData && profileData.isAdmin && info !== null) {
        this.setState({
          isloading: true,
          info,
          profileData,
          formData: {
            ...this.state.formData,
            id: questionData.id,
            answer: questionData.answer,
            answer_title: questionData.answer_title,
            question_title: questionData.question_title,
            question: questionData.question,
            _version: questionData._version,
          },
          isAdmin: true,
          questionData: {
            ...questionData,
            createdAt: newDate,
          },
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
    }
  }
  handleBackPage() {
    window.history.back();
  }
  render() {
    const { isloading, isAdmin, questionData, formData } = this.state;

    return (
      <>
        <Helmet>
          <title>QuestionDetail | FFF</title>
        </Helmet>
        <Header />
        <IntroLayout>
          {isloading ? (
            <Loader />
          ) : isAdmin ? (
            <>
              <IntroDetail>
                <MyQuestionContainer>
                  <ClientInfoBox>
                    <ClientColumn>
                      {TextData.Admin_Question_Detail_Column01}
                    </ClientColumn>
                    <ClientContent>
                      {questionData && questionData.real_name
                        ? questionData.real_name
                        : "조회불가"}
                    </ClientContent>
                    <ClientColumn>
                      {TextData.Admin_Question_Detail_Column02}
                    </ClientColumn>
                    <ClientContent>
                      {questionData && questionData.email
                        ? questionData.email
                        : "조회불가"}
                    </ClientContent>
                    <ClientColumn>
                      {TextData.Admin_Question_Detail_Column03}
                    </ClientColumn>
                    <ClientContent>
                      {questionData && questionData.nick_name
                        ? questionData.nick_name
                        : "조회불가"}
                    </ClientContent>
                    <ClientColumn>
                      {TextData.Admin_Question_Detail_Column04}
                    </ClientColumn>
                    <ClientContent>
                      {questionData && questionData.phone_number
                        ? questionData.phone_number
                        : "조회불가"}
                    </ClientContent>
                    <ClientColumn>
                      {TextData.Admin_Question_Detail_Column05}
                    </ClientColumn>
                    <ClientContent>
                      {questionData && questionData.createdAt
                        ? questionData.createdAt
                        : "조회불가"}
                    </ClientContent>
                  </ClientInfoBox>
                  <MyQuestionBox>
                    <QuestionHeader>
                      {TextData.Admin_Question_Detail_Column06}
                    </QuestionHeader>
                    <MyQuestionTitle
                      disabled
                      defaultValue={
                        questionData && questionData.question_title
                          ? questionData.question_title
                          : "조회불가"
                      }
                    ></MyQuestionTitle>
                    <MyQuestionDescription
                      disabled
                      defaultValue={
                        questionData && questionData.question
                          ? questionData.question
                          : "조회불가"
                      }
                    ></MyQuestionDescription>
                  </MyQuestionBox>
                  <MyAnswerBox>
                    <AnswerHeader>
                      {TextData.Admin_Question_Detail_Column07}
                    </AnswerHeader>
                    <MyAnswerTitle
                      onKeyPress={(e) => this.handleKeyPress(e)}
                      maxLength="60"
                      placeholder={"답변제목"}
                      defaultValue={
                        questionData && questionData.answer_title
                          ? questionData.answer_title
                          : ""
                      }
                      onChange={(e) =>
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            answer_title: e.target.value,
                          },
                        })
                      }
                    ></MyAnswerTitle>
                    <MyAnswerDescription
                      placeholder={"답변내용"}
                      defaultValue={
                        questionData && questionData.answer
                          ? questionData.answer
                          : ""
                      }
                      onChange={(e) =>
                        this.setState({
                          formData: {
                            ...formData,
                            answer: e.target.value,
                          },
                        })
                      }
                    ></MyAnswerDescription>
                    <AnswerBtnBox>
                      <AnswerSubmitBtn onClick={() => this.handleBackPage()}>
                        {TextData.Admin_Question_Detail_Column08}
                      </AnswerSubmitBtn>
                      <AnswerSubmitBtn onClick={() => this.handleUpdate()}>
                        {questionData && questionData.holding
                          ? "답변하기"
                          : "수정하기"}
                      </AnswerSubmitBtn>
                      {questionData && !questionData.holding ? (
                        <AnswerSubmitBtn
                          onClick={() => this.handleHolding(formData)}
                        >
                          {TextData.Admin_Question_Detail_Column09}
                        </AnswerSubmitBtn>
                      ) : (
                        <></>
                      )}
                    </AnswerBtnBox>
                  </MyAnswerBox>
                </MyQuestionContainer>
              </IntroDetail>
            </>
          ) : (
            <>
              <Error error={TextData.Access_Deny} />
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterQuestionDetail = withRouter(QuestionDetailPresenter);
export default withRouterQuestionDetail;
