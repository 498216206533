import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import Header from "../Components/Header";
import MobileHeader from "../Components/MobileHeader";

import { Auth } from "aws-amplify";
import {
  Form,
  Input,
  Button,
  notification,
  Popover,
  Col,
  Row,
  Icon,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { handleError } from "./HandleError";
// Presentational
import FormWrapper from "./FormWrapper";
import styled from "styled-components";
// App theme
import { colors } from "./Theme";
import "./antd.css";
import TextData from "../Json/joinJson.json";
import "./responsivejoin.css";
const JoinLayout = styled.div`
  position: absolute;
  /* top: 100px; */
  /* width: 1200px; */
  /* height: 750px; */
  background-color: #1a1a1a;
  /* margin-left: 330px; */
  border: solid 5px #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TitleBox = styled.div`
  /* width: 200px; */
  /* height: 10px; */
  position: relative;
  /* top: -40px; */
  font-family: "Alfredo";
  /* font-size: 15px; */
  font-weight: 600;
`;
const passwordValidator = require("password-validator");

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();
class SignUpContainer extends React.Component {
  state = {
    confirmDirty: false,
    redirect: false,
    loading: false,
    email: "",
  };

  handleOpenNotification = (type, title, message) => {
    switch (type) {
      case "success":
        notification["success"]({
          message: title,
          description: message,
          placement: "bottom",
          duration: 3.5,
          onClose: () => {
            this.setState({ redirect: true });
          },
        });
        break;

      case "error":
        notification["error"]({
          message: title,
          description: message,
          placement: "bottom",
          duration: 3.5,
        });
        break;
      default:
        console.log("nope");
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let { realname, password, email, phoneNumber } = values;
        // show loader
        this.setState({ loading: true });

        Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            name: realname,
            phone_number: `+82${phoneNumber}`,
          },
        })
          .then(() => {
            notification.success({
              message: TextData.join_success_msg,
              description: TextData.join_success_description,
              placement: "bottom",
              duration: 3.5,
              onClose: () => {
                this.setState({ redirect: true });
              },
            });

            this.setState({ email });
          })
          .catch((err) => {
            notification.error({
              message: TextData.join_err_msg,
              description: handleError(err.message),
              placement: "bottom",
              duration: 1.5,
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  handleConfirmBlur = (event) => {
    const { value } = event.currentTarget;

    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;

    if (value && value !== form.getFieldValue("password")) {
      callback("비밀번호가 일치하지 않습니다.");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    const validationRulesErrors = schema.validate(value, { list: true });

    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    if (validationRulesErrors.length > 0) {
      callback(this.formatPasswordValidateError(validationRulesErrors));
    }
    callback();
  };

  formatPasswordValidateError = (errors) => {
    for (let i = 0; i < errors.length; i++) {
      if (errors[i] === "min") {
        return "오류: 비밀번호는 반드시 8자 이상이여야 합니다.";
      } else if (errors[i] === "lowercase") {
        return "오류: 비밀번호는 반드시 영문 소문자를 포함해야 합니다.";
      } else if (errors[i] === "uppercase") {
        return "오류: 비밀번호는 반드시 영문 대문자를 포함해야 합니다.";
      } else if (errors[i] === "digits") {
        return "오류: 비밀번호는 반드시 숫자가 포함되어야 합니다.";
      } else if (errors[i] === "symbols") {
        return "오류: 비밀번호는 반드시 특수문자가 포함되어야 합니다.";
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { redirect, loading } = this.state;
    let mobilebranch;
    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    const title = TextData.join_pwd_info;
    const passwordPolicyContent = (
      <React.Fragment>
        <Col lg={45}>
          <ul>
            <li>{TextData.join_pwd_list01}</li>
            <li>{TextData.join_pwd_list02}</li>
            <li>{TextData.join_pwd_list03}</li>
            <li>{TextData.join_pwd_list04}</li>
            <li>{TextData.join_pwd_list05}</li>
          </ul>
        </Col>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Helmet>
          <title> JOIN | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <JoinLayout id="join_layout">
          <FormWrapper className="join-form" onSubmit={this.handleSubmit}>
            <TitleBox id="join_title">JOIN</TitleBox>
            <Form.Item
              // style={{ marginBottom: "35px" }}
              className="join_form_item"
            >
              {getFieldDecorator("realname", {
                rules: [
                  {
                    required: true,
                    message: TextData.join_realname_msg,
                  },
                ],
              })(
                <Input
                  className="join_form_name"
                  maxLength={4}
                  prefix={
                    <Icon
                      className="join_icons"
                      type="user"
                      style={{
                        color: colors.white,
                        // marginRight: "20px",
                        // fontSize: "20px",
                      }}
                    />
                  }
                  placeholder={TextData.placeholder_realname}
                />
              )}
            </Form.Item>
            <Form.Item
              className="join_form_item"
              //  style={{ marginBottom: "35px" }}
            >
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: TextData.join_email_msg,
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon
                      className="join_icons"
                      type="mail"
                      style={{
                        color: colors.white,
                        // marginRight: "20px",
                        // fontSize: "15px",
                      }}
                    />
                  }
                  placeholder={TextData.placeholder_email}
                />
              )}
            </Form.Item>
            <Form.Item
              className="join_form_item"
              //  style={{ marginBottom: "35px" }}
            >
              {getFieldDecorator("phoneNumber", {
                rules: [
                  {
                    required: true,
                    message: TextData.join_phone_msg,
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon
                      className="join_icons"
                      type="phone"
                      style={{
                        color: colors.white,
                        // marginRight: "20px",
                        // fontSize: "20px",
                      }}
                    />
                  }
                  placeholder={TextData.placeholder_phone}
                />
              )}
            </Form.Item>
            <Form.Item
              className="join_form_item"
              // style={{ marginBottom: "35px" }}
            >
              <Popover
                placement="left"
                title={title}
                content={passwordPolicyContent}
                trigger="focus"
              >
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: TextData.join_pwd_msg,
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon
                        className="join_icons"
                        type="lock"
                        style={{
                          color: colors.white,
                          // marginRight: "20px",
                          // fontSize: "20px",
                        }}
                      />
                    }
                    type="password"
                    placeholder={TextData.placeholder_pwd}
                  />
                )}
              </Popover>
            </Form.Item>
            <Form.Item
              className="join_form_item"
              //  style={{ marginBottom: "35px" }}
            >
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: TextData.join_confirm_pwd_msg,
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon
                      className="join_icons"
                      type="lock"
                      style={{
                        color: colors.white,
                        // marginRight: "20px",
                        // fontSize: "20px",
                      }}
                    />
                  }
                  type="password"
                  placeholder={TextData.placeholder_confirm_pwd}
                  onBlur={this.handleConfirmBlur}
                />
              )}
            </Form.Item>

            <Form.Item
            // className="text-center"
            >
              <Row>
                <Col lg={24}>
                  <Button
                    // style={{ width: "100%" }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    className="join_submit_btn"
                  >
                    {loading ? (
                      <LoadingOutlined spin style={{ fontSize: "4vw" }} />
                    ) : (
                      "가입하기"
                    )}
                  </Button>
                </Col>
                <Col lg={30} className="under_nav_box">
                  <Col lg={24}>
                    <Link to="/intro">{TextData.join_link_text01}</Link>
                  </Col>
                  <Col lg={25}>
                    <Link to="/login">{TextData.join_link_text02}</Link>
                  </Col>
                </Col>
              </Row>
            </Form.Item>
          </FormWrapper>
          {redirect && (
            <Redirect
              to={{
                pathname: "/verify-code",
                search: `?email=${this.state.email}`,
              }}
            />
          )}
        </JoinLayout>
      </React.Fragment>
    );
  }
}

export default Form.create()(SignUpContainer);
