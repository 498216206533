import React from "react";
import Helmet from "react-helmet";

import {
  IntroLayout,
  IntroDetail,
  IntroLayoutMenuOption,
  SLink,
  ProfileContainer,
  BoardLowerMenuContainer,
  MyInfoContainer,
  MyInfoLeftBox,
  MyPortFolioRightBox,
  MyInfoNavContainer,
  MyInfoNavSaveBtn,
  MyInfoNavNotSaveBtn,
  MyInfoNavLogoutLeftBox,
  MyInfoNavLogoutLeftImg,
  MyInfoNavLogoutLeftContent,
  MyInfoNavLogoutRightBox,
  MyInfoNavLogoutContainer,
  MyInfoNavLogoutBtn,
  MyPortFolioThumbnailBox,
  MyPortFolioStateInfo,
  MyPortFolioStateBox,
  MyPortFolioStateColumn,
  MyPortFolioThumbnailContent,
  MyPortFolioThumbnailInfo,
  MyPortFolioRightContent,
  MyPortFolioListContainer,
  MyPortFolioList,
  MyPortFolioListCancel,
  MyPortFolioListColumn,
  MyPortFolioListInput,
  MyPortFolioListisPublicBox,
  MyPortFolioListTagContent,
  ImageClickLabel,
  ImageClickInput,
  RadioContainer,
  RadioForm,
  RadioSection,
  RadioInput,
  RadioLabel,
  MyPortFolioSelect,
  MyPortFolioSelectOption,
  MyPortFolioAttachLabel,
  MyPortFolioAttachContent,
  MyPortFolioAttachInput,
  MyPortFolioAttachSpan,
  MyPortFolioReadOnlyLimitText,
  MyPortFolioFullDescriptionTextArea,
} from "../../../Components/Layout";
import { withRouter } from "react-router-dom";
import Header from "../../../Components/Header";
import MobileHeader from "../../../Components/MobileHeader";
import TextData from "../../../Json/myPortFolioJson.json";
import { Storage, API } from "aws-amplify";
import Loader from "../../../Components/Loader";
import Error from "../../../Components/Error";
import { getPortfolio } from "../../../graphql/queries";
import {
  createPortfolio as createPortFolioM,
  updatePortfolio as updatePortfolioM,
} from "../../../graphql/mutations";
import {
  getCurrentUserInfo,
  fetchOneUserById,
  onSignOutClick,
} from "../../../Controllers/amplifyController";
import urlRegex from "url-regex";
import "./mypfresponsive.css";

const initialFormState = {
  id: "",
  user_phone_number: "",
  company_name: "",
  business_license: "",
  portfolio_tag: {
    tag_content01: "",
    tag_content02: "",
    tag_content03: "",
  },
  portfolio_oneline_description: "",
  portfolio_website: "",
  portfolio_email: "",
  portfolio_attach01: {
    portfolio_link01: "",
    portfolio_url01: "",
  },
  portfolio_attach02: {
    portfolio_link02: "",
    portfolio_url02: "",
  },
  portfolio_attach03: {
    portfolio_link03: "",
    portfolio_url03: "",
  },
  portfolio_full_description: "",
  portfolio_thumbnail_url: "",
  updatedAt: "",
  pf_permission: false,
};
let tag_arr = [];
class MyPortFolioPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: initialFormState,
      isloading: true,
      isCreate: true,
      isCharLimit: 0,
      restricted: false,
      errorMsg: "",
    };
  }

  limitText(textContent, limitNum) {
    this.setState({ isCharLimit: textContent.length });
    if (textContent.length > limitNum) {
      textContent = textContent.substring(0, limitNum);
      return textContent;
    }
  }
  async onChangeThumb(e) {
    if (!e.target.files[0]) return;
    const file = await e.target.files[0];
    const fileIdName = `${this.state.formData.id}Thumbnail`;
    this.setState({
      formData: {
        ...this.state.formData,
        portfolio_thumbnail_url: fileIdName,
      },
      portfoliothumbnailFile: file,
      portfoliothumbnailFileURL: URL.createObjectURL(file),
    });
  }
  async onChangePort01(e) {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    const fileIdName = `${this.state.formData.id}PortFolio01${file.name}`;
    this.setState({
      formData: {
        ...this.state.formData,
        portfolio_attach01: {
          ...this.state.formData.portfolio_attach01,
          portfolio_url01: fileIdName,
        },
      },
      portfolio_file01: file,
    });
  }
  async onChangePort02(e) {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    const fileIdName = `${this.state.formData.id}PortFolio02${file.name}`;
    this.setState({
      formData: {
        ...this.state.formData,
        portfolio_attach02: {
          ...this.state.formData.portfolio_attach02,
          portfolio_url02: fileIdName,
        },
      },
      portfolio_file02: file,
    });
  }
  async onChangePort03(e) {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    const fileIdName = `${this.state.formData.id}PortFolio03${file.name}`;
    this.setState({
      formData: {
        ...this.state.formData,
        portfolio_attach03: {
          ...this.state.formData.portfolio_attach03,
          portfolio_url03: fileIdName,
        },
      },
      portfolio_file03: file,
    });
  }

  async onChangeTagSelector(e) {
    let tag_value = e.target.value;
    if (tag_arr.includes(tag_value)) {
      return;
    } else if (tag_value && tag_arr.length < 3) {
      tag_arr.push(tag_value);
      if (tag_arr.length === 1) {
        this.setState({
          formData: {
            ...this.state.formData,
            portfolio_tag: {
              ...this.state.formData.portfolio_tag,
              tag_content01: tag_arr[0],
            },
          },
        });
      } else if (tag_arr.length === 2) {
        this.setState({
          formData: {
            ...this.state.formData,
            portfolio_tag: {
              ...this.state.formData.portfolio_tag,
              tag_content02: tag_arr[1],
            },
          },
        });
      } else if (tag_arr.length === 3) {
        this.setState({
          formData: {
            ...this.state.formData,
            portfolio_tag: {
              ...this.state.formData.portfolio_tag,
              tag_content03: tag_arr[2],
            },
          },
        });
      }
    } else if (tag_value && tag_arr.length >= 3) {
      tag_arr.push(tag_value);
      tag_arr = tag_arr.slice(1, 4);
      this.setState({
        formData: {
          ...this.state.formData,
          portfolio_tag: {
            tag_content01: tag_arr[0],
            tag_content02: tag_arr[1],
            tag_content03: tag_arr[2],
          },
        },
      });
    }
  }

  async handleTime() {
    const { formData } = this.state;
    const currentTime = new Date();
    this.setState({
      formData: { ...formData, updatedAt: currentTime, pf_permission: false },
    });
  }

  async handleSave() {
    const { isCreate } = this.state;
    try {
      await this.handleTime();
      if (isCreate) {
        this.createPortFolio(this.state.formData);
      } else {
        this.UpdatePortfolio(this.state.formData);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async handleCancel() {
    window.location.replace("/#intro");
  }

  async fetchPortfolio(id) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: getPortfolio,
        variables: { id },
      })
        .then((port) => {
          if (port.data.getPortfolio) {
            this.setState({
              isCreate: false,
              formData: {
                ...this.state.formData,
                business_license: port.data.getPortfolio.business_license,
                portfolio_attach01: {
                  portfolio_link01:
                    port.data.getPortfolio.portfolio_attach01.portfolio_link01,
                  portfolio_url01:
                    port.data.getPortfolio.portfolio_attach01.portfolio_url01,
                },
                portfolio_attach02: {
                  portfolio_link02:
                    port.data.getPortfolio.portfolio_attach02.portfolio_link02,
                  portfolio_url02:
                    port.data.getPortfolio.portfolio_attach02.portfolio_url02,
                },
                portfolio_attach03: {
                  portfolio_link03:
                    port.data.getPortfolio.portfolio_attach03.portfolio_link03,
                  portfolio_url03:
                    port.data.getPortfolio.portfolio_attach03.portfolio_url03,
                },
                portfolio_tag: {
                  tag_content01:
                    port.data.getPortfolio.portfolio_tag.tag_content01,
                  tag_content02:
                    port.data.getPortfolio.portfolio_tag.tag_content02,
                  tag_content03:
                    port.data.getPortfolio.portfolio_tag.tag_content03,
                },
              },
            });
            if (port.data.getPortfolio.portfolio_tag) {
              const objtoarr = Object.values(
                port.data.getPortfolio.portfolio_tag
              ).filter((data) => data !== "");
              tag_arr = objtoarr;
            }
            resolve(port.data.getPortfolio);
          } else {
            this.setState({ isCreate: true });
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  handleUpdate(newinfo, oldinfo) {
    if (newinfo === "") {
      return oldinfo;
    }
    return newinfo;
  }

  async UpdatePortfolio(formData) {
    const { portfolioData } = this.state;
    if (
      formData.portfolio_website !== "" &&
      !urlRegex({ exact: true }).test(formData.portfolio_website)
    ) {
      alert("올바른 웹사이트를 입력하세요");
      return;
    }
    if (
      formData.portfolio_attach01.portfolio_link01 !== "" &&
      !(
        formData.portfolio_attach01.portfolio_link01.includes("youtube") ||
        formData.portfolio_attach01.portfolio_link01.includes("youtu.be") ||
        formData.portfolio_attach01.portfolio_link01.includes("vimeo")
      )
    ) {
      alert("포트폴리오 1에 알맞은 url을 입력하세요.");
      return;
    }
    if (
      formData.portfolio_attach02.portfolio_link02 !== "" &&
      !(
        formData.portfolio_attach02.portfolio_link02.includes("youtube") ||
        formData.portfolio_attach02.portfolio_link02.includes("youtu.be") ||
        formData.portfolio_attach02.portfolio_link02.includes("vimeo")
      )
    ) {
      alert("포트폴리오 2에 알맞은 url을 입력하세요.");
      return;
    }
    if (
      formData.portfolio_attach03.portfolio_link03 !== "" &&
      !(
        formData.portfolio_attach03.portfolio_link03.includes("youtube") ||
        formData.portfolio_attach03.portfolio_link03.includes("youtu.be") ||
        formData.portfolio_attach03.portfolio_link03.includes("vimeo")
      )
    ) {
      alert("포트폴리오 3에 알맞은 url을 입력하세요.");
      return;
    }

    try {
      await API.graphql({
        query: updatePortfolioM,
        variables: {
          input: {
            id: portfolioData.id,
            _version: portfolioData._version,
            company_name: this.handleUpdate(
              formData.company_name,
              portfolioData.company_name
            ),
            business_license: this.handleUpdate(
              formData.business_license,
              portfolioData.business_license
            ),
            portfolio_tag: {
              tag_content01: this.handleUpdate(
                formData.portfolio_tag.tag_content01,
                portfolioData.portfolio_tag.tag_content01
              ),
              tag_content02: this.handleUpdate(
                formData.portfolio_tag.tag_content02,
                portfolioData.portfolio_tag.tag_content02
              ),
              tag_content03: this.handleUpdate(
                formData.portfolio_tag.tag_content03,
                portfolioData.portfolio_tag.tag_content03
              ),
            },

            portfolio_oneline_description: this.handleUpdate(
              formData.portfolio_oneline_description,
              portfolioData.portfolio_oneline_description
            ),
            portfolio_website: this.handleUpdate(
              formData.portfolio_website,
              portfolioData.portfolio_website
            ),
            portfolio_email: this.handleUpdate(
              formData.portfolio_email,
              portfolioData.portfolio_email
            ),
            portfolio_attach01: {
              portfolio_link01: formData.portfolio_attach01.portfolio_link01,
              portfolio_url01: formData.portfolio_attach01.portfolio_url01,
            },
            portfolio_attach02: {
              portfolio_link02: formData.portfolio_attach02.portfolio_link02,
              portfolio_url02: formData.portfolio_attach02.portfolio_url02,
            },
            portfolio_attach03: {
              portfolio_link03: formData.portfolio_attach03.portfolio_link03,
              portfolio_url03: formData.portfolio_attach03.portfolio_url03,
            },
            portfolio_full_description: this.handleUpdate(
              formData.portfolio_full_description,
              portfolioData.portfolio_full_description
            ),
            portfolio_thumbnail_url: this.handleUpdate(
              formData.portfolio_thumbnail_url,
              portfolioData.portfolio_thumbnail_url
            ),
            updatedAt: formData.updatedAt,
            pf_permission: false,
          },
        },
      });
      if (portfolioData.portfolio_thumbnail_url) {
        await Storage.put(
          formData.portfolio_thumbnail_url,
          this.state.portfoliothumbnailFile
        );
      }
      if (portfolioData.portfolio_attach01.portfolio_url01) {
        await Storage.put(
          formData.portfolio_attach01.portfolio_url01,
          this.state.portfolio_file01
        );
      }
      if (portfolioData.portfolio_attach02.portfolio_url02) {
        await Storage.put(
          formData.portfolio_attach02.portfolio_url02,
          this.state.portfolio_file02
        );
      }
      if (portfolioData.portfolio_attach03.portfolio_url03) {
        await Storage.put(
          formData.portfolio_attach03.portfolio_url03,
          this.state.portfolio_file03
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ formData: initialFormState });
      window.location.reload();
    }
  }

  async createPortFolio(formData) {
    if (!formData.id) return;

    if (formData.company_name === "") {
      alert("알맞은 상호명을 작성해주세요.");
      return;
    }
    if (formData.portfolio_tag.tag_content01 === "") {
      alert("알맞은 태그를 1개 이상 선택하여 주세요.");
      return;
    }
    if (formData.portfolio_oneline_description === "") {
      alert("한줄설명란을 작성해주세요.");
      return;
    }
    if (
      formData.portfolio_website !== "" &&
      !urlRegex({ exact: true }).test(formData.portfolio_website)
    ) {
      alert("올바른 웹사이트를 입력하세요");
      return;
    }
    if (formData.portfolio_email === "") {
      alert("올바른 이메일을 작성해주세요.");
      return;
    }
    if (formData.portfolio_thumbnail_url === "") {
      alert("알맞은 썸네일을 등록해주세요.");
      return;
    }

    if (
      formData.portfolio_attach01.portfolio_link01 !== "" &&
      !(
        formData.portfolio_attach01.portfolio_link01.includes("youtube") ||
        formData.portfolio_attach01.portfolio_link01.includes("youtu.be") ||
        formData.portfolio_attach01.portfolio_link01.includes("vimeo")
      )
    ) {
      alert("포트폴리오 1에 알맞은 url을 입력하세요.");
      return;
    }
    if (
      formData.portfolio_attach02.portfolio_link02 !== "" &&
      !(
        formData.portfolio_attach02.portfolio_link02.includes("youtube") ||
        formData.portfolio_attach02.portfolio_link02.includes("youtu.be") ||
        formData.portfolio_attach02.portfolio_link02.includes("vimeo")
      )
    ) {
      alert("포트폴리오 2에 알맞은 url을 입력하세요.");
      return;
    }
    if (
      formData.portfolio_attach03.portfolio_link03 !== "" &&
      !(
        formData.portfolio_attach03.portfolio_link03.includes("youtube") ||
        formData.portfolio_attach03.portfolio_link03.includes("youtu.be") ||
        formData.portfolio_attach03.portfolio_link03.includes("vimeo")
      )
    ) {
      alert("포트폴리오 3에 알맞은 url을 입력하세요.");
      return;
    }
    if (formData.portfolio_full_description === "") {
      alert("상세 설명을 작성해주세요.");
      return;
    }
    try {
      await API.graphql({
        query: createPortFolioM,
        variables: { input: formData },
      });
      if (formData.portfolio_thumbnail_url) {
        await Storage.put(
          formData.portfolio_thumbnail_url,
          this.state.portfoliothumbnailFile
        );
      }
      if (
        formData.portfolio_attach01.portfolio_url01 &&
        this.state.portfolio_file01
      ) {
        await Storage.put(
          formData.portfolio_attach01.portfolio_url01,
          this.state.portfolio_file01
        );
      }
      if (
        formData.portfolio_attach02.portfolio_url02 &&
        this.state.portfolio_file02
      ) {
        await Storage.put(
          formData.portfolio_attach02.portfolio_url02,
          this.state.portfolio_file02
        );
      }
      if (
        formData.portfolio_attach03.portfolio_url03 &&
        this.state.portfolio_file03
      ) {
        await Storage.put(
          formData.portfolio_attach03.portfolio_url03,
          this.state.portfolio_file03
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ formData: initialFormState });
      window.location.reload();
    }
  }
  // userdata 가져와서 맵핑하고 id값으로 아이디를 넘긴다음에 pathname으로 처리!
  async componentDidMount() {
    let user;
    let portfolioData;
    let profileData;
    let fetchImg;
    let fetchThumb;
    let myinnerWidth;
    try {
      myinnerWidth = window.innerWidth;
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(user.id);
      portfolioData = await this.fetchPortfolio(`pf-${user.id}`);
      if (myinnerWidth < 1440) {
        this.setState({
          restricted: true,
          errorMsg:
            "모바일 환경에서는 작업할 수 없습니다. \n (1440px 이상의 환경으로 설정해주세요)",
        });
      }
      if (
        profileData === null ||
        Object.values(profileData).filter((data) => data === "").length > 2
      ) {
        this.setState({ restricted: true, user });
      } else if (
        (profileData && !profileData.membership) ||
        profileData.membership === null
      ) {
        this.setState({
          restricted: true,
          errorMsg: "관리자 승인 후 이용 가능합니다.",
        });
      } else if (profileData && profileData.avatar_url) {
        fetchImg = await Storage.get(profileData.avatar_url, {
          contentType: "url",
        });

        if (portfolioData) {
          fetchThumb = await Storage.get(
            portfolioData.portfolio_thumbnail_url,
            {
              contentType: "url",
            }
          );
        }
        this.setState({
          isloading: true,
          formData: {
            ...this.state.formData,
            id: `pf-${user.id}`,
            user_phone_number: user.attributes.phone_number,
          },
          user,
          portfolioData,
          fetchImg,
          fetchThumb,
        });
      } else {
        if (portfolioData) {
          fetchThumb = await Storage.get(
            portfolioData.portfolio_thumbnail_url,
            {
              contentType: "url",
            }
          );
        }
        this.setState({
          isloading: true,
          formData: {
            ...this.state.formData,
            id: `pf-${user.id}`,
            user_phone_number: user.attributes.phone_number,
          },
          user,
          portfolioData,
          fetchThumb,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false, user });
    }
  }

  render() {
    let mobilebranch;
    const { isloading, formData, portfolioData, user, restricted } = this.state;
    const {
      location: { pathname },
      info,
    } = this.props;
    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    return (
      <>
        <Helmet>
          <title>MyPortFolio | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <IntroLayout id="mypf_layout">
          {isloading ? (
            <>
              {" "}
              <Loader />{" "}
            </>
          ) : restricted ? (
            <Error
              error={
                this.state.errorMsg !== ""
                  ? this.state.errorMsg
                  : TextData.Error_Required_Profile
              }
            />
          ) : (
            <>
              <ProfileContainer id="mypf_profileContainer">
                <IntroLayoutMenuOption
                  className="mypf_profileMenuOptions"
                  current={pathname === `/profile/${info}`}
                >
                  <SLink to={`/profile/${info}`}>
                    {TextData.link_to_profile}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="mypf_profileMenuOptions"
                  current={pathname === `/mypost/${info}`}
                >
                  <SLink to={`/mypost/${info}`}>
                    {TextData.link_to_mypost}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="mypf_profileMenuOptions"
                  current={pathname === `/questions/${info}`}
                >
                  <SLink to={`/questions/${info}`}>
                    {TextData.link_to_question}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="mypf_profileMenuOptions"
                  current={pathname === `/myportfolio/${info}`}
                >
                  <SLink to={`/myportfolio/${info}`}>
                    {TextData.link_to_myportfolio}
                  </SLink>
                </IntroLayoutMenuOption>
              </ProfileContainer>
              <IntroDetail>
                <MyInfoContainer id="mypf_infoContainer">
                  <MyInfoLeftBox id="mypf_infoLeftBox">
                    <MyPortFolioThumbnailBox
                      thumbnail_url={
                        this.state.portfoliothumbnailFileURL
                          ? this.state.portfoliothumbnailFileURL
                          : portfolioData && this.state.fetchThumb
                          ? this.state.fetchThumb
                          : ""
                      }
                    >
                      <ImageClickLabel>
                        <MyPortFolioThumbnailContent
                          isuploaded={
                            portfolioData && this.state.fetchThumb
                              ? false
                              : this.state.portfoliothumbnailFileURL
                              ? false
                              : true
                          }
                        >
                          {TextData.Need_Thumbnail}
                        </MyPortFolioThumbnailContent>
                        <ImageClickInput
                          type="file"
                          id="portfolio_thumnail"
                          name="portfolio_thumnail"
                          accept=".jpg, .jpeg, .png"
                          onChange={(e) => this.onChangeThumb(e)}
                        />
                      </ImageClickLabel>
                      <MyPortFolioThumbnailInfo>
                        {TextData.Size_Info}
                      </MyPortFolioThumbnailInfo>
                    </MyPortFolioThumbnailBox>
                    <MyPortFolioStateBox>
                      <MyPortFolioStateColumn>
                        {TextData.MyPortFolio_Column01}
                      </MyPortFolioStateColumn>
                      <MyPortFolioStateInfo>
                        {portfolioData === null || portfolioData === undefined
                          ? "미등록"
                          : portfolioData.pf_permission === false
                          ? "대기중"
                          : "등록완료"}
                      </MyPortFolioStateInfo>
                    </MyPortFolioStateBox>
                  </MyInfoLeftBox>
                  <MyPortFolioRightBox>
                    <MyPortFolioRightContent>
                      <MyPortFolioListContainer>
                        <MyPortFolioList>
                          <MyPortFolioListColumn className="mypf_listColumn">
                            {TextData.MyPortFolio_Column02}
                          </MyPortFolioListColumn>
                          <MyPortFolioListInput
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  company_name: e.target.value,
                                },
                              })
                            }
                            value={formData.company_name}
                            placeholder={
                              portfolioData && portfolioData.company_name
                                ? portfolioData.company_name
                                : "상호를 입력해주세요."
                            }
                          ></MyPortFolioListInput>
                          <MyPortFolioListisPublicBox>
                            <RadioContainer>
                              <RadioForm>
                                <RadioSection>
                                  <RadioInput
                                    type="radio"
                                    name="portfolio_isbusiness"
                                    id="id_pf1"
                                    value="value1"
                                  />
                                  <RadioLabel
                                    className="mypf_radiolabels"
                                    selected={
                                      formData.business_license ? true : null
                                    }
                                    onClick={(e) => {
                                      this.setState({
                                        formData: {
                                          ...formData,
                                          business_license: true,
                                        },
                                      });
                                    }}
                                  >
                                    {TextData.MyPortFolio_Business_01}
                                  </RadioLabel>
                                  <RadioInput
                                    type="radio"
                                    name="portfolio_isfree"
                                    id="id_pf2"
                                    value="value2"
                                  />
                                  <RadioLabel
                                    className="mypf_radiolabels"
                                    selected={
                                      !formData.business_license ? true : null
                                    }
                                    onClick={(e) => {
                                      this.setState({
                                        formData: {
                                          ...formData,
                                          business_license: false,
                                        },
                                      });
                                    }}
                                  >
                                    {TextData.MyPortFolio_Business_02}
                                  </RadioLabel>
                                </RadioSection>
                              </RadioForm>
                            </RadioContainer>
                          </MyPortFolioListisPublicBox>
                        </MyPortFolioList>
                        <MyPortFolioList>
                          <MyPortFolioListColumn className="mypf_listColumn">
                            {TextData.MyPortFolio_Column03}
                          </MyPortFolioListColumn>

                          <MyPortFolioSelect
                            name="tag_pf"
                            id="tag_pf_select"
                            onChange={(e) => this.onChangeTagSelector(e)}
                          >
                            <MyPortFolioSelectOption value="">
                              {TextData.MyPortFolio_Selector_Default}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt01}
                            >
                              {TextData.MyPortFolio_Selctor_Opt01}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt02}
                            >
                              {TextData.MyPortFolio_Selctor_Opt02}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt03}
                            >
                              {TextData.MyPortFolio_Selctor_Opt03}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt04}
                            >
                              {TextData.MyPortFolio_Selctor_Opt04}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt05}
                            >
                              {TextData.MyPortFolio_Selctor_Opt05}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              valye={TextData.MyPortFolio_Selctor_Opt06}
                            >
                              {TextData.MyPortFolio_Selctor_Opt06}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt07}
                            >
                              {TextData.MyPortFolio_Selctor_Opt07}
                            </MyPortFolioSelectOption>

                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt08}
                            >
                              {TextData.MyPortFolio_Selctor_Opt08}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt09}
                            >
                              {TextData.MyPortFolio_Selctor_Opt09}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt10}
                            >
                              {TextData.MyPortFolio_Selctor_Opt10}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt11}
                            >
                              {TextData.MyPortFolio_Selctor_Opt11}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt12}
                            >
                              {TextData.MyPortFolio_Selctor_Opt12}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt13}
                            >
                              {TextData.MyPortFolio_Selctor_Opt13}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt14}
                            >
                              {TextData.MyPortFolio_Selctor_Opt14}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt15}
                            >
                              {TextData.MyPortFolio_Selctor_Opt15}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt16}
                            >
                              {TextData.MyPortFolio_Selctor_Opt16}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt17}
                            >
                              {TextData.MyPortFolio_Selctor_Opt17}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt18}
                            >
                              {TextData.MyPortFolio_Selctor_Opt18}
                            </MyPortFolioSelectOption>
                            <MyPortFolioSelectOption
                              value={TextData.MyPortFolio_Selctor_Opt19}
                            >
                              {TextData.MyPortFolio_Selctor_Opt19}
                            </MyPortFolioSelectOption>
                          </MyPortFolioSelect>

                          <MyPortFolioListisPublicBox>
                            <MyPortFolioListTagContent
                              className="mypf_tagcontents"
                              istagged={
                                formData.portfolio_tag &&
                                formData.portfolio_tag.tag_content01
                                  ? true
                                  : false
                              }
                            >
                              {formData.portfolio_tag &&
                              formData.portfolio_tag.tag_content01
                                ? formData.portfolio_tag.tag_content01
                                : ""}
                            </MyPortFolioListTagContent>
                            <MyPortFolioListTagContent
                              className="mypf_tagcontents"
                              istagged={
                                formData.portfolio_tag &&
                                formData.portfolio_tag.tag_content02
                                  ? true
                                  : false
                              }
                            >
                              {formData.portfolio_tag &&
                              formData.portfolio_tag.tag_content02
                                ? formData.portfolio_tag.tag_content02
                                : ""}
                            </MyPortFolioListTagContent>
                            <MyPortFolioListTagContent
                              className="mypf_tagcontents"
                              istagged={
                                formData.portfolio_tag &&
                                formData.portfolio_tag.tag_content03
                                  ? true
                                  : false
                              }
                            >
                              {formData.portfolio_tag &&
                              formData.portfolio_tag.tag_content03
                                ? formData.portfolio_tag.tag_content03
                                : ""}
                            </MyPortFolioListTagContent>
                          </MyPortFolioListisPublicBox>
                        </MyPortFolioList>
                        <MyPortFolioList>
                          <MyPortFolioListColumn className="mypf_listColumn">
                            {TextData.MyPortFolio_Column04}
                          </MyPortFolioListColumn>
                          <MyPortFolioListInput
                            placeholder={
                              portfolioData &&
                              portfolioData.portfolio_oneline_description
                                ? portfolioData.portfolio_oneline_description
                                : "10자 이내로 적어주세요."
                            }
                            maxLength="10"
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  portfolio_oneline_description: e.target.value,
                                },
                              })
                            }
                          ></MyPortFolioListInput>
                          <MyPortFolioListisPublicBox />
                        </MyPortFolioList>
                        <MyPortFolioList>
                          <MyPortFolioListColumn className="mypf_listColumn">
                            {TextData.MyPortFolio_Column05}
                          </MyPortFolioListColumn>
                          <MyPortFolioListInput
                            placeholder={
                              portfolioData && portfolioData.portfolio_website
                                ? portfolioData.portfolio_website
                                : "www.example.com"
                            }
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  portfolio_website: e.target.value,
                                },
                              })
                            }
                          ></MyPortFolioListInput>
                          <MyPortFolioListisPublicBox />
                        </MyPortFolioList>
                        <MyPortFolioList>
                          <MyPortFolioListColumn className="mypf_listColumn">
                            {TextData.MyPortFolio_Column06}
                          </MyPortFolioListColumn>
                          <MyPortFolioListInput
                            placeholder={
                              portfolioData && portfolioData.portfolio_email
                                ? portfolioData.portfolio_email
                                : "fff@example.com"
                            }
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  portfolio_email: e.target.value,
                                },
                              })
                            }
                          ></MyPortFolioListInput>
                          <MyPortFolioListisPublicBox />
                        </MyPortFolioList>
                        <MyPortFolioList>
                          <MyPortFolioListColumn className="mypf_listColumn">
                            {TextData.MyPortFolio_Column07}
                          </MyPortFolioListColumn>
                          <MyPortFolioListInput
                            placeholder={
                              formData.portfolio_attach01.portfolio_link01
                                ? formData.portfolio_attach01.portfolio_link01
                                : "vimeo 혹은 youtube 링크 입력"
                            }
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  portfolio_attach01: {
                                    ...formData.portfolio_attach01,
                                    portfolio_link01: e.target.value,
                                  },
                                },
                              })
                            }
                          />

                          <MyPortFolioListisPublicBox>
                            <MyPortFolioAttachLabel>
                              <MyPortFolioAttachContent className="mypf_attachContent">
                                {TextData.MyPortFolio_Attach}
                              </MyPortFolioAttachContent>
                              <MyPortFolioAttachInput
                                type="file"
                                id="portfolio_attach01"
                                name="pf_attach01"
                                accept=".jpg, .jpeg, .png"
                                onChange={(e) => this.onChangePort01(e)}
                              ></MyPortFolioAttachInput>
                              <MyPortFolioAttachSpan>
                                {this.state.portfolio_file01
                                  ? `${this.state.portfolio_file01.name.substr(
                                      0,
                                      11
                                    )}...`
                                  : formData.portfolio_attach01 &&
                                    formData.portfolio_attach01.portfolio_url01
                                  ? `${formData.portfolio_attach01.portfolio_url01
                                      .split("PortFolio01")[1]
                                      .substr(0, 11)}...`
                                  : ""}
                              </MyPortFolioAttachSpan>
                            </MyPortFolioAttachLabel>
                            <MyPortFolioListCancel
                              tabIndex="-1"
                              onClick={(e) =>
                                this.setState({
                                  formData: {
                                    ...formData,
                                    portfolio_attach01: {
                                      portfolio_link01: "",
                                      portfolio_url01: "",
                                    },
                                  },
                                })
                              }
                            >
                              {TextData.MyPortFolio_Attach_Delete}
                            </MyPortFolioListCancel>
                          </MyPortFolioListisPublicBox>
                        </MyPortFolioList>
                        <MyPortFolioList>
                          <MyPortFolioListColumn className="mypf_listColumn">
                            {TextData.MyPortFolio_Column08}
                          </MyPortFolioListColumn>
                          <MyPortFolioListInput
                            placeholder={
                              formData.portfolio_attach02.portfolio_link02
                                ? formData.portfolio_attach02.portfolio_link02
                                : "vimeo 혹은 youtube 링크 입력"
                            }
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  portfolio_attach02: {
                                    ...formData.portfolio_attach02,
                                    portfolio_link02: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          <MyPortFolioListisPublicBox>
                            <MyPortFolioAttachLabel>
                              <MyPortFolioAttachContent className="mypf_attachContent">
                                {TextData.MyPortFolio_Attach}
                              </MyPortFolioAttachContent>
                              <MyPortFolioAttachInput
                                type="file"
                                id="portfolio_attach02"
                                name="pf_attach02"
                                accept=".jpg, .jpeg, .png"
                                onChange={(e) => this.onChangePort02(e)}
                              ></MyPortFolioAttachInput>
                              <MyPortFolioAttachSpan>
                                {this.state.portfolio_file02
                                  ? `${this.state.portfolio_file02.name.substr(
                                      0,
                                      11
                                    )}...`
                                  : formData.portfolio_attach02 &&
                                    formData.portfolio_attach02.portfolio_url02
                                  ? `${formData.portfolio_attach02.portfolio_url02
                                      .split("PortFolio02")[1]
                                      .substr(0, 11)}...`
                                  : ""}
                              </MyPortFolioAttachSpan>
                            </MyPortFolioAttachLabel>
                            <MyPortFolioListCancel
                              tabIndex="-1"
                              onClick={(e) =>
                                this.setState({
                                  formData: {
                                    ...formData,
                                    portfolio_attach02: {
                                      portfolio_link02: "",
                                      portfolio_url02: "",
                                    },
                                  },
                                })
                              }
                            >
                              {TextData.MyPortFolio_Attach_Delete}
                            </MyPortFolioListCancel>
                          </MyPortFolioListisPublicBox>
                        </MyPortFolioList>
                        <MyPortFolioList>
                          <MyPortFolioListColumn className="mypf_listColumn">
                            {TextData.MyPortFolio_Column09}
                          </MyPortFolioListColumn>
                          <MyPortFolioListInput
                            placeholder={
                              formData.portfolio_attach03.portfolio_link03
                                ? formData.portfolio_attach03.portfolio_link03
                                : "vimeo 혹은 youtube 링크 입력"
                            }
                            onChange={(e) =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  portfolio_attach03: {
                                    ...formData.portfolio_attach03,
                                    portfolio_link03: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          <MyPortFolioListisPublicBox>
                            <MyPortFolioAttachLabel>
                              <MyPortFolioAttachContent className="mypf_attachContent">
                                {TextData.MyPortFolio_Attach}
                              </MyPortFolioAttachContent>
                              <MyPortFolioAttachInput
                                type="file"
                                id="portfolio_attach03"
                                name="pf_attach03"
                                accept=".jpg, .jpeg, .png"
                                onChange={(e) => this.onChangePort03(e)}
                              ></MyPortFolioAttachInput>
                              <MyPortFolioAttachSpan>
                                {this.state.portfolio_file03
                                  ? `${this.state.portfolio_file03.name.substr(
                                      0,
                                      11
                                    )}...`
                                  : formData.portfolio_attach03 &&
                                    formData.portfolio_attach03.portfolio_url03
                                  ? `${formData.portfolio_attach03.portfolio_url03
                                      .split("PortFolio03")[1]
                                      .substr(0, 11)}...`
                                  : ""}
                              </MyPortFolioAttachSpan>
                            </MyPortFolioAttachLabel>
                            <MyPortFolioListCancel
                              tabIndex="-1"
                              onClick={(e) =>
                                this.setState({
                                  formData: {
                                    ...formData,
                                    portfolio_attach03: {
                                      portfolio_link03: "",
                                      portfolio_url03: "",
                                    },
                                  },
                                })
                              }
                            >
                              {TextData.MyPortFolio_Attach_Delete}
                            </MyPortFolioListCancel>
                          </MyPortFolioListisPublicBox>
                        </MyPortFolioList>
                        <MyPortFolioList>
                          <MyPortFolioListColumn className="mypf_listColumn">
                            {TextData.MyPortFolio_Column10}
                          </MyPortFolioListColumn>
                          <MyPortFolioFullDescriptionTextArea
                            id="mypf_description"
                            placeholder={
                              portfolioData &&
                              portfolioData.portfolio_full_description
                                ? portfolioData.portfolio_full_description
                                : "500자 이내로 상세하게 설명해주세요."
                            }
                            maxLength="500"
                            onChange={(e) => {
                              this.limitText(e.target.value, 500);
                              this.setState({
                                formData: {
                                  ...formData,
                                  portfolio_full_description: e.target.value,
                                },
                              });
                            }}
                          />
                        </MyPortFolioList>
                        <MyPortFolioReadOnlyLimitText id="mypf_descr_count">
                          {`${this.state.isCharLimit} / 500`}
                        </MyPortFolioReadOnlyLimitText>
                      </MyPortFolioListContainer>
                    </MyPortFolioRightContent>
                    <MyInfoNavContainer id="mypf_navContainer">
                      <MyInfoNavSaveBtn
                        className="myprf_navbtn"
                        onClick={() => this.handleSave()}
                      >
                        {TextData.MyPortFolio_Btn01}
                      </MyInfoNavSaveBtn>
                      <MyInfoNavNotSaveBtn
                        className="myprf_navbtn"
                        onClick={this.handleCancel}
                      >
                        {TextData.MyPortFolio_Btn02}
                      </MyInfoNavNotSaveBtn>
                    </MyInfoNavContainer>
                  </MyPortFolioRightBox>
                </MyInfoContainer>
              </IntroDetail>
              <BoardLowerMenuContainer id="myinfo_togglemenu">
                <MyInfoNavLogoutContainer>
                  <MyInfoNavLogoutLeftBox>
                    <MyInfoNavLogoutLeftImg
                      avatar_url={
                        this.state.fetchImg ? this.state.fetchImg : ""
                      }
                    ></MyInfoNavLogoutLeftImg>
                    <MyInfoNavLogoutLeftContent>
                      {user ? user.attributes.name : "아무개"}님
                    </MyInfoNavLogoutLeftContent>
                  </MyInfoNavLogoutLeftBox>
                  <MyInfoNavLogoutRightBox>
                    {/* <MyInfoNavLogoutBtn onClick={onSignOutClick}> */}
                    {/* {TextData.MyPortFolio_LOGOUT} */}
                    {/* </MyInfoNavLogoutBtn> */}
                  </MyInfoNavLogoutRightBox>
                </MyInfoNavLogoutContainer>
              </BoardLowerMenuContainer>
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterMyPortFolio = withRouter(MyPortFolioPresenter);
export default withRouterMyPortFolio;
