import React from "react";
import Router from "./Router";
import GlobalFonts from "../fonts/fonts";
import MediaScreen from "./MediaScreen";

// import { device } from "../Devices/devices";
// import styled from "styled-components";

// 반응형 설계 설정중 ..
/*
const Page = styled.div`
  @media ${device.mobileS} {
    background-size: cover;
    background-color: red;
  }
  @media ${device.mobileM} {
    background-color: orange;
  }
  @media ${device.mobileL} {
    background-color: yellow;
  }
  @media ${device.tablet} {
    background-color: green;
  }
  @media ${device.laptop} {
    background-color: blue;
  }
  @media ${device.laptopL} {
    background-color: navy;
  }
  @media ${device.desktop} {
    background-color: purple;
  }
`;
*/
class App extends React.Component {
  render() {
    return (
      <>
        <GlobalFonts />
        {/* <MediaScreen /> */}
        <Router />
      </>
    );
  }
}

export default App;
