import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import {
  AdminIntroLayout as IntroLayout,
  SLink,
  AdminIntroLayoutMenuBox as IntroLayoutMenuBox,
  AdminIntroLayoutMenuOption as IntroLayoutMenuOption,
  Admin_Question_AdminDetailContainer as AdminDetailContainer,
  Admin_Question_NeedHeader as NeedHeader,
  Admin_Question_FinishedHeader as FinishedHeader,
  Admin_Question_NeedContainer as NeedContainer,
  Admin_Question_NeedBox as NeedBox,
  Admin_Question_FinishedContainer as FinishedContainer,
  Admin_Question_FinishedBox as FinishedBox,
  Admin_Question_ContentBox as ContentBox,
  Admin_Question_NrealNameInfo as NrealNameInfo,
  Admin_Question_NContactInfo as NContactInfo,
  Admin_PF_HActionBtn as HActionBtn,
  Admin_PF_UActionBtn as UActionBtn,
  Admin_Question_FrealNameInfo as FrealNameInfo,
  Admin_Question_FContactInfo as FContactInfo,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import Error from "../../Components/Error";
import TextData from "../../Json/AdminJson.json";
import { API } from "aws-amplify";
import { listUsers } from "../../graphql/queries";
import {
  getCurrentUsernameGetSub,
  getCurrentUserInfo,
  fetchOneUserById,
} from "../../Controllers/amplifyController";
import { updateUser as updateUserM } from "../../graphql/mutations";

class AdminUserPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      isAdmin: false,
    };
  }
  async ConfirmPermission(data) {
    try {
      await API.graphql({
        query: updateUserM,
        variables: {
          input: {
            id: data.id,
            _version: data._version,
            membership: true,
          },
        },
      });
      this.setState({ isloading: true });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
      window.location.reload();
    }
  }
  async DenyPermission(data) {
    try {
      await API.graphql({
        query: updateUserM,
        variables: {
          input: {
            id: data.id,
            _version: data._version,
            membership: false,
          },
        },
      });
      this.setState({ isloading: true });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
      window.location.reload();
    }
  }
  async confirmUpload(data) {
    await this.ConfirmPermission(data);
  }
  async denyUpload(data) {
    await this.DenyPermission(data);
  }
  // 요것도 여기서만 쓰이니깐!
  async fetchAllUser(bool) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listUsers,
        variables: { filter: { membership: { eq: bool } }, limit: 1000 },
      })
        .then((user) => {
          if (user.data.listUsers.items) {
            resolve(user.data.listUsers.items);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  async componentDidMount() {
    let user;
    let info;
    let profileData;
    let allowed_Member;
    let not_allowed_Member;

    try {
      info = await getCurrentUsernameGetSub();
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(user.id);
      allowed_Member = await this.fetchAllUser(true);
      allowed_Member.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      not_allowed_Member = await this.fetchAllUser(false);
      not_allowed_Member.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      if (profileData && profileData.isAdmin && info !== null) {
        this.setState({
          isloading: true,
          info,
          profileData,
          isAdmin: true,
          allowed_Member,
          not_allowed_Member,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
    }
  }

  render() {
    const {
      info,
      isloading,
      isAdmin,
      allowed_Member,
      not_allowed_Member,
    } = this.state;
    const {
      location: { pathname },
    } = this.props;
    return (
      <>
        <Helmet>
          <title>UserPool | FFF</title>
        </Helmet>
        <Header />
        <IntroLayout>
          {isloading ? (
            <Loader />
          ) : isAdmin ? (
            <>
              <IntroLayoutMenuBox>
                <IntroLayoutMenuOption current={pathname === `/admin/${info}`}>
                  <SLink to={`/admin/${info}`}>
                    {TextData.Admin_Header_01}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  current={pathname === `/admin_question/${info}`}
                >
                  <SLink to={`/admin_question/${info}`}>
                    {TextData.Admin_Header_02}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  current={pathname === `/admin_user/${info}`}
                >
                  <SLink to={`/admin_user/${info}`}>
                    {TextData.Admin_Header_03}
                  </SLink>
                </IntroLayoutMenuOption>
              </IntroLayoutMenuBox>
              <AdminDetailContainer>
                <NeedContainer>
                  <NeedHeader>
                    {TextData.Admin_UserPool_Not_Membership}
                  </NeedHeader>
                  <NeedBox>
                    {not_allowed_Member ? (
                      not_allowed_Member.map((data, index) => (
                        <ContentBox key={index}>
                          <NrealNameInfo>
                            <SLink to={`/userdetail/${data.id}`}>
                              {data.phone_number.split("+82")[1]}
                            </SLink>
                          </NrealNameInfo>
                          <NContactInfo>{data.username}</NContactInfo>
                          <HActionBtn onClick={() => this.confirmUpload(data)}>
                            {TextData.Admin_UserPool_Allowed_Membership}
                          </HActionBtn>
                        </ContentBox>
                      ))
                    ) : (
                      <></>
                    )}
                  </NeedBox>
                </NeedContainer>
                <FinishedContainer>
                  <FinishedHeader>
                    {TextData.Admin_UserPool_Membership}
                  </FinishedHeader>

                  <FinishedBox>
                    {allowed_Member ? (
                      allowed_Member.map((data, index) => (
                        <ContentBox key={index}>
                          <FrealNameInfo>
                            <SLink to={`/userdetail/${data.id}`}>
                              {data.phone_number.split("+82")[1]}
                            </SLink>
                          </FrealNameInfo>
                          <FContactInfo>{data.username}</FContactInfo>
                          <UActionBtn onClick={() => this.denyUpload(data)}>
                            {TextData.Admin_UserPool_NotAllowed_Membership}
                          </UActionBtn>
                        </ContentBox>
                      ))
                    ) : (
                      <></>
                    )}
                  </FinishedBox>
                </FinishedContainer>
              </AdminDetailContainer>
            </>
          ) : (
            <>
              <Error error={TextData.Access_Deny} />
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterAdminUser = withRouter(AdminUserPresenter);
export default withRouterAdminUser;
