import styled from "styled-components";
import { Form } from "antd";

/** App Theme */

const EmailConfirmFormWrapper = styled(Form)`
  display: flex;
  flex-flow: wrap column;
  /* width: 500px; */
  /* height: 600px; */
  background: transparent;
  justify-content: space-around;
  /* margin-top: 500px; */
  /* margin-left: 350px; */
  input {
    text-align: center;
  }
`;

export default EmailConfirmFormWrapper;
