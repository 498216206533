import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import {
  AdminIntroLayout as IntroLayout,
  SLink,
  AdminIntroLayoutMenuBox as IntroLayoutMenuBox,
  AdminIntroLayoutMenuOption as IntroLayoutMenuOption,
  Admin_PF_AdminDetailContainer as AdminDetailContainer,
  Admin_PF_HoldingHeader as HoldingHeader,
  Admin_PF_UploadedHeader as UploadedHeader,
  Admin_PF_HoldingContainer as HoldingContainer,
  Admin_PF_HoldingBox as HoldingBox,
  Admin_PF_UploadedContainer as UploadedContainer,
  Admin_PF_UploadedBox as UploadedBox,
  Admin_PF_ContentBox as ContentBox,
  Admin_PF_HCompanyInfo as HCompanyInfo,
  Admin_PF_HContactInfo as HContactInfo,
  Admin_PF_HActionBtn as HActionBtn,
  Admin_PF_UCompanyInfo as UCompanyInfo,
  Admin_PF_UContactInfo as UContactInfo,
  Admin_PF_UActionBtn as UActionBtn,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import Error from "../../Components/Error";
import TextData from "../../Json/AdminJson.json";
import { API } from "aws-amplify";
import { listPortfolios } from "../../graphql/queries";
import { updatePortfolio as updatedPortfolioM } from "../../graphql/mutations";
import {
  getCurrentUserInfo,
  fetchOneUserById,
  getCurrentUsernameGetSub,
} from "../../Controllers/amplifyController";

class AdminPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      isAdmin: false,
    };
  }

  async fetchPortfolio(data) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listPortfolios,
        variables: { filter: { pf_permission: { eq: data } }, limit: 1000 },
      })
        .then((portfolio) => {
          if (portfolio) {
            resolve(portfolio.data.listPortfolios.items);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  async ConfirmPermission(data) {
    try {
      await API.graphql({
        query: updatedPortfolioM,
        variables: {
          input: {
            id: data.id,
            _version: data._version,
            pf_permission: true,
          },
        },
      });
      this.setState({ isloading: true });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
      window.location.reload();
    }
  }

  async DenyPermission(data) {
    try {
      await API.graphql({
        query: updatedPortfolioM,
        variables: {
          input: {
            id: data.id,
            _version: data._version,
            pf_permission: false,
          },
        },
      });
      this.setState({ isloading: true });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
      window.location.reload();
    }
  }

  async confirmUpload(data) {
    await this.ConfirmPermission(data);
  }
  async denyUpload(data) {
    await this.DenyPermission(data);
  }

  async componentDidMount() {
    let info;
    let user;
    let profileData;
    let notallowedPortFolio;
    let allowedPortfolio;
    try {
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(user.id);
      notallowedPortFolio = await this.fetchPortfolio(false);
      notallowedPortFolio.sort(
        (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
      );
      allowedPortfolio = await this.fetchPortfolio(true);
      allowedPortfolio.sort(
        (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
      );
      info = await getCurrentUsernameGetSub();
      if (profileData && profileData.isAdmin && info !== null) {
        this.setState({
          isAdmin: true,
          profileData,
          notallowedPortFolio,
          allowedPortfolio,
          info,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
    }
  }

  render() {
    const {
      isAdmin,
      isloading,
      notallowedPortFolio,
      allowedPortfolio,
      info,
    } = this.state;
    const {
      location: { pathname },
    } = this.props;
    return (
      <>
        <Helmet>
          <title>Admin | FFF</title>
        </Helmet>
        <Header />
        <IntroLayout>
          {isloading ? (
            <Loader />
          ) : isAdmin ? (
            <>
              <IntroLayoutMenuBox>
                <IntroLayoutMenuOption current={pathname === `/admin/${info}`}>
                  <SLink to={`/admin/${info}`}>
                    {TextData.Admin_Header_01}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  current={pathname === `/admin_question/${info}`}
                >
                  <SLink to={`/admin_question/${info}`}>
                    {TextData.Admin_Header_02}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  current={pathname === `/admin_user/${info}`}
                >
                  <SLink to={`/admin_user/${info}`}>
                    {TextData.Admin_Header_03}
                  </SLink>
                </IntroLayoutMenuOption>
              </IntroLayoutMenuBox>
              <AdminDetailContainer>
                <HoldingContainer>
                  <HoldingHeader>{TextData.Admin_01_Holding}</HoldingHeader>
                  <HoldingBox>
                    {notallowedPortFolio ? (
                      notallowedPortFolio.map((data, index) => (
                        <ContentBox key={index}>
                          <HCompanyInfo>
                            <SLink to={`/portfoliodetail/${data.id}`}>
                              {data.company_name}{" "}
                            </SLink>
                          </HCompanyInfo>
                          <HContactInfo>
                            {data.user_phone_number
                              ? data.user_phone_number.split("+82")[1]
                              : "연락처 조회 불가"}
                          </HContactInfo>
                          <HActionBtn onClick={() => this.confirmUpload(data)}>
                            {TextData.Admin_01_Confirm}
                          </HActionBtn>
                        </ContentBox>
                      ))
                    ) : (
                      <></>
                    )}
                  </HoldingBox>
                </HoldingContainer>
                <UploadedContainer>
                  <UploadedHeader>{TextData.Admin_02_Uploaded}</UploadedHeader>
                  <UploadedBox>
                    {allowedPortfolio ? (
                      allowedPortfolio.map((data, index) => (
                        <ContentBox key={index}>
                          <UCompanyInfo>
                            <SLink to={`/portfoliodetail/${data.id}`}>
                              {data.company_name}
                            </SLink>
                          </UCompanyInfo>
                          <UContactInfo>
                            {data.user_phone_number
                              ? data.user_phone_number.split("+82")[1]
                              : "연락처 조회 불가"}
                          </UContactInfo>
                          <UActionBtn onClick={() => this.denyUpload(data)}>
                            {TextData.Admin_02_Deny}
                          </UActionBtn>
                        </ContentBox>
                      ))
                    ) : (
                      <></>
                    )}
                  </UploadedBox>
                </UploadedContainer>
              </AdminDetailContainer>
            </>
          ) : (
            <Error error={TextData.Access_Deny} />
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterAdmin = withRouter(AdminPresenter);
export default withRouterAdmin;
