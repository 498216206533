import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import MobileHeader from "../../Components/MobileHeader";
import {
  IntroVerticalLayout,
  PF_Detail_Container as DetailContainer,
  PF_Detail_Header_Box as DetailHeaderBox,
  PF_Detail_Header_Left as DetailHeaderLeft,
  PF_Detail_Header_Right as DetailHeaderRight,
  PF_Detail_Header_Right_Tag_Box as DetailHeaderRightTagBox,
  PF_Detail_Header_Right_Tags as DetailHeaderRightTags,
  PF_Detail_Header_Right_CompanyName as DetailHeaderRightCompanyName,
  PF_Detail_Header_Right_OneLineDes as DetailHeaderRightOneline,
  PF_Detail_Header_Right_Web as DetailHeaderRightWeb,
  PF_Detail_Web_Span as DetailWebSpan,
  PF_Detail_Header_Right_Email as DetailHeaderRightEmail,
  PF_Detail_Header_Right_Business as DetailHeaderRightBusiness,
  PF_Detail_Contents_Box as DetailContentsBox,
  PF_Detail_Contents_Box_Header as DetailContentsBoxHeader,
  PF_Detail_Contents_Box_Content as DetailContentsBoxContent,
  PF_Detail_Contents_Box_FullDes as DetailContentsBoxFullDes,
  PF_Detail_Nav_Box as DetailNavBox,
  PF_Detail_Nav_Btn as DetailNavBtn,
  PortFolioDetailHeader,
  PF_Detail_Header_Right_Tags_Span as DetailHeaderRightSpan,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import { Storage } from "aws-amplify";
import TextData from "../../Json/pfDetailJson.json";
import { fetchOnePortFolioById } from "../../Controllers/amplifyController";
import "./responsive.css";
import Iframe from "react-iframe";
class PortfolioDetailPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      error: null,
      imagearr: [],
      videoarr: [],
    };
  }

  async componentDidMount() {
    let portfolioData;
    let fetchImg;
    let fetchPort01;
    let fetchPort02;
    let fetchPort03;
    let tagArr;
    let videoUrl01;
    let videoUrl02;
    let videoUrl03;

    const {
      location: { pathname },
    } = this.props;
    const portfolio_id = await pathname.split("/portfoliodetail/")[1];
    try {
      portfolioData = await fetchOnePortFolioById(portfolio_id);
      if (portfolioData) {
        fetchImg = await Storage.get(portfolioData.portfolio_thumbnail_url, {
          contentType: "url",
        });
        if (
          portfolioData.portfolio_attach01 &&
          portfolioData.portfolio_attach01.portfolio_url01
        ) {
          fetchPort01 = await Storage.get(
            portfolioData.portfolio_attach01.portfolio_url01,
            {
              contentType: "url",
            }
          );
          this.setState({ imagearr: [...this.state.imagearr, fetchPort01] });
        }
        if (
          portfolioData.portfolio_attach02 &&
          portfolioData.portfolio_attach02.portfolio_url02
        ) {
          fetchPort02 = await Storage.get(
            portfolioData.portfolio_attach02.portfolio_url02,
            {
              contentType: "url",
            }
          );
          this.setState({ imagearr: [...this.state.imagearr, fetchPort02] });
        }

        if (
          portfolioData.portfolio_attach03 &&
          portfolioData.portfolio_attach03.portfolio_url03
        ) {
          fetchPort03 = await Storage.get(
            portfolioData.portfolio_attach03.portfolio_url03,
            {
              contentType: "url",
            }
          );
          this.setState({ imagearr: [...this.state.imagearr, fetchPort03] });
        }
        if (portfolioData.portfolio_tag) {
          tagArr = Object.values(portfolioData.portfolio_tag);
        }
        if (
          portfolioData.portfolio_attach01 &&
          portfolioData.portfolio_attach01.portfolio_link01 !== ""
        ) {
          if (
            portfolioData.portfolio_attach01.portfolio_link01.includes(
              "youtube"
            )
          ) {
            videoUrl01 = `https://www.youtube.com/embed/${
              portfolioData.portfolio_attach01.portfolio_link01.split("v=")[1]
            }`;
          } else if (
            portfolioData.portfolio_attach01.portfolio_link01.includes(
              "youtu.be"
            )
          ) {
            videoUrl01 = `https://www.youtube.com/embed/${
              portfolioData.portfolio_attach01.portfolio_link01.split(".be/")[1]
            }`;
          } else {
            videoUrl01 = `https://player.vimeo.com/video/${
              portfolioData.portfolio_attach01.portfolio_link01.split("com/")[1]
            }`;
          }

          this.setState({
            videoarr: [...this.state.videoarr, videoUrl01],
          });
        }
        if (
          portfolioData.portfolio_attach02 &&
          portfolioData.portfolio_attach02.portfolio_link02 !== ""
        ) {
          if (
            portfolioData.portfolio_attach02.portfolio_link02.includes(
              "youtube"
            )
          ) {
            videoUrl02 = `https://www.youtube.com/embed/${
              portfolioData.portfolio_attach02.portfolio_link02.split("v=")[1]
            }`;
          } else if (
            portfolioData.portfolio_attach02.portfolio_link02.includes(
              "youtu.be"
            )
          ) {
            videoUrl02 = `https://www.youtube.com/embed/${
              portfolioData.portfolio_attach02.portfolio_link02.split(".be/")[1]
            }`;
          } else {
            videoUrl02 = `https://player.vimeo.com/video/${
              portfolioData.portfolio_attach02.portfolio_link02.split("com/")[1]
            }`;
          }

          this.setState({
            videoarr: [...this.state.videoarr, videoUrl02],
          });
        }
        if (
          portfolioData.portfolio_attach03 &&
          portfolioData.portfolio_attach03.portfolio_link03 !== ""
        ) {
          if (
            portfolioData.portfolio_attach03.portfolio_link03.includes(
              "youtube"
            )
          ) {
            videoUrl03 = `https://www.youtube.com/embed/${
              portfolioData.portfolio_attach03.portfolio_link03.split("v=")[1]
            }`;
          } else if (
            portfolioData.portfolio_attach03.portfolio_link03.includes(
              "youtu.be"
            )
          ) {
            videoUrl03 = `https://www.youtube.com/embed/${
              portfolioData.portfolio_attach03.portfolio_link03.split(".be/")[1]
            }`;
          } else {
            videoUrl03 = `https://player.vimeo.com/video/${
              portfolioData.portfolio_attach03.portfolio_link03.split("com/")[1]
            }`;
          }

          this.setState({
            videoarr: [...this.state.videoarr, videoUrl03],
          });
        }
      }
      this.setState({
        isloading: true,
        portfolioData,
        fetchImg,
        fetchPort01,
        fetchPort02,
        fetchPort03,
        tagArr,
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
    }
  }
  handleBackPage() {
    window.history.back();
  }

  render() {
    let newDescriptionArr;
    let mobilebranch;
    let mymobileNewbranch;
    const {
      portfolioData,
      fetchImg,
      isloading,
      tagArr,
      imagearr,
      videoarr,
    } = this.state;
    if (portfolioData && portfolioData.portfolio_full_description !== "") {
      newDescriptionArr = portfolioData.portfolio_full_description.split("\n");
    }
    function handleScroll(e) {
      var handleHeight = document.getElementById("header_scroll");
      let countvar =
        (handleHeight.scrollHeight - handleHeight.clientHeight) / 360;
      let headerImg = document.getElementById("FFF_LOGO");
      headerImg.style.transform =
        "rotate(" + handleHeight.scrollTop / countvar + "deg)";
    }
    mobilebranch = window.innerWidth;
    if (mobilebranch >= 1280) {
      mobilebranch = false;
    } else if (mobilebranch < 1280) {
      mobilebranch = true;
    }
    if (window.innerWidth <= 425) {
      mymobileNewbranch = true;
    } else {
      mymobileNewbranch = false;
    }
    return (
      <>
        <Helmet>
          <title>PortfolioDetail | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <IntroVerticalLayout
          id="header_scroll"
          onScroll={(e) => handleScroll(e)}
        >
          {isloading ? (
            <Loader />
          ) : (
            <>
              <DetailContainer>
                <PortFolioDetailHeader id="pf_detail_header">
                  PORTFOLIO
                </PortFolioDetailHeader>
                <DetailHeaderBox className="portfolio_detail_headerbox">
                  <DetailHeaderLeft
                    className="portfolio_header_left_img"
                    thumbnail_url={fetchImg ? fetchImg : ""}
                  ></DetailHeaderLeft>
                  <DetailHeaderRight className="portfolio_hedaer_right">
                    <DetailHeaderRightTagBox className="TagBox">
                      {tagArr ? (
                        tagArr.map((data, index) => (
                          <DetailHeaderRightTags
                            key={index}
                            className="TagContents"
                            current={data !== "" ? data : false}
                          >
                            <DetailHeaderRightSpan
                              className="TagSpan"
                              current={data !== "" ? data : false}
                            >
                              {data}
                            </DetailHeaderRightSpan>
                          </DetailHeaderRightTags>
                        ))
                      ) : (
                        <></>
                      )}
                    </DetailHeaderRightTagBox>
                    <DetailHeaderRightCompanyName className="CompanyName">
                      {portfolioData && portfolioData.company_name
                        ? portfolioData.company_name
                        : "조회 불가"}
                    </DetailHeaderRightCompanyName>
                    <DetailHeaderRightOneline className="Oneline">
                      {portfolioData &&
                      portfolioData.portfolio_oneline_description
                        ? portfolioData.portfolio_oneline_description
                        : "조회 불가"}
                    </DetailHeaderRightOneline>
                    <DetailHeaderRightWeb className="WebLink">
                      {portfolioData && portfolioData.portfolio_website ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            portfolioData.portfolio_website.includes(
                              "https://"
                            ) ||
                            portfolioData.portfolio_website.includes("http://")
                              ? portfolioData.portfolio_website
                              : `https://${portfolioData.portfolio_website}`
                          }
                        >
                          <DetailWebSpan>
                            {portfolioData.portfolio_website}
                          </DetailWebSpan>
                        </a>
                      ) : (
                        "조회 불가"
                      )}
                    </DetailHeaderRightWeb>
                    <DetailHeaderRightEmail className="Email">
                      {portfolioData && portfolioData.portfolio_email
                        ? portfolioData.portfolio_email
                        : "조회 불가"}
                    </DetailHeaderRightEmail>
                    <DetailHeaderRightBusiness className="Business">
                      {portfolioData && portfolioData.business_license === true
                        ? "사업자"
                        : portfolioData.business_license === false
                        ? "프리랜서"
                        : "조회 불가"}
                    </DetailHeaderRightBusiness>
                  </DetailHeaderRight>
                </DetailHeaderBox>
                <DetailContentsBox>
                  <DetailContentsBoxHeader className="ContentHeader">
                    <DetailHeaderRightSpan
                      className="TagSpan_PF_Header"
                      current={true}
                    >
                      포트폴리오
                    </DetailHeaderRightSpan>
                  </DetailContentsBoxHeader>
                  {imagearr.length !== 0 ? (
                    imagearr.map((data, index) => (
                      <DetailContentsBoxContent
                        key={index}
                        current={data}
                      ></DetailContentsBoxContent>
                    ))
                  ) : (
                    <></>
                  )}
                  {videoarr.length !== 0 ? (
                    videoarr.map((data, index) => (
                      <Iframe
                        id={index}
                        key={index}
                        url={data}
                        className="myframe"
                        allow="fullscreen"
                      ></Iframe>
                    ))
                  ) : (
                    <></>
                  )}
                  <DetailContentsBoxFullDes className="ContentFullDes">
                    {portfolioData && portfolioData.portfolio_full_description
                      ? // ? portfolioData.portfolio_full_description
                        newDescriptionArr.map((data, index) => (
                          <div key={index}>
                            <span>{data}</span>
                          </div>
                        ))
                      : ""}
                  </DetailContentsBoxFullDes>
                </DetailContentsBox>
                <DetailNavBox className="pf_detail_new_btn_box">
                  <DetailNavBtn
                    id="pf_detail_nav_btn"
                    onClick={(e) => this.handleBackPage(e)}
                  >
                    돌아가기
                  </DetailNavBtn>
                </DetailNavBox>
              </DetailContainer>
            </>
          )}
        </IntroVerticalLayout>
      </>
    );
  }
}

const withRouterJBoard = withRouter(PortfolioDetailPresenter);
export default withRouterJBoard;
