import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import {
  AdminIntroLayout as IntroLayout,
  AdminIntroLayoutMenuBox as IntroLayoutMenuBox,
  AdminIntroLayoutMenuOption as IntroLayoutMenuOption,
  SLink,
  Admin_Question_AdminDetailContainer as AdminDetailContainer,
  Admin_Question_NeedHeader as NeedHeader,
  Admin_Question_FinishedHeader as FinishedHeader,
  Admin_Question_NeedContainer as NeedContainer,
  Admin_Question_NeedBox as NeedBox,
  Admin_Question_FinishedContainer as FinishedContainer,
  Admin_Question_FinishedBox as FinishedBox,
  Admin_Question_ContentBox as ContentBox,
  Admin_Question_NrealNameInfo as NrealNameInfo,
  Admin_Question_NContactInfo as NContactInfo,
  Admin_Question_NActionBtn as NActionBtn,
  Admin_Question_FrealNameInfo as FrealNameInfo,
  Admin_Question_FContactInfo as FContactInfo,
  Admin_Question_FActionBtn as FActionBtn,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import Error from "../../Components/Error";
import TextData from "../../Json/AdminJson.json";
import { API } from "aws-amplify";
import { listUsers, listQuestions } from "../../graphql/queries";
import {
  getCurrentUsernameGetSub,
  getCurrentUserInfo,
  fetchOneUserById,
} from "../../Controllers/amplifyController";

class AdminQuestionsPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      isAdmin: false,
    };
  }

  async fetchUser(id) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listUsers,
        variables: { filter: { id: { eq: id } } },
      })
        .then((user) => {
          if (user.data.listUsers.items[0]) {
            resolve(user.data.listUsers.items[0]);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  //  요것두 여기서만!
  async fetchQuestions(bool) {
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listQuestions,
        variables: { filter: { holding: { eq: bool } }, limit: 1000 },
      })
        .then((holding) => {
          if (holding) {
            resolve(holding.data.listQuestions.items);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  async componentDidMount() {
    let user;
    let info;
    let profileData;
    let holding_QuestionData;
    let finished_QuestionData;
    try {
      info = await getCurrentUsernameGetSub();
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(user.id);
      holding_QuestionData = await this.fetchQuestions(true);
      holding_QuestionData.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      finished_QuestionData = await this.fetchQuestions(false);
      finished_QuestionData.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      if (profileData && profileData.isAdmin && info !== null) {
        this.setState({
          isloading: true,
          info,
          profileData,
          isAdmin: true,
          holding_QuestionData,
          finished_QuestionData,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
    }
  }

  render() {
    const {
      info,
      isloading,
      isAdmin,
      holding_QuestionData,
      finished_QuestionData,
    } = this.state;
    const {
      location: { pathname },
    } = this.props;
    return (
      <>
        <Helmet>
          <title>Admin | FFF</title>
        </Helmet>
        <Header />
        <IntroLayout>
          {isloading ? (
            <Loader />
          ) : isAdmin ? (
            <>
              <IntroLayoutMenuBox>
                <IntroLayoutMenuOption current={pathname === `/admin/${info}`}>
                  <SLink to={`/admin/${info}`}>
                    {TextData.Admin_Header_01}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  current={pathname === `/admin_question/${info}`}
                >
                  <SLink to={`/admin_question/${info}`}>
                    {TextData.Admin_Header_02}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  current={pathname === `/admin_user/${info}`}
                >
                  <SLink to={`/admin_user/${info}`}>
                    {TextData.Admin_Header_03}
                  </SLink>
                </IntroLayoutMenuOption>
              </IntroLayoutMenuBox>
              <AdminDetailContainer>
                <NeedContainer>
                  <NeedHeader>{TextData.Admin_Question_Need_Answer}</NeedHeader>
                  <NeedBox>
                    {holding_QuestionData ? (
                      holding_QuestionData.map((data, index) => (
                        <ContentBox key={index}>
                          <NrealNameInfo>{data.real_name}</NrealNameInfo>
                          <NContactInfo>
                            {data.phone_number.split("+82")[1]}
                          </NContactInfo>
                          <NActionBtn to={`/admin_question_details/${data.id}`}>
                            {TextData.Admin_Question_Detail}
                          </NActionBtn>
                        </ContentBox>
                      ))
                    ) : (
                      <></>
                    )}
                  </NeedBox>
                </NeedContainer>
                <FinishedContainer>
                  <FinishedHeader>
                    {TextData.Admin_Question_Success_Answer}
                  </FinishedHeader>

                  <FinishedBox>
                    {finished_QuestionData ? (
                      finished_QuestionData.map((data, index) => (
                        <ContentBox key={index}>
                          <FrealNameInfo>{data.real_name}</FrealNameInfo>
                          <FContactInfo>
                            {data.phone_number.split("+82")[1]}
                          </FContactInfo>
                          <FActionBtn to={`/admin_question_details/${data.id}`}>
                            {TextData.Admin_Question_Detail}
                          </FActionBtn>
                        </ContentBox>
                      ))
                    ) : (
                      <></>
                    )}
                  </FinishedBox>
                </FinishedContainer>
              </AdminDetailContainer>
            </>
          ) : (
            <>
              <Error error={TextData.Access_Deny} />
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterAdminQuestions = withRouter(AdminQuestionsPresenter);
export default withRouterAdminQuestions;
