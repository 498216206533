import React from "react";
import Helmet from "react-helmet";
import { withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import { UpArrow } from "@styled-icons/boxicons-solid/UpArrow";
import { API } from "aws-amplify";

import Header from "../../Components/Header";
import MobileHeader from "../../Components/MobileHeader";
import { listPosts } from "../../graphql/queries";
import TextData from "../../Json/IntroJson.json";
import {
  IntroLayout,
  IntroLayoutMenuBox,
  IntroLayoutMenuOption,
  SLink,
  SButton,
} from "../../Components/Layout";

import fff_about from "../../assets/01_fff/fff_about.png";
import fff_about_info from "../../assets/01_fff/fff_about_info.png";
import fff_about_jobs from "../../assets/01_fff/fff_about_jobs.png";
import fff_about_study from "../../assets/01_fff/fff_about_study.png";
import fff_join from "../../assets/01_fff/fff_join_mouseOff.png";
import fff_join_mouseOn from "../../assets/01_fff/fff_join_mouseOn.gif";
import fff_logos from "../../assets/01_fff/fff_logos.png";
import Loader from "../../Components/Loader";
import { getCurrentUsernameGetSub } from "../../Controllers/amplifyController";
import "./responsive.css";
import fff_news from "../../assets/font_logo/news.png";
// intro페이지는 혼자 호출되는 png파일 많으니깐 따로 레이아웃에 옮기지 말자
const IntroNavBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 400px; */
  /* height: 70px; */
  /* bottom: 20px; */
  /* left: -130px; */
  background-color: #1a1a1a;
  border: solid 5px #00ee13;
`;

const IntroNavFirstImg = styled.div`
  width: 80px;
  height: 40px;
  background-image: url(${fff_news});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 30px;
`;

const IntroNavContent = styled.div`
  font-family: "Noto Sans KR";
  font-weight: 500;
  /* font-size: 15px; */

  &:first-child {
    font-family: "Alfredo";
    /* font-size: 15px; */
    margin-left: 25px;
    margin-top: 10px;
  }
`;

const IntroNavIcon = styled(UpArrow)`
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-top: 5px;
  color: #00ee13;
`;

const IntroDetail = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: scroll;
`;
const FFFInfo = styled.div`
  /* width: 1080px; */
  /* height: 2700px; */
  white-space: pre-line;
  vertical-align: bottom;
`;
const InfoTopImg = styled.div`
  width: 100%;
  height: 42%;
  background-image: url(${fff_about});
  background-repeat: no-repeat;
  /* background-size: 1200px auto; */
`;
const InfoHighLight = styled.div`
  font-family: "Noto Sans KR";
  /* font-size: 29px; */
  font-weight: 600;
  /* margin-left: 70px; */
  /* margin-bottom: 50px; */
  /* margin-top: 80px; */
  /* line-height: 2.1rem; */
  color: #00ee13;
`;
const InfoTextBox = styled.div`
  /* margin-left: 70px;
  margin-top: 30px;
  margin-bottom: 100px; */
`;
const InfoText = styled.div`
  font-family: "Noto Sans KR";
  font-weight: 300;
  /* font-size: 16px; */
  /* &:not(:first-child) {
    margin-top: 25px;
  }
  &:first-child {
    margin-top: 30px;
  } */
  color: #00ee13;
  /* line-height: 2rem; */
`;
const InfoMidCon = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  /* height: 13%; */
  /* margin-left: 70px; */
  /* margin-top: 100px; */
`;
const InfoMidBox = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* width: 30%; */
  /* height: 100%; */
`;
const InfoMidImg01 = styled.div`
  /* width: 190px;
  height: 190px;
  margin-right: 70px; */
  background-image: url(${fff_about_jobs});
  background-repeat: no-repeat;
  background-size: contain;
`;

const InfoMidImg02 = styled.div`
  /* width: 190px;
  height: 190px;
  margin-right: 100px; */
  background-image: url(${fff_about_info});
  background-repeat: no-repeat;
  background-size: contain;
`;

const InfoMidImg03 = styled.div`
  /* width: 190px;
  height: 190px;
  margin-right: 120px; */
  background-image: url(${fff_about_study});
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position: center; */
`;

const InfoMidText = styled.div`
font-weight:300;
  /* width: 350px;
  font-family: "Noto Sans KR";
  font-size: 14px;
  margin-top: 30px;
  color: #00ee13;
  line-height: 1.5rem; */
`;
const InfoMidTextL = styled.div`
font-weight:300;
  /* width: 350px;
  font-family: "Noto Sans KR";
  font-size: 14px;
  margin-top: 30px;
  margin-left: 45px;
  line-height: 1.5rem;
  color: #00ee13; */
`;
const InfoNavContainer = styled.div`
  display: flex;
  /* margin-top: 70px; */
  /* margin-left: 70px; */
`;

const InfoNavMoreDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-top: 70px; */
  /* margin-left: 70px; */
  width: 90%;
  /* height: 20%; */
`;

const InfoNavDetailLinkBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 300;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  /* font-weight: 400; */
  /* &:not(:last-child) {
    margin-bottom: 30px;
  } */
`;

const InfoNavDetailLogoHeader = styled.div`
  font-family: "Noto Sans KR";
  font-weight: 300;
  /* font-size: 13px; */
  /* font-weight: 400; */
  /* margin: 30px 0px; */
`;

const InfoNavDetailLogo = styled.div`
  /* width: 200px; */
  /* height: 100px; */
  background-image: url(${fff_logos});
  background-size: cover;
  background-repeat: no-repeat;
`;

const InfoNavImg = styled.div`
  /* width: 250px; */
  /* height: 250px; */
  /* margin-right: 150px; */
  background-image: url(${fff_join});
  background-repeat: no-repeat;
  background-size: contain;
  &:hover {
    background-image: url(${fff_join_mouseOn});
    background-repeat: no-repeat;
    background-size: contain;
  }
`;
const InfoNavFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const InfoNavBox = styled.div`
  /* width: 600px; */
  /* height: 150px; */
`;
const InfoNavHighLight = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Noto Sans KR";
  /* font-size: 23px; */
  font-weight: 600;
  color: #00ee13;
`;
const InfoNavText = styled.div`
  font-family: "Noto Sans KR";
  font-weight:300;
  /* font-size: 16px; */
  /* margin-top: 15px; */
  color: #00ee13;
  /* line-height: 2rem; */
`;

let idx = 0;
class IntroPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
    };
  }

  // 요것도 여기서만 쓰이니깐!
  async fetchPost() {
    let filteredData;
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listPosts,
        variables: { filter: { category: { eq: "공지" } } },
      })
        .then((post) => {
          if (post.data.listPosts && post.data.listPosts.items) {
            filteredData = post.data.listPosts.items.filter(
              (data) => data._deleted !== true
            );
            resolve(filteredData);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }
  handleBtn(e) {
    var element = document.getElementById("fff_more_info");
    element.scrollIntoView({
      behavior: "smooth",
      // start & end로 위치 조정
      block: "end",
      inline: "nearest",
    });
  }
  handleScrollBtn(e) {
    var element = document.getElementById("fff_more_info");
    element.scrollIntoView({
      behavior: "smooth",
      // start & end로 위치 조정
      block: "start",
      inline: "nearest",
    });
  }
  handleScrollTop(e) {
    var element = document.getElementById("fff_top");
    element.scrollIntoView({
      behavior: "smooth",
      // start & end로 위치 조정
      block: "start",
      inline: "nearest",
    });
  }
  async componentDidMount() {
    let clientId;
    let posts = [];
    try {
      posts = await this.fetchPost();
      clientId = await getCurrentUsernameGetSub();
      if (posts) {
        posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false, clientId, posts });
    }
  }
  render() {
    let noticeUrl;
    let mobilebranch;
    const { isloading, posts } = this.state;
    const {
      location: { pathname },
      isauthorized,
    } = this.props;
    const getNextIndex = (idx, length) => {
      return (idx + 1) % length;
    };
    const getNewIndexAndRender = () => {
      const resultBox = document.getElementById("calc");
      idx = getNextIndex(idx, posts.length);
      noticeUrl = posts[idx].id;
      this.setState({ linkUrl: noticeUrl });
      resultBox.innerHTML = `[공지] ${posts[idx].title}`;
    };

    function handleScroll(e) {
      var handleHeight = document.getElementById("viewDetailHeight");
      let countvar =
        (handleHeight.scrollHeight - handleHeight.clientHeight) / 360;
      let headerImg = document.getElementById("FFF_LOGO");

      headerImg.style.transform =
        "rotate(" + handleHeight.scrollTop / countvar + "deg)";
    }
    mobilebranch = window.innerWidth;
    if (mobilebranch >= 1280) {
      mobilebranch = false;
    } else if (mobilebranch < 1280) {
      mobilebranch = true;
    }
    return (
      <>
        <Helmet>
          <title>Index | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <IntroLayout id="intro_layout">
          {isloading ? (
            <Loader />
          ) : (
            <>
              <IntroLayoutMenuBox id="intro_layout_menubox">
                <IntroLayoutMenuOption
                  className="intro_MenuOptions"
                  current={pathname === "/intro"}
                  onClick={(e) => this.handleScrollTop()}
                >
                  {TextData.IntroOption01}
                  {/* <SLink to="/intro">{TextData.IntroOption01}</SLink> */}
                </IntroLayoutMenuOption>

                {isauthorized === true ? (
                  <>
                    {" "}
                    <IntroLayoutMenuOption
                      className="intro_MenuOptions"
                      onClick={(e) => this.handleBtn()}
                      current={pathname === "/profile"}
                    >
                      함께하기
                      {/* <SLink to={`/profile/${this.state.clientId}`}>
                        {TextData.IntroAuthOption01}
                      </SLink> */}
                    </IntroLayoutMenuOption>
                  </>
                ) : (
                  <>
                    <IntroLayoutMenuOption
                      className="intro_MenuOptions"
                      onClick={(e) => this.handleBtn()}
                      current={pathname === "/join"}
                    >
                      함께하기
                      {/* <SLink to="/join"> {TextData.IntroOption02}</SLink> */}
                    </IntroLayoutMenuOption>
                  </>
                )}
                <IntroLayoutMenuOption
                  className="intro_MenuOptions"
                  onClick={(e) => this.handleScrollBtn()}
                  current={pathname === "/contact"}
                >
                  더보기
                  {/* <SLink to="/contact"> {TextData.IntroOption03}</SLink> */}
                </IntroLayoutMenuOption>
              </IntroLayoutMenuBox>
              <IntroDetail
                id="viewDetailHeight"
                onScroll={(e) => handleScroll(e)}
              >
                <FFFInfo className="info_container" id="fff_top">
                  <InfoTopImg id="info_top_img" />
                  <InfoHighLight className="info_highlight">
                    {TextData.Intro_HighLight_01}
                  </InfoHighLight>
                  <InfoTextBox id="info_text_box">
                    <InfoText id="info_text">
                      {TextData.Intro_Text_01_01}
                    </InfoText>
                    <InfoText id="info_text">
                      {TextData.Intro_Text_01_02}
                    </InfoText>
                    <InfoText id="info_text">
                      {TextData.Intro_Text_01_03}
                    </InfoText>
                  </InfoTextBox>
                  <InfoHighLight className="info_highlight info_desktop_highlight">
                    {TextData.Intro_HighLight_02}
                  </InfoHighLight>
                  <InfoMidCon id="info_mid_con">
                    <InfoMidBox id="info_mid_box">
                      <InfoMidImg01 id="info_mid_img"></InfoMidImg01>
                      <InfoMidText id="info_mid_text">
                        {TextData.Intro_Text_02_01}
                      </InfoMidText>
                    </InfoMidBox>
                    <InfoMidBox id="info_mid_box">
                      <InfoMidImg02 id="info_mid_img"></InfoMidImg02>
                      <InfoMidText id="info_mid_text">
                        {TextData.Intro_Text_02_02}
                      </InfoMidText>
                    </InfoMidBox>
                    <InfoMidBox id="info_mid_box">
                      <InfoMidImg03 id="info_mid_img"></InfoMidImg03>
                      <InfoMidTextL id="info_mid_text">
                        {TextData.Intro_Text_02_03}
                      </InfoMidTextL>
                    </InfoMidBox>
                  </InfoMidCon>
                  <InfoHighLight className="info_highlight info_desktop_highlight">
                    {TextData.Intro_HighLight_03}
                  </InfoHighLight>

                  <InfoNavContainer id="info_nav_container">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://forms.gle/EcHqxqCqKD8Q2Cxy6"
                    >
                      <InfoNavImg id="info_nav_img" />
                    </a>
                    <InfoNavFlex>
                      <InfoNavBox id="info_nav_box">
                        <InfoNavHighLight id="info_nav_highlight">
                          {TextData.Intro_NavHighLight_01}
                        </InfoNavHighLight>
                        <InfoNavText id="info_nav_text">
                          {TextData.Intro_Text_03_01}
                        </InfoNavText>
                      </InfoNavBox>
                      <InfoNavBox id="info_nav_box">
                        <InfoNavHighLight id="info_nav_highlight">
                          {TextData.Intro_NavHighLight_02}
                        </InfoNavHighLight>
                        <InfoNavText id="info_nav_text">
                          {TextData.Intro_Text_03_02}
                        </InfoNavText>
                      </InfoNavBox>
                    </InfoNavFlex>
                  </InfoNavContainer>
                  <InfoHighLight className="info_highlight" id="fff_more_info">
                    더 알아보기
                  </InfoHighLight>

                  <InfoNavMoreDetailContainer className="info_nav_more_container">
                    <InfoNavDetailLinkBox className="info_nav_more_content">
                      <span role="img" aria-label="fff_instagram" className="more_emoji">
                        🔗
                      </span>
                        <span className="more_txt">프프프 인스타그램</span>
                         <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://instagram.com/fff.prj"
                        >
                          @fff.prj
                        </a>
                    </InfoNavDetailLinkBox>
                    <InfoNavDetailLinkBox className="info_nav_more_content">
                      <span role="img" aria-label="fff_coworking" className="more_emoji">
                        📮
                        </span>
                        <span className="more_txt">협업 및 기타 문의</span>
                        <a href="mailto:fffprjfff@gmail.com">
                          fffprjfff@gmail.com
                        </a>
                    </InfoNavDetailLinkBox>
                    <InfoNavDetailLinkBox className="info_nav_more_content">
                      <span role="img" aria-label="fff_account" className="more_emoji">
                        💌
                      </span>
                      <span>후원계좌 카카오뱅크 7979-23-93714</span>
                    </InfoNavDetailLinkBox>
                    <InfoNavDetailLogoHeader id="info_nav_more_logos">
                      본 프로젝트는 여성가족부 2020 청년 성평등 문화 추진단
                      지원을 받아 진행되었습니다.
                    </InfoNavDetailLogoHeader>
                    <InfoNavDetailLogo id="info_nav_more_logos_img"></InfoNavDetailLogo>
                  </InfoNavMoreDetailContainer>
                </FFFInfo>
              </IntroDetail>
              {isauthorized ? (
                <IntroNavBox id="intro_nav_box">
                  <IntroNavFirstImg>
                    {/* {TextData.IntroSubNavContent01} */}
                  </IntroNavFirstImg>
                  <IntroNavContent id="result" className="navcontent_intro">
                    <SLink
                      to={
                        !this.state.linkUrl && !posts[0]
                          ? `/jboard/index=?1`
                          : this.state.linkUrl && posts[0]
                          ? `/postdetail/${this.state.linkUrl}`
                          : `/postdetail/${posts[0].id}`
                      }
                    >
                      <div id="calc">
                        {posts[0] && posts[0].title
                          ? `[공지] ${posts[0].title}`
                          : "새로운 공지사항이 없습니다"}
                      </div>
                    </SLink>
                  </IntroNavContent>
                  <SButton
                    onClick={(e) => {
                      if (posts[0] && posts[0].title) {
                        getNewIndexAndRender();
                      } else {
                        return;
                      }
                    }}
                  >
                    <IntroNavIcon />
                  </SButton>
                </IntroNavBox>
              ) : (
                <>
                  <IntroNavBox id="intro_nav_box">
                    <IntroNavFirstImg>
                      {/* {TextData.IntroSubNavContent01} */}
                    </IntroNavFirstImg>
                    <IntroNavContent className="navcontent_intro">
                      <SLink to={`/jboard/index=?1`}>
                        <div id="calc">
                          {posts[0] && posts[0].title
                            ? `[공지] ${posts[0].title}`
                            : "새로운 공지사항이 없습니다"}
                        </div>
                      </SLink>
                    </IntroNavContent>
                    <SButton
                      onClick={(e) => {
                        if (posts[0] && posts[0].title) {
                          getNewIndexAndRender();
                        } else {
                          return;
                        }
                      }}
                    >
                      <IntroNavIcon />
                    </SButton>
                  </IntroNavBox>
                </>
              )}
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterIntro = withRouter(IntroPresenter);
export default withRouterIntro;
