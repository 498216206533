import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import {
  IntroLayout,
  SLink,
  UserRightListBox,
  UserRightList,
  UserRightColumn,
  UserRightInput,
  UserGetHomePage,
  FlexBox,
  PortFolioInfo,
  PortFolioState,
  UserDetail,
  UserHeader,
  UserContainer,
  UserLeftBox,
  UserLeftImg,
  UserRightBox,
  UserUnderBackBtn,
  UserGetHomePageSpan,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import Error from "../../Components/Error";
import TextData from "../../Json/boardJson.json";
import UserDetailTextData from "../../Json/UserDetailJson.json";
import { Storage } from "aws-amplify";
import {
  getCurrentUserInfo,
  fetchOneUserById,
  fetchOnePortFolioById,
} from "../../Controllers/amplifyController";
import "./userdetailresponsive.css";

class UserDetailPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: true,
      isAdmin: false,
      restricted: false,
      errorMsg: "",
      error: false,
    };
  }

  async handleBackBtn() {
    window.history.back();
  }

  async componentDidMount() {
    let userData;
    let userId;
    let fetchImg;
    let activate_realname;
    let activate_belongto;
    let activate_field;
    let activate_homepage;
    let portfolioData;
    let fetchMe;
    let fetchMyProfile;
    const {
      location: { pathname },
    } = this.props;
    userId = pathname.split("/userdetail/")[1];
    try {
      userData = await fetchOneUserById(userId);
      portfolioData = await fetchOnePortFolioById(`pf-${userId}`);
      fetchMe = await getCurrentUserInfo();
      fetchMyProfile = await fetchOneUserById(fetchMe.id);
      if (
        (fetchMyProfile && !fetchMyProfile.membership) ||
        fetchMyProfile.membership === null
      ) {
        this.setState({
          error: true,
          errorMsg: "관리자 승인 후 이용 가능합니다.",
        });
      }
      if (fetchMe && fetchMyProfile.isAdmin) {
        this.setState({ isAdmin: true });
      }
      if (userData && userData.avatar_url) {
        fetchImg = await Storage.get(userData.avatar_url, {
          contentType: "url",
        });
      }
      if (userData) {
        activate_realname = userData.permission.auth_realname;
        activate_belongto = userData.permission.auth_belongto;
        activate_field = userData.permission.auth_field;
        activate_homepage = userData.permission.auth_homepage;
      }
      this.setState({
        isloading: true,
        userData,
        fetchImg,
        activate_realname,
        activate_belongto,
        activate_field,
        activate_homepage,
        portfolioData,
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false });
    }
  }
  render() {
    let mobilebranch;
    let mymobileNewbranch;
    const {
      userData,
      isloading,
      fetchImg,
      activate_realname,
      activate_belongto,
      activate_field,
      activate_homepage,
      portfolioData,
      error,
      isAdmin,
    } = this.state;
    if (window.innerWidth <= 1440) {
      mobilebranch = true;
    } else if (window.innerWidth > 1440) {
      mobilebranch = false;
    }
    if (window.innerWidth <= 425) {
      mymobileNewbranch = true;
    } else {
      mymobileNewbranch = false;
    }
    return (
      <>
        <Helmet>
          <title>UserDetail | FFF</title>
        </Helmet>
        {mobilebranch ? <></> : <Header />}
        <IntroLayout id="post_detaillayout">
          {isloading ? (
            <Loader />
          ) : !error ? (
            <>
              <UserDetail>
                <UserHeader id="user_header">
                  {UserDetailTextData.UserDetail_Header}
                </UserHeader>
                <UserContainer className="userContainer">
                  {mymobileNewbranch ? (
                    <UserLeftImg
                      className="userDetailImg"
                      currentDevice={mobilebranch ? true : false}
                      currentImg={fetchImg ? fetchImg : ""}
                    ></UserLeftImg>
                  ) : (
                    <UserLeftBox className="userLeftBox">
                      <UserLeftImg
                        className="userDetailImg"
                        currentDevice={mobilebranch ? true : false}
                        currentImg={fetchImg ? fetchImg : ""}
                      ></UserLeftImg>

                      <FlexBox>
                        <PortFolioInfo id="userdetail_left_portfolioInfo">
                          {UserDetailTextData.UserDetail_Column01}
                        </PortFolioInfo>
                        <PortFolioState id="userdetail_left_portfoliostate">
                          {portfolioData && portfolioData.pf_permission ? (
                            <SLink to={`/portfoliodetail/${portfolioData.id}`}>
                              {UserDetailTextData.UserDetail_Go_PortFolio}
                            </SLink>
                          ) : portfolioData && !portfolioData.pf_permission ? (
                            "대기중"
                          ) : (
                            "미등록"
                          )}
                        </PortFolioState>
                      </FlexBox>
                    </UserLeftBox>
                  )}
                  <UserRightBox className="userRightBox">
                    <UserRightListBox>
                      <UserRightList className="userRightList">
                        <UserRightColumn className="userdetail_userRightColumn">
                          {UserDetailTextData.UserDetail_Column02}
                        </UserRightColumn>
                        <UserRightInput
                          className="userdetail_userRightInput userdetail_realname"
                          // disabled
                          activated={activate_realname}
                          // defaultValue={
                          //   isAdmin && userData && userData.real_name
                          //     ? userData.real_name
                          //     : userData &&
                          //       activate_realname &&
                          //       userData.real_name
                          //     ? userData.real_name
                          //     : userData &&
                          //       userData.real_name &&
                          //       !activate_realname
                          //     ? "비공개"
                          //     : "조회 불가"
                          // }
                        >
                          {isAdmin && userData && userData.real_name
                            ? userData.real_name
                            : userData &&
                              activate_realname &&
                              userData.real_name
                            ? userData.real_name
                            : userData &&
                              userData.real_name &&
                              !activate_realname
                            ? "비공개"
                            : "조회 불가"}
                        </UserRightInput>
                      </UserRightList>
                      <UserRightList className="userRightList">
                        <UserRightColumn className="userdetail_userRightColumn">
                          {UserDetailTextData.UserDetail_Column03}
                        </UserRightColumn>
                        <UserRightInput
                          className="userdetail_userRightInput userdetail_nickname"
                          // disabled
                          activated={true}
                          // defaultValue={
                          //   userData && userData.nick_name
                          //     ? userData.nick_name
                          //     : "조회 불가"
                          // }
                        >
                          {userData && userData.nick_name
                            ? userData.nick_name
                            : "조회 불가"}
                        </UserRightInput>
                      </UserRightList>
                      <UserRightList className="userRightList">
                        <UserRightColumn className="userdetail_userRightColumn">
                          {UserDetailTextData.UserDetail_Column04}
                        </UserRightColumn>
                        <UserRightInput
                          className="userdetail_userRightInput"
                          // disabled
                          activated={activate_belongto}
                          // defaultValue={
                          //   isAdmin && userData && userData.belong_to
                          //     ? userData.belong_to
                          //     : userData &&
                          //       activate_belongto &&
                          //       userData.belong_to
                          //     ? userData.belong_to
                          //     : userData &&
                          //       userData.belong_to &&
                          //       !activate_belongto
                          //     ? "비공개"
                          //     : "없음"
                          // }
                        >
                          {isAdmin && userData && userData.belong_to
                            ? userData.belong_to
                            : userData &&
                              activate_belongto &&
                              userData.belong_to
                            ? userData.belong_to
                            : userData &&
                              userData.belong_to &&
                              !activate_belongto
                            ? "비공개"
                            : "없음"}
                        </UserRightInput>
                      </UserRightList>
                      <UserRightList className="userRightList">
                        <UserRightColumn className="userdetail_userRightColumn">
                          {UserDetailTextData.UserDetail_Column05}
                        </UserRightColumn>
                        <UserRightInput
                          className="userdetail_userRightInput"
                          // disabled
                          activated={activate_field}
                          // defaultValue={
                          //   isAdmin && userData && userData.field
                          //     ? userData.field
                          //     : userData && activate_field && userData.field
                          //     ? userData.field
                          //     : userData && userData.field && !activate_field
                          //     ? "비공개"
                          //     : "조회 불가"
                          // }
                        >
                          {isAdmin && userData && userData.field
                            ? userData.field
                            : userData && activate_field && userData.field
                            ? userData.field
                            : userData && userData.field && !activate_field
                            ? "비공개"
                            : "조회 불가"}
                        </UserRightInput>
                      </UserRightList>
                      <UserRightList className="userRightList">
                        <UserRightColumn className="userdetail_userRightColumn">
                          {UserDetailTextData.UserDetail_Column06}
                        </UserRightColumn>
                        <UserRightInput
                          className="userdetail_userRightInput"
                          // disabled
                          activated={true}
                          // defaultValue={
                          //   userData && userData.username
                          //     ? userData.username
                          //     : "조회 불가"
                          // }
                        >
                          {userData && userData.username
                            ? userData.username
                            : "조회 불가"}
                        </UserRightInput>
                      </UserRightList>
                      <UserRightList className="userRightList">
                        <UserRightColumn className="userdetail_userRightColumn">
                          {UserDetailTextData.UserDetail_Column07}
                        </UserRightColumn>
                        <UserGetHomePage
                          className="userdetail_inputbox"
                          activated={activate_homepage}
                        >
                          <UserGetHomePageSpan
                            className="userdetail_userRightSpan"
                            activated={activate_homepage}
                          >
                            {" "}
                            {isAdmin && userData && userData.homepage ? (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  userData.homepage.includes("https://") ||
                                  userData.homepage.includes("http://")
                                    ? userData.homepage
                                    : `https://${userData.homepage}`
                                }
                              >
                                {userData.homepage &&
                                userData.homepage.length >= 30 &&
                                mymobileNewbranch
                                  ? `${userData.homepage.slice(0, 25)}...`
                                  : userData.homepage}
                              </a>
                            ) : userData &&
                              activate_homepage &&
                              userData.homepage ? (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  userData.homepage.includes("https://") ||
                                  userData.homepage.includes("http://")
                                    ? userData.homepage
                                    : `https://${userData.homepage}`
                                }
                              >
                                {userData.homepage}
                              </a>
                            ) : userData &&
                              userData.homepage &&
                              !activate_homepage ? (
                              "비공개"
                            ) : (
                              "없음"
                            )}
                          </UserGetHomePageSpan>
                        </UserGetHomePage>
                      </UserRightList>
                      <UserRightList className="userRightList">
                        <UserRightColumn className="userdetail_userRightColumn">
                          {UserDetailTextData.UserDetail_Column08}
                        </UserRightColumn>
                        <UserRightInput
                          className="userdetail_userRightInput"
                          // disabled
                          activated={true}
                          // defaultValue={
                          //   userData && userData.description
                          //     ? userData.description
                          //     : "조회 불가"
                          // }
                        >
                          {userData && userData.description
                            ? userData.description
                            : "조회 불가"}
                        </UserRightInput>
                      </UserRightList>
                      {mymobileNewbranch ? (
                        <FlexBox className="userdetail_flexbox">
                          <PortFolioInfo id="userdetail_left_portfolioInfo">
                            {UserDetailTextData.UserDetail_Column01}
                          </PortFolioInfo>
                          <PortFolioState id="userdetail_left_portfoliostate">
                            {portfolioData && portfolioData.pf_permission ? (
                              <SLink
                                to={`/portfoliodetail/${portfolioData.id}`}
                              >
                                {UserDetailTextData.UserDetail_Go_PortFolio}
                              </SLink>
                            ) : portfolioData &&
                              !portfolioData.pf_permission ? (
                              "대기중"
                            ) : (
                              "미등록"
                            )}
                          </PortFolioState>
                        </FlexBox>
                      ) : (
                        <></>
                      )}
                    </UserRightListBox>
                  </UserRightBox>
                </UserContainer>
                <UserUnderBackBtn
                  className="userdetail_underbackbtn"
                  onClick={(e) => this.handleBackBtn()}
                >
                  {UserDetailTextData.UserDetail_Btn01}
                </UserUnderBackBtn>
              </UserDetail>
            </>
          ) : (
            <Error
              error={
                this.state.errorMsg !== ""
                  ? this.state.errorMsg
                  : TextData.Access_Deny
              }
            />
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterJBoard = withRouter(UserDetailPresenter);
export default withRouterJBoard;
