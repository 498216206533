import React, { lazy, Suspense } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import styled from "styled-components";
import Loader from "./Loader";
import Home from "../Routes/Home";
import Intro from "../Routes/Intro";
import JBoard from "../Routes/JBoard";
import Post from "../Routes/Post";
import UpdatePost from "../Routes/UpdatePost";
import PostDetail from "../Routes/PostDetail";
import Portfolio from "../Routes/Portfolio";
import Profile from "../Routes/Profile";
import MyPortFolio from "../Routes/Profile/MyPortFolio";
import Questions from "../Routes/Profile/Questions";
import MyPost from "../Routes/Profile/MyPost";
import PortfolioDetail from "../Routes/PortfolioDetail";
import UserDetail from "../Routes/UserDetail";
import Admin from "../Routes/Admin";
import AdminQuestions from "../Routes/AdminQuestions";
import QuestionDetail from "../Routes/QuestionDetail";
import AdminUser from "../Routes/AdminUser";
import SignUp from "../Auth/SignUpContainer";
import ConfirmSignUp from "../Auth/ConfirmEmailContainer";
import SignIn from "../Auth/LoginContainer";
import ForgotPassword from "../Auth/ForgotPasswordContainer";
import ResetPassword from "../Auth/PasswordResetContainer";
import { getCurrentUsernameGetSub } from "../Controllers/amplifyController";
// 배경 가지고 장난친다고 하면, 이 부분에서 withRouter로 장난
const GlobalStyles = lazy(() => import("./GlobalStyles"));

const LoadingBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #1a1a1a;
`;

class GlobalRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isauthorized: false,
      isloading: true,
    };
  }

  async componentDidMount() {
    let info;
    let test;
    try {
      info = await getCurrentUsernameGetSub();
      if (info !== null) {
        this.setState({ isauthorized: true, isloading: true, test });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false, info });
    }
  }
  render() {
    const { isloading, isauthorized, info } = this.state;
    return (
      <Router>
        <Suspense fallback={<LoadingBox></LoadingBox>}>
          <GlobalStyles />
        </Suspense>
        {isloading === true ? (
          <Loader />
        ) : (
          <>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route exact path="/Intro">
                <Intro isauthorized={isauthorized} isloading={isloading} />
              </Route>
              <Route path="/portfolio" exact component={Portfolio} />

              <Route path={`/jboard/:id`} exact component={JBoard} />

              <Route path={`/editpost/:id`} exact>
                {isauthorized === false ? <Redirect to="/" /> : <UpdatePost />}
              </Route>

              <Route path={`/upload/:id`} exact>
                {isauthorized === false ? <Redirect to="/" /> : <Post />}
              </Route>

              <Route path={`/postdetail/:id`} exact>
                {isauthorized === false ? <Redirect to="/" /> : <PostDetail />}
              </Route>

              <Route exact path={`/admin/${info}`}>
                {isauthorized === false ? <Redirect to="/" /> : <Admin />}
              </Route>

              <Route exact path={`/admin_question/${info}`}>
                {isauthorized === false ? (
                  <Redirect to="/" />
                ) : (
                  <AdminQuestions />
                )}
              </Route>
              <Route exact path={`/admin_question_details/:id`}>
                {isauthorized === false ? (
                  <Redirect to="/" />
                ) : (
                  <QuestionDetail />
                )}
              </Route>
              <Route exact path={`/admin_user/${info}`}>
                {isauthorized === false ? <Redirect to="/" /> : <AdminUser />}
              </Route>

              <Route
                exact
                path={`/portfoliodetail/:id`}
                component={PortfolioDetail}
              />

              <Route exact path={`/userdetail/:id`}>
                {isauthorized === false ? <Redirect to="/" /> : <UserDetail />}
              </Route>

              <Route exact path={`/profile/${info}`}>
                {isauthorized === false ? <Redirect to="/" /> : <Profile />}
              </Route>

              <Route exact path={`/myportfolio/${info}`}>
                {isauthorized === false ? <Redirect to="/" /> : <MyPortFolio />}
              </Route>

              <Route exact path={`/questions/${info}`}>
                {isauthorized === false ? <Redirect to="/" /> : <Questions />}
              </Route>

              <Route exact path={`/mypost/${info}`}>
                {isauthorized === false ? <Redirect to="/" /> : <MyPost />}
              </Route>

              <Route exact path="/join">
                {isauthorized === true ? <Redirect to="/" /> : <SignUp />}
              </Route>

              <Route exact path="/verify-code">
                {isauthorized === true ? (
                  <Redirect to="/" />
                ) : (
                  <ConfirmSignUp />
                )}
              </Route>

              <Route exact path="/login">
                {isauthorized === true ? <Redirect to="/" /> : <SignIn />}
              </Route>

              <Route exact path="/forgot-password">
                {isauthorized === true ? (
                  <Redirect to="/" />
                ) : (
                  <ForgotPassword />
                )}
              </Route>

              <Route exact path="/reset-password">
                {isauthorized === true ? (
                  <Redirect to="/" />
                ) : (
                  <ResetPassword />
                )}
              </Route>

              <Redirect from="*" to="/" />
            </Switch>
          </>
        )}
      </Router>
    );
  }
}

export default GlobalRouter;
