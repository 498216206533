import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import MobileHeader from "../../Components/MobileHeader";
import Error from "../../Components/Error";
import Pagination from "react-js-pagination";
import {
  BoardLowerMenuContainer,
  BoardLowerMenuImg,
  BoardLowerMenuText,
  BoardTextFlexbox,
  BoardUnauthorizedBox,
  BoardUnauthorizedBg,
  BoardUnauthorizedText,
  BoardUnauthorizedHighLight,
  BoardUpperMenuContainer,
  IntroLayout,
  IntroLayoutMenuOption,
  SLink,
  MyInfoNavLogoutContainer,
  MyInfoNavLogoutLeftBox,
  MyInfoNavLogoutLeftImg,
  MyInfoNavLogoutLeftContent,
  MyInfoNavLogoutRightBox,
  MyInfoNavLogoutBtn,
  Board_JBoardDropBox as JBoardDropBox,
  Board_JBoardDropBoxText as JBoardDropBoxText,
  Board_OverFlowBox as OverFlowBox,
  Board_JBoardDropBoxContent as JBoardDropBoxContent,
  Board_JBoardDropBoxInput as JBoardDropBoxInput,
  Board_JBoardSearchSelector as JBoardSearchSelector,
  Board_JBoardSearchOption as JBoardSearchOption,
  Board_IntroNavIcon as IntroNavIcon,
  Board_BoardDetail as BoardDetail,
  Board_BoardContainer as BoardContainer,
  Board_PostContentBox as PostContentBox,
  Board_PostIndex as PostIndex,
  Board_PostTitle as PostTitle,
  Board_PostAuthor as PostAuthor,
  Board_PostCreatedAt as PostCreatedAt,
  Board_PostReadCount as PostReadCount,
  Board_BoardCategoryBar as BoardCategoryBar,
  Board_CategoryHeader as CategoryHeader,
  Board_BoardCategoryContainer as BoardCategoryContainer,
  Board_BoardCategoryBox as BoardCategoryBox,
  Board_BoardSearchContainer as BoardSearchContainer,
  Board_BoardSearchBox as BoardSearchBox,
  Board_FlexBox as FlexBox,
  Board_BoardSearchSelector as BoardSearchSelector,
  Board_SelectorArrow as SelectorArrow,
  Board_BoardSearchContent as BoardSearchContent,
  Board_BoardPageCountBox as BoardPageCountBox,
  Board_BoardBtnBox as BoardBtnBox,
  Board_BoardWriteBtn as BoardWriteBtn,
  Board_UploadRecentIcon as UploadRecentIcon,
  Board_TagForm as TagForm,
  Board_TagRadioSection as TagRadioSection,
  Board_TagRadioLabel as TagRadioLabel,
  MobileInfoBox,
  MobileInfoLeftBox,
  MobileInfoLogoutLeftImg,
  MobileInfoLogoutLeftContent,
  MobileInfoLogoutRightBox,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import TextData from "../../Json/boardJson.json";
import JBoardTextData from "../../Json/JBoardJson.json";
import { Auth, API, Storage } from "aws-amplify";
import { listPosts, listComments } from "../../graphql/queries";
import {
  getCurrentUserInfo,
  fetchOneUserById,
} from "../../Controllers/amplifyController";
import "./communityresponsive.css";

class JBoardPresenter extends React.Component {
  constructor(props) {
    const { search } = props.history.location;
    const active = parseInt(search.split("?")[1]);
    super(props);
    this.state = {
      isloading: true,
      isAdmin: false,
      restricted: false,
      activePage: active ? active : 1,
      postPerPage: 10,
      categoryselector: "",
      errorMsg: "",
      commentCounter: [],
    };
  }
  handlePageChange(pageNumber) {
    this.props.history.push({
      pathname: `/jboard/index=?${pageNumber}`,
    });
    this.setState({ activePage: pageNumber });
  }

  async tagSearch(data) {
    let filterData;
    let commentCountlogic;
    let originCountData;
    try {
      filterData = this.state.origin.filter((el) => el.category === data);
      commentCountlogic = filterData.map((data) => this.fetchComments(data.id));
      originCountData = this.state.origin.map((data) =>
        this.fetchComments(data.id)
      );
      Promise.all(commentCountlogic).then((res) => {
        this.setState({
          commentCounter: res,
        });
      });
      this.setState({
        postData: filterData,
        categoryselector: data,
      });
      if (this.state.categoryselector === data) {
        Promise.all(originCountData).then((res) => {
          this.setState({
            commentCounter: res,
          });
        });
        this.setState({
          postData: this.state.origin,
          categoryselector: "",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  istagSelected(data) {
    if (this.state.categoryselector && this.state.categoryselector === data) {
      return true;
    }
    return false;
  }
  async handleSearch(e) {
    let searchdata = [];
    let newcounter;
    const { searchSelector } = this.state;
    if (searchSelector) {
      if (e.key === "Enter") {
        if (searchSelector === "제목") {
          searchdata = this.state.postData.filter((el) =>
            el.title.toLowerCase().includes(e.target.value)
          );
          newcounter = searchdata.map((data) => this.fetchComments(data.id));
        }
        if (searchSelector === "모집분야") {
          searchdata = this.state.postData.filter((el) =>
            el.recruitmentField.toLowerCase().includes(e.target.value)
          );
          newcounter = searchdata.map((data) => this.fetchComments(data.id));
        }
        if (searchSelector === "내용") {
          searchdata = this.state.postData.filter((el) =>
            el.description.toLowerCase().includes(e.target.value)
          );
          newcounter = searchdata.map((data) => this.fetchComments(data.id));
        }
        if (e.target.value.length === 0) {
          this.setState({ postData: this.state.origin });
        } else {
          Promise.all(newcounter).then((res) => {
            this.setState({
              commentCounter: res,
            });
          });
          this.setState({ postData: searchdata });
        }
      }
    } else {
      return;
    }
  }
  async handleSearchReset(e) {
    if (e.length === 0) {
      let counterdata;
      counterdata = await this.state.origin.map((data) =>
        this.fetchComments(data.id)
      );
      Promise.all(counterdata).then((res) => {
        this.setState({
          commentCounter: res,
        });
      });
      this.setState({ postData: this.state.origin, categoryselector: "" });
    }
  }

  // 요것두 여기서만 쓰이니깐 따로 호출 안함!
  async fetchPost() {
    let notDeletedData;
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listPosts,
        variables: { limit: 1000 },
      })
        .then((post) => {
          notDeletedData = post.data.listPosts.items.filter(
            (data) => data._deleted !== true
          );
          if (post.data.listPosts && post.data.listPosts.items) {
            resolve(notDeletedData);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }
  async fetchComments(id) {
    let filterComments;
    return new Promise((resolve, reject) => {
      API.graphql({
        query: listComments,
        variables: { filter: { postID: { contains: id } }, limit: 1000 },
      })
        .then((comments) => {
          if (comments.data.listComments && comments.data.listComments.items) {
            filterComments = comments.data.listComments.items.filter(
              (data) => data._deleted !== true
            );
            resolve(filterComments.length);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }
  async componentDidMount() {
    let user;
    let profileData;
    let postData;
    let fetchImg;
    let newarr;
    let modarr;
    let myarr;
    try {
      //new arr로 관리자 글 먼저 오고, 나머지 최신순으로 오게끔 정렬 postData는 그렇게 넘어감
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(user.id);
      postData = await this.fetchPost();
      postData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      newarr = postData.filter((data) => data.isAdmin === true);
      modarr = postData.filter((data) => data.isAdmin !== true);
      newarr = [...newarr, ...modarr];
      myarr = newarr.map((data) => this.fetchComments(data.id));
      Promise.all(myarr).then((res) => {
        this.setState({
          commentCounter: res,
        });
      });

      if (
        profileData === null ||
        Object.values(profileData).filter((data) => data === "").length > 2
      ) {
        this.setState({
          restricted: true,
          user,
          errorMsg: "프로필을 먼저 작성해주세요.",
        });
      } else if (
        (profileData && !profileData.membership) ||
        profileData.membership === null
      ) {
        this.setState({
          restricted: true,
          errorMsg: "관리자 승인 후 이용 가능합니다.",
        });
      } else if (profileData && profileData.avatar_url) {
        fetchImg = await Storage.get(profileData.avatar_url, {
          contentType: "url",
        });
        this.setState({
          isloading: true,
          user,
          profileData,
          fetchImg,
          postData: newarr,
          origin: newarr,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        isloading: false,
        user,
        postData: newarr,
        origin: newarr,
      });
    }
  }

  render() {
    let renderPosts;
    const {
      isloading,
      user,
      fetchImg,
      postData,
      postPerPage,
      activePage,
      restricted,
      // postIdArr,
      commentCounter,
    } = this.state;
    const {
      location: { pathname },
      islogged,
      info,
    } = this.props;
    const indexOfLastPost = activePage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    let desktopViewbranch;
    desktopViewbranch = window.innerWidth;
    if (desktopViewbranch > 1440) {
      desktopViewbranch = true;
    } else {
      desktopViewbranch = false;
    }
    let mobileCommuBranch;
    mobileCommuBranch = window.innerWidth;
    if (mobileCommuBranch <= 425) {
      mobileCommuBranch = true;
    } else {
      mobileCommuBranch = false;
    }
    if (
      postData !== null &&
      postData !== undefined &&
      commentCounter !== null &&
      commentCounter !== undefined
    ) {
      const currentPost = postData.slice(indexOfFirstPost, indexOfLastPost);
      const currentPostIdArr = commentCounter.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      renderPosts = currentPost.map((data, index) => {
        let d = new Date(data.createdAt);
        let newDate = `${d.getUTCFullYear()}-${
          (d.getUTCMonth() + 1).toString().length === 1
            ? `0${d.getUTCMonth() + 1}`
            : d.getUTCMonth() + 1
        }-${
          d.getUTCDate().toString().length === 1
            ? `0${d.getUTCDate()}`
            : d.getUTCDate()
        } ${
          d.getHours().toString().length === 1
            ? `0${d.getHours()}`
            : d.getHours()
        }:${
          d.getMinutes().toString().length === 1
            ? `0${d.getMinutes()}`
            : d.getMinutes()
        }`;
        return (
          <PostContentBox className="community_content_box" key={index}>
            <PostIndex className="community_postIndex">
              {index + 1 + (activePage - 1) * 10}
            </PostIndex>
            <PostTitle
              className="community_postTitle"
              isAdmin={data.isAdmin ? true : false}
            >
              {desktopViewbranch ? (
                <SLink to={`/postdetail/${data.id}`}>
                  {!data.title || !data.category
                    ? "조회 불가"
                    : data.title.length > 38 && data.category
                    ? `[${data.category}]${data.title.slice(0, 38)}..`
                    : `[${data.category}]${data.title.slice(0, 38)}`}
                </SLink>
              ) : (
                <SLink to={`/postdetail/${data.id}`}>
                  {!data.title || !data.category
                    ? "조회 불가"
                    : data.title.length > 28 && data.category
                    ? `[${data.category}]${data.title.slice(0, 28)}..`
                    : `[${data.category}]${data.title.slice(0, 28)}`}
                  {mobileCommuBranch ? (
                    <span className="mobile_counter">
                      {currentPostIdArr[index]}
                    </span>
                  ) : (
                    <></>
                  )}
                </SLink>
              )}
              <UploadRecentIcon
                className="community_uploadRecentIcon"
                // 관리자 글 맥스로 정해두고 포함 인덱스 6개까지, 단 관리자 글은 최신 표시 안하기
                current={
                  !data.isAdmin && index + 1 + (activePage - 1) * 10 < 6
                    ? true
                    : false
                }
              />
              {mobileCommuBranch ? (
                <></>
              ) : (
                <div className="comment_counter">{currentPostIdArr[index]}</div>
              )}
            </PostTitle>
            <PostAuthor className="community_postAuthor">
              {!data.writer
                ? "조회 불가"
                : data.writer.length > 6
                ? `${data.writer.slice(0, 6)}..`
                : data.writer.slice(0, 6)}
            </PostAuthor>
            <PostCreatedAt className="community_postCreatedAt">
              {newDate}
            </PostCreatedAt>

            <PostReadCount className="community_postReadCount">
              {data.ReadCount ? data.ReadCount : "0"}
            </PostReadCount>
          </PostContentBox>
        );
      });
    }

    async function onSignOutClick() {
      await Auth.signOut({ global: true })
        .then((data) => {
          window.location.reload();
        })
        .catch((err) => console.log(err));
    }
    let mobilebranch;

    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    let paginationbranch;
    paginationbranch = window.innerWidth;
    if (paginationbranch <= 425) {
      paginationbranch = true;
    } else {
      paginationbranch = false;
    }

    return (
      <>
        <Helmet>
          <title>Community | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <IntroLayout id="community_layout">
          {islogged ? (
            <>
              <BoardUpperMenuContainer id="community_profileContainer">
                <IntroLayoutMenuOption
                  className="community_profileMenuOptions"
                  current={pathname === "/jboard/index="}
                >
                  <SLink to="/jboard/index=?1">
                    {TextData.BoardUpperMenuContent01}
                  </SLink>
                </IntroLayoutMenuOption>
              </BoardUpperMenuContainer>
              {isloading ? (
                <Loader />
              ) : !restricted ? (
                <>
                  <BoardDetail id="community_detail_Container">
                    <BoardContainer>{renderPosts}</BoardContainer>
                    <BoardCategoryBar id="community_side_detail">
                      <BoardCategoryContainer id="categorybox_container">
                        <CategoryHeader className="categorybox_container_header">
                          {JBoardTextData.JBoard_Header}
                        </CategoryHeader>
                        <BoardCategoryBox>
                          <TagForm>
                            <TagRadioSection>
                              <TagRadioLabel
                                className="categorybox_container_tag"
                                selected={this.istagSelected(
                                  JBoardTextData.JBoard_Label04
                                )}
                                onClick={(e) =>
                                  this.tagSearch(e.currentTarget.innerHTML)
                                }
                              >
                                {JBoardTextData.JBoard_Label04}
                              </TagRadioLabel>
                              <TagRadioLabel
                                className="categorybox_container_tag"
                                selected={this.istagSelected(
                                  JBoardTextData.JBoard_Label01
                                )}
                                onClick={(e) =>
                                  this.tagSearch(e.currentTarget.innerHTML)
                                }
                              >
                                {JBoardTextData.JBoard_Label01}
                              </TagRadioLabel>
                              <TagRadioLabel
                                className="categorybox_container_tag"
                                selected={this.istagSelected(
                                  JBoardTextData.JBoard_Label02
                                )}
                                onClick={(e) =>
                                  this.tagSearch(e.currentTarget.innerHTML)
                                }
                              >
                                {JBoardTextData.JBoard_Label02}
                              </TagRadioLabel>
                              <TagRadioLabel
                                className="categorybox_container_tag"
                                selected={this.istagSelected(
                                  JBoardTextData.JBoard_Label05
                                )}
                                onClick={(e) =>
                                  this.tagSearch(e.currentTarget.innerHTML)
                                }
                              >
                                {JBoardTextData.JBoard_Label05}
                              </TagRadioLabel>
                              <TagRadioLabel
                                className="categorybox_container_tag"
                                selected={this.istagSelected(
                                  JBoardTextData.JBoard_Label03
                                )}
                                onClick={(e) =>
                                  this.tagSearch(e.currentTarget.innerHTML)
                                }
                              >
                                {JBoardTextData.JBoard_Label03}
                              </TagRadioLabel>
                            </TagRadioSection>
                          </TagForm>
                        </BoardCategoryBox>
                      </BoardCategoryContainer>
                      <BoardSearchContainer>
                        <CategoryHeader className="categorybox_container_header_search">
                          {JBoardTextData.JBoard_Search}
                        </CategoryHeader>
                        <FlexBox>
                          <SelectorArrow />
                          <BoardSearchBox
                            name="jboard_search"
                            id="jboard_search"
                            onChange={(e) =>
                              this.setState({ searchSelector: e.target.value })
                            }
                          >
                            <BoardSearchSelector value="">
                              {JBoardTextData.JBoard_Selector_Default}
                            </BoardSearchSelector>
                            <BoardSearchSelector
                              value={JBoardTextData.JBoard_Selector_Opt01}
                            >
                              {JBoardTextData.JBoard_Selector_Opt01}
                            </BoardSearchSelector>
                            <BoardSearchSelector
                              value={JBoardTextData.JBoard_Selector_Opt02}
                            >
                              {JBoardTextData.JBoard_Selector_Opt02}
                            </BoardSearchSelector>

                            <BoardSearchSelector
                              value={JBoardTextData.JBoard_Selector_Opt03}
                            >
                              {JBoardTextData.JBoard_Selector_Opt03}
                            </BoardSearchSelector>
                          </BoardSearchBox>
                        </FlexBox>
                        <BoardSearchContent
                          className="category_search_input"
                          onKeyDown={(e) => this.handleSearch(e)}
                          onChange={(e) =>
                            this.handleSearchReset(e.target.value)
                          }
                        ></BoardSearchContent>
                      </BoardSearchContainer>
                    </BoardCategoryBar>
                  </BoardDetail>
                  <BoardPageCountBox>
                    {/* 1부터 5까지의 배열 */}
                    {paginationbranch ? (
                      <></>
                    ) : (
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={postData ? postData.length : 20}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="pagination_li_class"
                        linkClass="pagination_link_class"
                        // hideFirstLastPages="true"
                        // prevPageText="이전페이지"
                        // nextPageText="다음페이지"
                        // pageRangeDisplayed="disabled"
                      />
                    )}

                    {/* 이전 & 다음 */}
                    <Pagination
                      innerClass="pagination_kor"
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={postData ? postData.length : 20}
                      // pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                      itemClass="pagination_li_class"
                      linkClass="pagination_kor_btn"
                      hideFirstLastPages={true}
                      prevPageText="이전페이지"
                      nextPageText="다음페이지"
                      pageRangeDisplayed="disabled"
                    />
                    <li className="pagination_kor_btn kor_list">
                      <SLink to={user ? `/upload/${user.id}` : "/"}>
                        {JBoardTextData.JBoard_Write_Btn}
                      </SLink>
                    </li>
                  </BoardPageCountBox>
                  {/* <BoardBtnBox>
                    <BoardWriteBtn id="community_writeBtn">
                      <SLink to={user ? `/upload/${user.id}` : "/"}>
                        {JBoardTextData.JBoard_Write_Btn}
                      </SLink>
                    </BoardWriteBtn>
                  </BoardBtnBox> */}
                  {mobilebranch ? (
                    <MobileInfoBox id="mobileInfoBox">
                      <MobileInfoLeftBox id="mobileInfoLeftBox">
                        <MobileInfoLogoutLeftImg
                          id="mobileInfoLeftImg"
                          avatar_url={fetchImg ? fetchImg : ""}
                        ></MobileInfoLogoutLeftImg>
                        <MobileInfoLogoutLeftContent id="mobileInfoLeftContent">
                          <SLink
                            to={
                              info && info.attributes && info.attributes.sub
                                ? `/profile/${info.attributes.sub}`
                                : ""
                            }
                          >
                            {user && user.attributes
                              ? user.attributes.name
                              : "아무개"}
                            님
                          </SLink>
                        </MobileInfoLogoutLeftContent>
                      </MobileInfoLeftBox>
                      <MobileInfoLogoutRightBox
                        onClick={onSignOutClick}
                        id="mobileInfoRightBox"
                      ></MobileInfoLogoutRightBox>
                    </MobileInfoBox>
                  ) : (
                    <></>
                  )}

                  <BoardLowerMenuContainer id="myinfo_togglemenu">
                    <MyInfoNavLogoutContainer>
                      <MyInfoNavLogoutLeftBox>
                        <MyInfoNavLogoutLeftImg
                          avatar_url={fetchImg ? fetchImg : ""}
                        ></MyInfoNavLogoutLeftImg>
                        <MyInfoNavLogoutLeftContent>
                          <SLink
                            to={
                              info && info.attributes && info.attributes.sub
                                ? `/profile/${info.attributes.sub}`
                                : ""
                            }
                          >
                            {user && user.attributes
                              ? user.attributes.name
                              : "아무개"}
                            님
                          </SLink>
                        </MyInfoNavLogoutLeftContent>
                      </MyInfoNavLogoutLeftBox>
                      <MyInfoNavLogoutRightBox onClick={onSignOutClick}>
                        {/* <MyInfoNavLogoutBtn onClick={onSignOutClick}> */}
                        {/* {JBoardTextData.JBoard_LOGOUT} */}
                        {/* </MyInfoNavLogoutBtn> */}
                      </MyInfoNavLogoutRightBox>
                    </MyInfoNavLogoutContainer>
                  </BoardLowerMenuContainer>
                </>
              ) : (
                <>
                  <Error
                    error={
                      this.state.errorMsg !== ""
                        ? this.state.errorMsg
                        : "알 수 없는 에러가 발생하였습니다."
                    }
                  />
                  <BoardLowerMenuContainer id="myinfo_togglemenu">
                    <MyInfoNavLogoutContainer>
                      <MyInfoNavLogoutLeftBox>
                        <MyInfoNavLogoutLeftImg
                          avatar_url={fetchImg ? fetchImg : ""}
                        ></MyInfoNavLogoutLeftImg>
                        <MyInfoNavLogoutLeftContent>
                          <SLink
                            to={
                              info && info.attributes
                                ? `/profile/${info.attributes.sub}`
                                : ""
                            }
                          >
                            {user && user.attributes
                              ? user.attributes.name
                              : "아무개"}
                            님
                          </SLink>
                        </MyInfoNavLogoutLeftContent>
                      </MyInfoNavLogoutLeftBox>
                      <MyInfoNavLogoutRightBox onClick={onSignOutClick}>
                        {/* <MyInfoNavLogoutBtn onClick={onSignOutClick}> */}
                        {/* {JBoardTextData.JBoard_LOGOUT} */}
                        {/* </MyInfoNavLogoutBtn> */}
                      </MyInfoNavLogoutRightBox>
                    </MyInfoNavLogoutContainer>
                  </BoardLowerMenuContainer>
                </>
              )}
            </>
          ) : (
            <>
              <BoardUpperMenuContainer id="community_profileContainer">
                <IntroLayoutMenuOption
                  className="community_profileMenuOptions"
                  current={pathname === "/jboard/index="}
                >
                  <SLink to="/jboard/index=?1">
                    {TextData.BoardUpperMenuContent01}
                  </SLink>
                </IntroLayoutMenuOption>
              </BoardUpperMenuContainer>
              <JBoardDropBox id="community_not_login">
                <JBoardDropBoxText>
                  {TextData.JBoardSideDropBox01}
                </JBoardDropBoxText>
                <OverFlowBox>
                  <JBoardDropBoxContent>공지</JBoardDropBoxContent>
                  <JBoardDropBoxContent>구인구직</JBoardDropBoxContent>
                  <JBoardDropBoxContent>아무말</JBoardDropBoxContent>
                </OverFlowBox>
                <JBoardDropBoxText>
                  {TextData.JBoardSideDropBox05}
                </JBoardDropBoxText>
                <JBoardSearchSelector>
                  <IntroNavIcon />
                  <JBoardSearchOption>
                    {TextData.JBoardSideDropBox06}
                  </JBoardSearchOption>
                </JBoardSearchSelector>
                <JBoardDropBoxInput />
              </JBoardDropBox>
              <BoardUnauthorizedBox className="not_login_unauthorizedBox">
                <BoardUnauthorizedBg />
                <BoardTextFlexbox>
                  <BoardUnauthorizedText>
                    {TextData.UnauthorizedBoardText01}
                    <BoardUnauthorizedHighLight className="not_login_unauthorizedHighLight">
                      <SLink to="/login">
                        {TextData.UnauthorizedBoardSpan01}
                      </SLink>
                    </BoardUnauthorizedHighLight>
                    {TextData.UnauthorizedBoardText03}
                    <BoardUnauthorizedHighLight className="not_login_unauthorizedHighLight">
                      <SLink to="/intro">
                        {TextData.UnauthorizedBoardSpan02}
                      </SLink>
                    </BoardUnauthorizedHighLight>
                    {TextData.UnauthorizedBoardText04}
                  </BoardUnauthorizedText>
                </BoardTextFlexbox>
              </BoardUnauthorizedBox>
              <BoardLowerMenuContainer className="not_login_boardlower">
                <BoardLowerMenuImg className="not_login_boardImg" />
                <SLink to="/login">
                  <BoardLowerMenuText className="not_login_boardTxt">
                    {TextData.NeedLogin}
                  </BoardLowerMenuText>
                </SLink>
              </BoardLowerMenuContainer>
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterJBoard = withRouter(JBoardPresenter);
export default withRouterJBoard;
