import React from "react";
import AdminQuestionsPresenter from "./AdminQuestionsPresenter";
import TextData from "../../Json/boardJson.json";
import { getCurrentUsername } from "../../Controllers/amplifyController";
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      islogged: false,
    };
  }

  async componentDidMount() {
    let info;
    try {
      info = await getCurrentUsername();
      if (info !== null) {
        this.setState({ islogged: true });
      }
    } catch {
      this.setState({
        error: TextData.Error_msg,
      });
    } finally {
      this.setState({ loading: false, info });
    }
  }
  render() {
    const { loading, error, info, islogged } = this.state;
    return (
      <AdminQuestionsPresenter
        loading={loading}
        error={error}
        info={info}
        islogged={islogged}
      />
    );
  }
}
