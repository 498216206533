import React from "react";
import IntroPresenter from "./IntroPresenter";
import DefaultBg from "../../assets/all/pc_bg.jpg";

const bodyselector = document.getElementsByTagName("body")[0];
function IntroContainer(props) {
  const { isauthorized, isloading } = props;
  bodyselector.style.backgroundImage = `url(${DefaultBg})`;

  return <IntroPresenter isauthorized={isauthorized} isloading={isloading} />;
}

export default IntroContainer;
