import React from "react";
import Helmet from "react-helmet";
import Header from "../../Components/Header";
import "./postresponsive.css";
import {
  IntroLayout,
  SLink,
  IntroLayoutMenuOption,
  BoardUpperMenuContainer,
  Post_IntroDetail as IntroDetail,
  Post_UploadContainer as UploadContainer,
  Post_UploadBox as UploadBox,
  Post_UploadColumn as UploadColumn,
  Post_UploadListContainer as UploadListContainer,
  Post_UploadList as UploadList,
  Post_SelectorOptions as SelectorOptions,
  Post_UploadCategory as UploadCategory,
  Post_UploadTitle as UploadTitle,
  Post_UploadWriter as UploadWriter,
  Post_UploadWriterContent as UploadWriterContent,
  Post_UploadWriterImg as UploadWriterImg,
  Post_UploadWriterNickName as UploadWriterNickName,
  Post_UploadLink as UploadLink,
  Post_UploadField as UploadField,
  Post_UploadCost as UploadCost,
  Post_UploadDescription as UploadDescription,
  Post_UploadFile as UploadFile,
  Post_FileBox as FileBox,
  Post_UploadAttachLabel as UploadAttachLabel,
  Post_UploadAttachContent as UploadAttachContent,
  Post_UploadAttachSpan as UploadAttachSpan,
  Post_FlexBox as FlexBox,
  Post_BackBtn as BackBtn,
  Post_ErrMsg as ErrMsg,
  Post_CategoryCircle as CategoryCircle,
} from "../../Components/Layout";
import { withRouter } from "react-router-dom";
import Loader from "../../Components/Loader";
import Error from "../../Components/Error";
import TextData from "../../Json/boardJson.json";
import PostTextData from "../../Json/postJson.json";
import { API, Storage } from "aws-amplify";
import { createPost as createPostM } from "../../graphql/mutations";
import {
  getCurrentUserInfo,
  fetchOneUserById,
  fetchAllPostT,
} from "../../Controllers/amplifyController";
import urlRegex from "url-regex";

const initialFormSate = {
  id: "",
  isAdmin: false,
  category: "",
  recruitmentField: "",
  quotedCost: "",
  writer: "",
  link: "",
  title: "",
  description: "",
  file: "",
};
class PostPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: initialFormSate,
      isloading: true,
      error: false,
      not_allow_errorMsg: "",
    };
  }

  async createPost(formData) {
    if (!formData.id) return;
    if (!formData.isAdmin) {
      if (
        formData.category === "공지" ||
        formData.category === "아무말" ||
        formData.category === "질문" ||
        formData.category === "팁"
      ) {
        if (formData.title === "") {
          this.setState({ errorMsg: PostTextData.Post_Err_No_Title });
          return;
        }
        if (formData.description === "") {
          this.setState({ errorMsg: PostTextData.Post_Err_No_Description });
          return;
        }
        if (
          formData.link !== "" &&
          !urlRegex({ exact: true }).test(formData.link)
        ) {
          this.setState({ errorMsg: "올바른 링크를 입력하세요" });
          return;
        }
      } else {
        if (formData.category === "") {
          this.setState({ errorMsg: PostTextData.Post_Err_No_Category });
          return;
        }
        if (formData.recruitmentField === "") {
          this.setState({ errorMsg: PostTextData.Post_Err_No_Recruitment });
          return;
        }
        if (formData.quotedCost === "") {
          this.setState({ errorMsg: PostTextData.Post_Err_No_QuotedCost });
          return;
        }
        if (
          formData.link !== "" &&
          !urlRegex({ exact: true }).test(formData.link)
        ) {
          this.setState({ errorMsg: "올바른 링크를 입력하세요" });
          return;
        }
        if (formData.title === "") {
          this.setState({ errorMsg: PostTextData.Post_Err_No_Title });
          return;
        }
        if (formData.description === "") {
          this.setState({ errorMsg: PostTextData.Post_Err_No_Description });
          return;
        }
      }
    }

    try {
      await API.graphql({
        query: createPostM,
        variables: { input: formData },
      });
      if (formData.file) {
        await Storage.put(formData.file, this.state.postFile);
      }
    } catch (err) {
      console.log(err);
    } finally {
      window.history.back();
    }
  }
  async onChangePostFile(e) {
    if (!e.target.files[0]) return;
    const file = await e.target.files[0];
    const fileIdName = `${this.state.formData.id}postFile${file.name}`;
    this.setState({
      formData: {
        ...this.state.formData,
        file: fileIdName,
      },
      postFile: file,
    });
  }

  async componentDidMount() {
    let user;
    let profileData;
    let fetchImg;
    let fetchAllPost;
    let newPostId;
    try {
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(user.id);
      fetchAllPost = await fetchAllPostT();
      fetchAllPost.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      if (fetchAllPost === null || fetchAllPost.length === 0) {
        newPostId = `0post-${user.id}`;
      }
      if (fetchAllPost !== null && fetchAllPost.length > 0) {
        newPostId = `${
          parseInt(fetchAllPost[0].id.split("post-")[0]) + 1
        }post-${user.id}`;
      }
      if (
        profileData === null ||
        Object.values(profileData).filter((data) => data === "").length > 2
      ) {
        this.setState({ error: true, user });
      } else if (
        (profileData && !profileData.membership) ||
        profileData.membership === null
      ) {
        this.setState({
          error: true,
          not_allow_errorMsg: "관리자 승인 후 이용 가능합니다.",
        });
      } else if (profileData) {
        if (profileData.avatar_url) {
          fetchImg = await Storage.get(profileData.avatar_url, {
            contentType: "url",
          });
        }
        this.setState({
          isloading: true,
          user,
          formData: {
            ...this.state.formData,
            id: newPostId,
            isAdmin: profileData.isAdmin,
            writer: profileData.nick_name,
          },
          profileData,
          fetchImg,
          fetchAllPost,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ isloading: false, user, fetchAllPost });
    }
  }
  handleBackPage() {
    window.history.back();
  }
  render() {
    let mobilebranch;
    let loweripad;
    const { isloading, profileData, formData, error, fetchImg } = this.state;
    const {
      location: { pathname },
    } = this.props;
    if (window.innerWidth <= 425) {
      mobilebranch = true;
    } else if (window.innerWidth > 425) {
      mobilebranch = false;
    }
    if (window.innerWidth < 768) {
      loweripad = true;
    } else if (window.innerWidth >= 768) {
      loweripad = false;
    }
    return (
      <>
        <Helmet>
          <title>Upload | FFF</title>
        </Helmet>
        {/* 헤더 분기 */}
        {/* <Header /> */}
        <IntroLayout id="post_detaillayout">
          {/* <BoardUpperMenuContainer>
            <IntroLayoutMenuOption current={pathname === "/jboard/index=?1"}>
              <SLink to="/jboard/index=?1">
                {TextData.BoardUpperMenuContent01}
              </SLink>
            </IntroLayoutMenuOption>
          </BoardUpperMenuContainer> */}
          {isloading ? (
            <Loader />
          ) : !error ? (
            <>
              <IntroDetail>
                <UploadContainer className="post_upload_container">
                  <UploadBox className="post_upload_box">
                    <UploadListContainer>
                      <UploadList className="post_upload_list">
                        <UploadColumn className="post_upload_column">
                          {PostTextData.Post_Column_01}
                        </UploadColumn>
                        <CategoryCircle />
                        <UploadCategory
                          className="post_upload_category"
                          onChange={(e) => {
                            if (
                              e.target.value === "공지" ||
                              e.target.value === "아무말" ||
                              e.target.value === "질문" ||
                              e.target.value === "팁"
                            ) {
                              this.setState({
                                formData: {
                                  ...formData,
                                  category: e.target.value,
                                  recruitmentField: "",
                                  quotedCost: "",
                                },
                              });
                            } else {
                              this.setState({
                                formData: {
                                  ...formData,
                                  category: e.target.value,
                                },
                              });
                            }
                          }}
                        >
                          <SelectorOptions value="">
                            {PostTextData.Post_Selector_Opt_Default}
                          </SelectorOptions>
                          <SelectorOptions value="아무말">
                            {PostTextData.Post_Selector_Opt_01}
                          </SelectorOptions>
                          <SelectorOptions value="질문">
                            {PostTextData.Post_Selector_Opt_02}
                          </SelectorOptions>
                          <SelectorOptions value="팁">
                            {PostTextData.Post_Selector_Opt_03}
                          </SelectorOptions>
                          <SelectorOptions value="구인구직">
                            {PostTextData.Post_Selector_Opt_04}
                          </SelectorOptions>{" "}
                          {formData.isAdmin ? (
                            <SelectorOptions value="공지">
                              {PostTextData.Post_Selector_Opt_05}
                            </SelectorOptions>
                          ) : (
                            <></>
                          )}
                        </UploadCategory>
                        <UploadColumn
                          freecategory={loweripad ? true : false}
                          className="post_upload_column"
                        >
                          {PostTextData.Post_Column_02}
                        </UploadColumn>
                        <UploadWriter className="post_upload_wrtier">
                          <UploadWriterContent className="post_upload_writer_content">
                            <UploadWriterImg
                              className="post_upload_wrtier_img"
                              fetchImg={fetchImg ? fetchImg : ""}
                            ></UploadWriterImg>
                            <UploadWriterNickName className="post_upload_writer_nickname">
                              {profileData && profileData.nick_name
                                ? profileData.nick_name
                                : "조회 불가"}
                            </UploadWriterNickName>
                          </UploadWriterContent>
                        </UploadWriter>
                        <UploadColumn className="post_upload_column">
                          {PostTextData.Post_Column_03}
                        </UploadColumn>
                        <UploadLink
                          className="post_upload_link"
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                link: e.target.value,
                              },
                            })
                          }
                        ></UploadLink>
                      </UploadList>
                      <UploadList className="post_upload_list">
                        <UploadColumn
                          className="post_upload_column_second"
                          freecategory={
                            formData.category === "공지" ||
                            formData.category === "아무말" ||
                            formData.category === "질문" ||
                            formData.category === "팁"
                              ? true
                              : false
                          }
                        >
                          {PostTextData.Post_Column_04}
                        </UploadColumn>
                        <UploadField
                          className="post_upload_field"
                          freecategory={
                            formData.category === "공지" ||
                            formData.category === "아무말" ||
                            formData.category === "질문" ||
                            formData.category === "팁"
                              ? true
                              : false
                          }
                          defaultValue={
                            formData.recruitmentField !== ""
                              ? formData.recruitmentField
                              : ""
                          }
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                recruitmentField: e.target.value,
                              },
                            })
                          }
                        ></UploadField>
                        <UploadColumn
                          className="post_upload_column_second"
                          freecategory={
                            formData.category === "공지" ||
                            formData.category === "아무말" ||
                            formData.category === "질문" ||
                            formData.category === "팁"
                              ? true
                              : false
                          }
                        >
                          {PostTextData.Post_Column_05}
                        </UploadColumn>
                        <UploadCost
                          className="post_upload_cost"
                          freecategory={
                            formData.category === "공지" ||
                            formData.category === "아무말" ||
                            formData.category === "질문" ||
                            formData.category === "팁"
                              ? true
                              : false
                          }
                          defaultValue={
                            formData.quotedCost !== ""
                              ? formData.quotedCost
                              : ""
                          }
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                quotedCost: e.target.value,
                              },
                            })
                          }
                        ></UploadCost>
                        {loweripad ? (
                          <></>
                        ) : (
                          <FileBox className="post_upload_fileBox">
                            <UploadAttachLabel>
                              <UploadAttachContent className="post_upload_attachContent">
                                {PostTextData.Post_Column_06}
                              </UploadAttachContent>
                              <UploadFile
                                type="file"
                                id="post_file"
                                name="post_file"
                                onChange={(e) => this.onChangePostFile(e)}
                              ></UploadFile>
                              <UploadAttachSpan className="post_upload_attachSpan">
                                {this.state.postFile
                                  ? `${this.state.postFile.name.substr(
                                      0,
                                      11
                                    )}...`
                                  : ""}
                              </UploadAttachSpan>
                            </UploadAttachLabel>
                          </FileBox>
                        )}
                      </UploadList>
                      {loweripad ? (
                        <UploadList className="post_upload_list">
                          <FileBox className="post_upload_fileBox">
                            <UploadAttachLabel>
                              <UploadAttachContent className="post_upload_attachContent">
                                {PostTextData.Post_Column_06}
                              </UploadAttachContent>
                              <UploadFile
                                type="file"
                                id="post_file"
                                name="post_file"
                                onChange={(e) => this.onChangePostFile(e)}
                              ></UploadFile>
                              <UploadAttachSpan className="post_upload_attachSpan">
                                {this.state.postFile
                                  ? `${this.state.postFile.name.substr(
                                      0,
                                      11
                                    )}...`
                                  : ""}
                              </UploadAttachSpan>
                            </UploadAttachLabel>
                          </FileBox>
                        </UploadList>
                      ) : (
                        <></>
                      )}
                      <UploadList className="post_upload_list">
                        <UploadColumn className="post_upload_column_second">
                          {PostTextData.Post_Column_07}
                        </UploadColumn>
                        <UploadTitle
                          className="post_upload_title"
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                title: e.target.value,
                              },
                            })
                          }
                        ></UploadTitle>
                      </UploadList>
                      <UploadList className="post_upload_list">
                        <UploadColumn className="post_upload_column_second">
                          {PostTextData.Post_Column_08}
                        </UploadColumn>
                        <UploadDescription
                          className="post_upload_description"
                          onChange={(e) =>
                            this.setState({
                              formData: {
                                ...formData,
                                description: e.target.value,
                              },
                            })
                          }
                        ></UploadDescription>
                      </UploadList>
                    </UploadListContainer>
                  </UploadBox>
                  <FlexBox className="post_btn_box">
                    <BackBtn
                      className="post_btn"
                      onClick={(e) => this.handleBackPage(e)}
                    >
                      {PostTextData.Post_Btn_01}
                    </BackBtn>
                    <BackBtn
                      className="post_btn"
                      onClick={(e) => this.createPost(formData)}
                    >
                      {PostTextData.Post_Btn_02}
                    </BackBtn>
                  </FlexBox>
                  {this.state.errorMsg ? (
                    <ErrMsg>{this.state.errorMsg}</ErrMsg>
                  ) : (
                    <></>
                  )}
                </UploadContainer>
              </IntroDetail>
            </>
          ) : (
            <>
              <Error
                error={
                  this.state.not_allow_errorMsg !== ""
                    ? this.state.not_allow_errorMsg
                    : TextData.Access_Deny
                }
              />
            </>
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterPost = withRouter(PostPresenter);
export default withRouterPost;
