import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

@font-face {
    font-family: "Alfredo"; 
    src: url("//db.onlinewebfonts.com/t/ebc0e8d72223ae4fcb5d7ecb3a030441.eot"); 
    src: url("//db.onlinewebfonts.com/t/ebc0e8d72223ae4fcb5d7ecb3a030441.eot?#iefix") format("embedded-opentype"), 
    /* url("//db.onlinewebfonts.com/t/ebc0e8d72223ae4fcb5d7ecb3a030441.woff2") format("woff2"),  */
    url("//db.onlinewebfonts.com/t/ebc0e8d72223ae4fcb5d7ecb3a030441.woff") format("woff"), 
    url("//db.onlinewebfonts.com/t/ebc0e8d72223ae4fcb5d7ecb3a030441.ttf") format("truetype"), 
    url("//db.onlinewebfonts.com/t/ebc0e8d72223ae4fcb5d7ecb3a030441.svg#Alfredo's Dance") format("svg"); }

`;
