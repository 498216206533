import styled from "styled-components";
import { Form } from "antd";

/** App Theme */

const FormWrapper = styled(Form)`
  /* width: 500px; */
  /* height: 600px; */
  /* max-width: 350px; */
  /* margin: 7% auto !important; */
  /* padding: 42px 24px 50px !important; */
  background: transparent;
  position: relative;
  z-index: 1;
`;

export default FormWrapper;
