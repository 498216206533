import React from "react";
import Helmet from "react-helmet";
import {
  IntroLayout,
  IntroLayoutMenuOption,
  SLink,
  ProfileContainer,
  BoardLowerMenuContainer,
  MyInfoNavLogoutContainer,
  MyInfoNavLogoutLeftBox,
  MyInfoNavLogoutLeftImg,
  MyInfoNavLogoutLeftContent,
  MyInfoNavLogoutRightBox,
  MyInfoNavLogoutBtn,
  MyPost_IntroDetail as IntroDetail,
  MyPost_MyPostContainer as MyPostContainer,
  MyPost_MyPostHeader as MyPostHeader,
  MyPost_MyPostBox as MyPostBox,
  MyPost_MyPostList as MyPostList,
  MyPost_MyPostColumn as MyPostColumn,
  MyPost_MyPostTitle as MyPostTitle,
  MyPost_MyPostBtn as MyPostBtn,
} from "../../../Components/Layout";
import { withRouter } from "react-router-dom";
import Header from "../../../Components/Header";
import MobileHeader from "../../../Components/MobileHeader";
import Loader from "../../../Components/Loader";
import Error from "../../../Components/Error";
import { Storage } from "aws-amplify";
import TextData from "../../../Json/myPostJson.json";
import {
  getCurrentUserInfo,
  fetchOneUserById,
  fetchAllPostById,
  onSignOutClick,
} from "../../../Controllers/amplifyController";
import "./mypostresponsive.css";
class MyPostPresenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isloading: true,
      errorMsg: "",
    };
  }

  async componentDidMount() {
    let user;
    let profileData;
    let fetchImg;
    let postData;
    let notDeletedData;
    try {
      user = await getCurrentUserInfo();
      profileData = await fetchOneUserById(user.id);
      postData = await fetchAllPostById(user.id);
      if (postData) {
        notDeletedData = postData
          .filter((data) => data._deleted !== true)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      }
      if (
        profileData === null ||
        profileData === undefined ||
        Object.values(profileData).filter((data) => data === "").length > 2
      ) {
        this.setState({ error: true, errorMsg: "프로필을 먼저 완성해주세요!" });
      }
      if (
        (profileData && !profileData.membership) ||
        profileData.membership === null
      ) {
        this.setState({
          error: true,
          errorMsg: "관리자 승인 후 이용 가능합니다.",
        });
      }

      if (profileData) {
        if (profileData.avatar_url) {
          fetchImg = await Storage.get(profileData.avatar_url, {
            contentType: "url",
          });
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        isloading: false,
        user,
        profileData,
        fetchImg,
        notDeletedData,
      });
    }
  }

  render() {
    let mobilebranch;
    const {
      location: { pathname },
      info,
    } = this.props;
    const {
      isloading,
      user,
      error,
      fetchImg,
      errorMsg,
      notDeletedData,
      profileData,
    } = this.state;
    mobilebranch = window.innerWidth;
    if (mobilebranch < 1280) {
      mobilebranch = true;
    } else if (mobilebranch >= 1280) {
      mobilebranch = false;
    }
    return (
      <>
        <Helmet>
          <title>MyPost | FFF</title>
        </Helmet>
        {mobilebranch ? <MobileHeader /> : <Header />}
        <IntroLayout id="mypost_layout">
          {isloading ? (
            <Loader />
          ) : !error &&
            profileData &&
            profileData.membership &&
            profileData.membership !== null ? (
            <>
              <ProfileContainer id="mypost_profileContainer">
                <IntroLayoutMenuOption
                  className="mypost_profileMenuOptions"
                  current={pathname === `/profile/${info}`}
                >
                  <SLink to={`/profile/${info}`}>
                    {TextData.link_to_profile}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="mypost_profileMenuOptions"
                  current={pathname === `/mypost/${info}`}
                >
                  <SLink to={`/mypost/${info}`}>
                    {TextData.link_to_mypost}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="mypost_profileMenuOptions"
                  current={pathname === `/questions/${info}`}
                >
                  <SLink to={`/questions/${info}`}>
                    {TextData.link_to_question}
                  </SLink>
                </IntroLayoutMenuOption>
                <IntroLayoutMenuOption
                  className="mypost_profileMenuOptions"
                  current={pathname === `/myportfolio/${info}`}
                >
                  <SLink to={`/myportfolio/${info}`}>
                    {TextData.link_to_myportfolio}
                  </SLink>
                </IntroLayoutMenuOption>
              </ProfileContainer>
              <IntroDetail>
                <MyPostContainer>
                  <MyPostHeader id="mypost_header">
                    {TextData.MyPost_Header}
                  </MyPostHeader>
                  <MyPostBox>
                    {notDeletedData ? (
                      notDeletedData.map((data, index) => (
                        <MyPostList key={index}>
                          <MyPostColumn className="mypost_column">
                            {data && data.category
                              ? data.category
                              : "조회 불가"}
                          </MyPostColumn>
                          <MyPostTitle className="mypost_title">
                            {data && data.title ? data.title : "조회 불가"}
                          </MyPostTitle>
                          <MyPostBtn className="mypost_detailbtn">
                            <SLink to={`/postdetail/${data.id}`}>
                              {TextData.MyPost_Detail}
                            </SLink>
                          </MyPostBtn>
                        </MyPostList>
                      ))
                    ) : (
                      <></>
                    )}
                  </MyPostBox>
                </MyPostContainer>
              </IntroDetail>
              <BoardLowerMenuContainer id="myinfo_togglemenu">
                <MyInfoNavLogoutContainer>
                  <MyInfoNavLogoutLeftBox>
                    <MyInfoNavLogoutLeftImg
                      avatar_url={fetchImg ? fetchImg : ""}
                    />

                    <MyInfoNavLogoutLeftContent>
                      {user ? user.attributes.name : "아무개"}님
                    </MyInfoNavLogoutLeftContent>
                  </MyInfoNavLogoutLeftBox>
                  <MyInfoNavLogoutRightBox>
                    {/* <MyInfoNavLogoutBtn onClick={onSignOutClick}>
                      {TextData.NeedLogout}
                    </MyInfoNavLogoutBtn> */}
                  </MyInfoNavLogoutRightBox>
                </MyInfoNavLogoutContainer>
              </BoardLowerMenuContainer>
            </>
          ) : (
            <Error error={errorMsg !== "" ? errorMsg : TextData.Access_Deny} />
          )}
        </IntroLayout>
      </>
    );
  }
}

const withRouterMyPost = withRouter(MyPostPresenter);
export default withRouterMyPost;
