import { Link } from "react-router-dom";
import styled from "styled-components";
import { opacity } from "../Animations";
import LogInImg from "../assets/03_community/login_null.png";
import UnauthorizedBg from "../assets/03_community/login_required.png";
import DefaultProfileImg from "../assets/04_myaccount/account_default.png";
import fff_holdImg from "../assets/03_community/like_default.png";
import fff_qnaImg from "../assets/01_fff/reply_waiting.png";
import fff_pressed_like from "../assets/03_community/like_pressed.png";
import AdditonalReply from "../assets/03_community/reply.png";
import Required from "../assets/04_myaccount/required.png";
import ArrowImg from "../assets/03_community/up.png";
import { DownArrow } from "@styled-icons/boxicons-solid/DownArrow";
import backImg from "../assets/02_portfolio/back.png";
import LogOutImg from "../assets/font_logo/logOut.png";
// 공통으로 쓰이는 styled-component Layout
export const SLink = styled(Link)``;

export const SButton = styled.button`
  background-color: #1a1a1a;
  cursor: grab;
`;

export const AdminIntroLayout = styled.div`
  position: absolute;
  top: 100px;
  width: 1200px;
  height: 750px;
  background-color: #1a1a1a;
  margin-left: 330px;
  border: solid 5px #00ee13;
`;
export const AdminIntroLayoutMenuBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  background-color: #1a1a1a;
  width: 450px;
  height: 70px;
  right: -40px;
  top: 20px;
  border: solid 6px #00ee13;
`;
export const AdminIntroLayoutMenuOption = styled.div`
  opacity: ${(props) => (props.current ? "1" : "0.5")};
  font-family: "Noto Sans KR";
  font-weight: 600;
  font-size: 18px;
  &:not(:last-child) {
    margin-right: 70px;
  }

  &:hover {
    animation: ${opacity} 0.4s ease-in-out;
    opacity: 1;
    cursor: grab;
  }
`;
export const IntroLayout = styled.div`
  /* position: absolute; */
  /* top: 100px; */
  /* width: 1200px; */
  /* height: 750px; */
  background-color: #1a1a1a;
  /* margin-left: 330px; */
  border: solid 4px #00ee13;
`;

// 우측 상단 메뉴 박스
export const IntroLayoutMenuBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  /* justify-content:center; */
  justify-content: space-around;
  background-color: #1a1a1a;
  /* width: 450px; */
  /* height: 70px; */
  /* right: -40px; */
  /* top: 20px; */
  border: solid 6px #00ee13;
`;

//좌측 하단 메뉴 박스
export const LIntroLayoutMenuBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #1a1a1a;
  /* justify-content: center; */
  /* width: 270px; */
  /* height: 70px; */
  /* left: 40px; */
  /* top: -30px; */
  border: solid 6px #00ee13;
`;

export const IntroLayoutMenuOption = styled.div`
  opacity: ${(props) => (props.current ? "1" : "0.5")};
  font-family: "Noto Sans KR";
  font-weight: 600;
  /* font-size: 18px; */
  /* &:not(:last-child) {
    margin-right: 70px;
  } */

  &:hover {
    animation: ${opacity} 0.4s ease-in-out;
    opacity: 1;
    cursor: grab;
  }
`;
// 게시판 레이아웃

export const BoardUpperMenuContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
  width: 330px;
  height: 60px;
  right: -20px;
  top: -20px;
  border: solid 6px #00ee13;
`;

export const BoardLowerMenuContainer = styled.div`
  position: absolute;
  /* display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 70px;
  bottom: -20px;
  left: -100px; */
  border: solid 5px #00ee13;
  background-color: #1a1a1a;
`;
export const BoardLowerMenuImg = styled.div`
  /* width: 35px; */
  /* height: 35px; */
  background-image: url(${LogInImg});
  background-size: cover;
  margin-bottom: 3px;
`;
export const BoardLowerMenuText = styled.div`
  /* font-size: 23px; */
  font-family: "Alfredo";
  /* margin-left: 30px; */
  /* margin-top: 18px; */
`;
export const BoardButton = styled.button`
  width: 180px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #00ee13;
  cursor: pointer;
`;

export const BoardUnauthorizedBox = styled.div`
  /* position: absolute; */
  /* width: 5vw; */
  /* height: 5vg; */
  /* top: 80px; */
  /* left: 410px; */
  display: flex;
  flex-direction: column;
`;

export const BoardUnauthorizedBg = styled.div`
  width: 20vw;
  height: 19vw;
  margin: 0 auto;
  background-image: url(${UnauthorizedBg});
  background-size: cover;
`;
export const BoardTextFlexbox = styled.div`
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
  /* width: 320px; */
  /* margin-left: 20px; */
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const BoardUnauthorizedText = styled.div`
  font-family: "Noto Sans KR";
  font-size: 1.4vh;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
  /* vertical-align: bottom; */
  line-height: 2vh;
`;

export const BoardUnauthorizedHighLight = styled.span`
  font-family: "Noto Sans KR";
  font-weight: 600;
  /* font-size: 17px; */
  /* border-bottom: 2px solid #ff22ff;  */
`;

export const ProfileContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #1a1a1a;
  /* width: 1000px; */
  /* height: 70px; */
  /* left: 40px; */
  /* top: -30px; */
  /* border: solid 6px #00ee13; */
`;

export const ErrorBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// 프로필 & 포트폴리오 관리 공통 LayOut
export const IntroDetail = styled.div`
  max-width: 100%;
  max-height: 100%;
  overflow: scroll;
`;

export const MyInfoContainer = styled.div`
  width: 100%;
  /* height: 500px; */
  /* margin-top: 100px; */
  display: flex;
  /* justify-content: center; */
`;

export const MyInfoLeftBox = styled.div`
  /* width: 35%; */
  height: 100%;
  /* margin-top: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MyInfoRightBox = styled.div`
  /* width: 50%; */
  /* height: 100%; */
  /* margin-left: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// 공통 Nav styled
export const MyInfoNavContainer = styled.div`
  /* width: 30%; */
  /* height: 10%; */
  /* margin-top: 15px; */
  display: flex;
  justify-content: space-around;
`;
export const MyInfoNavSaveBtn = styled.button`
  /* width: 70px; */
  /* height: 30px; */
  outline: none;
  border: 2px solid #00ee13;
  background-color: transparent;
  color: #00ee13;
  font-family: "Noto Sans KR";
  font-weight: 400;
  /* font-size: 13px; */
  cursor: grab; ;
`;
export const MyInfoNavNotSaveBtn = styled.button`
  /* width: 70px; */
  /* height: 30px; */
  outline: none;
  border: 2px solid #00ee13;
  background-color: transparent;
  color: #00ee13;
  font-family: "Noto Sans KR";
  font-weight: 400;
  /* font-size: 13px; */
  cursor: grab; ;
`;

// 공통 하단 로그아웃 박스 ( Private LogOut)

export const MyInfoNavLogoutContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MyInfoNavLogoutLeftBox = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const MyInfoNavLogoutLeftImg = styled.div`
  width: 40px;
  height: 40px;
  /* margin-left: 10px; */
  border-radius: 40px;
  background-image: ${(props) =>
    props.avatar_url
      ? `url(${props.avatar_url})`
      : `url(${DefaultProfileImg})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 2px solid #00ee13;
`;
export const MyInfoNavLogoutLeftContent = styled.div`
  width: 90px;
  height: 40px;
  /* margin-left: 10px; */
  font-family: "Noto Sans KR";
  font-weight: 500;
  font-size: 17px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
`;
export const MyInfoNavLogoutRightBox = styled.div`
  width: 40px;
  height: 45px;
  font-family: "Alfredo";
  font-size: 13px;
  margin-top: 2px;
  background-image: url(${LogOutImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: grab;
`;

export const MyInfoNavLogoutBtn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  font-family: "Alfredo";
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: #00ee13;
  cursor: grab;
`;

// 내정보 레이아웃

// 내정보 > 좌측 > 디폴트 이미지 ( 프롭스로 받아서 나중엔 이미지 뿌려주자)

export const MyProfileImg = styled.div`
  /* width: 100%;
  height: 100%;
  background-size: contain; */
  background-image: ${(props) =>
    props.avatar_url
      ? `url(${props.avatar_url})`
      : `url(${DefaultProfileImg})`};
  background-position: center;
  background-repeat: no-repeat;
`;

// 내정보 > 좌측 > 이미지 하단부 > 활동명 입력하기 ( 없으면 닉네임 설정 )

export const ProfileNameUnderImg = styled.div`
  width: 60%;
  /* height: 10%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 22px; */
  /* font-weight: 600; */
  /* margin-top: 10px; */
`;
export const MembershipState = styled.div`
  width: 60%;
  /* height: 10%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  /* font-weight: 400; */
  /* margin-top: 10px; */
  color: #ff02ff;
`;
// 내정보 > 우측 > 리스트와 인풋 그 사이
export const ProfileRightContent = styled.div`
  width: 80%;
  height: 100%;
  /* border-bottom: 3px solid #00ee13; */
  display: flex;
  justify-content: center;
  /* padding-bottom: 50px; */
`;
export const ProfileListContainer = styled.ul`
  /* width: 90%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
`;

export const ProfileList = styled.li`
  width: 100%;
  /* height: 10%; */
  display: flex;
  /* justify-content: space-between; */
`;
export const ProfileListColumn = styled.div`
  /* width: 70px; */
  /* height: 30px; */
  background-color: #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: 700;
  color: #1a1a1a;
`;
export const ProfileListInput = styled.input`
  /* width: 180px; */
  /* height: 30px; */
  background-color: transparent;
  /* border-bottom: 1px solid #00ee13; */
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  /* font-weight: 500; */
  text-align: left;
  color: #00ee13;
  outline: none;
`;
export const ProfileListDisabled = styled.div`
  /* width: 180px; */
  /* height: 30px; */
  /* border-bottom: 1px solid #00ee13; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  /* font-weight: 500; */
`;
export const ProfileListisPublicBox = styled.div`
  width: 35%;
  height: 30px;
  /* display: flex;
  justify-content: space-around; */
`;

// MyPortFolio Left Content

export const MyPortFolioStateBox = styled.div`
  width: 50%;
  height: 5%;
  background-color: transparent;
  margin-top: 20px;
  margin-left: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const MyPortFolioStateColumn = styled.div`
  width: 40%;
  height: 100%;
  border: 1px solid #00ee13;
  color: #00ee13;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MyPortFolioStateInfo = styled.div`
  width: 30%;
  height: 100%;
  background-color: transparent;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 600;
  color: #ff02ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MyPortFolioThumbnailBox = styled.div`
  width: 80%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 70px;
  border: 1px solid #00ee13;
  background-image: ${(props) =>
    props.thumbnail_url ? `url(${props.thumbnail_url})` : ""};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
export const MyPortFolioThumbnailContent = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isuploaded ? "#00ee13" : "transparent")};
`;
export const MyPortFolioThumbnailInfo = styled.div`
  width: 100%;
  height: 5%;
  margin-top: 50px;
  text-align: center;
  font-family: "Noto Sans KR";
  font-size: 11px;
  font-weight: 400;
  color: #00ee13;
  line-height: 1.5rem;
  white-space: pre-line;
`;

//MyPortFolio Right Content

export const MyPortFolioRightBox = styled.div`
  width: 65%;
  height: 100%;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MyPortFolioRightContent = styled.div`
  width: 80%;
  height: 100%;
  border-bottom: 3px solid #00ee13;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
`;

export const MyPortFolioListContainer = styled.ul`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const MyPortFolioList = styled.li`
  width: 100%;
  height: 10%;
  display: flex;
  /* justify-content: space-between; */
`;

export const MyPortFolioListCancel = styled.button`
  width: 35px;
  /* height: 25px; */
  margin-top: 1px;
  background-color: transparent;
  color: #00ee13;
  outline: none;
  font-family: "Noto Sans KR";
  font-size: 10px;
  font-weight: 600;
`;

export const MyPortFolioListColumn = styled.div`
  width: 75px;
  height: 25px;
  background-color: #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 700;
  color: #1a1a1a;
  margin-right: 30px;
`;

export const MyPortFolioFullDescription = styled.input`
  width: 180px;
  height: 30px;
  background-color: transparent;
  border-bottom: 1px solid #00ee13;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #00ee13;
  outline: none;
`;

export const MyPortFolioFullDescriptionTextArea = styled.textarea`
  /* width: 420px; */
  /* height: 80px; */
  background-color: transparent;
  border: 1px solid #00ee13;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #00ee13;
  outline: none;
`;
export const MyPortFolioReadOnlyLimitText = styled.span`
  /* margin-left: 25px; */
`;

export const MyPortFolioSelect = styled.select`
  width: 195px;
  height: 30px;
  background-color: transparent;
  color: #00ee13;
  border: none;
  outline: none;
  appearance: none;
  font-family: "Noto Sans KR";
  /* font-size: 11px; */
  font-weight: 600;
`;
export const MyPortFolioSelectOption = styled.option``;

export const MyPortFolioListInput = styled.input`
  width: 200px;
  height: 30px;
  background-color: transparent;
  border-bottom: 1px solid #00ee13;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #00ee13;
  outline: none;
`;
export const MyPortFolioAttachLabel = styled.label`
  display: flex;
  align-items: center;
`;
export const MyPortFolioAttachContent = styled.div`
  /* width: 70px; */
  /* height: 30px; */
  background-color: transparent;
  border: 2px solid #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 600;
  margin-left: 10px;
`;
export const MyPortFolioAttachInput = styled.input`
  display: none;
`;
export const MyPortFolioAttachSpan = styled.span`
  width: 75px;
  /* height: 30px; */
  margin-left: 10px;
  font-family: "Noto Sans KR";
  font-size: 12px;
  font-weight: 600;
`;

export const MyPortFolioListisPublicBox = styled.div`
  width: 270px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
`;

export const MyPortFolioListTagContent = styled.div`
  /* width: 75px; */
  /* height: 25px; */
  background-color: ${(props) => (props.istagged ? "#00ee13" : "transparent")};
  color: #1a1a1a;
  font-family: "Noto Sans KR";
  /* font-size: 11px; */
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-right: 5px;
  }
  &:first-child {
    margin-left: 10px;
  }
`;

// Purple Circle ( isRequired )

export const IsrequiredPurple = styled.span`
  width: 4px;
  height: 4px;
  background-color: #ff02ff;
  border-radius: 4px;
`;

//isOpen OR isClose < radio >

export const RadioContainer = styled.div`
  width: 100%;
`;
export const RadioForm = styled.form`
  width: 100%;
  height: 100%;
`;
export const RadioSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: "Noto Sans KR";
`;
export const RadioInput = styled.input`
  height: 20px;
  display: none;
`;
export const RadioLabel = styled.label`
  /* width: 100px; */
  /* padding: 5px 12px; */
  background-color: ${(props) => (props.selected ? "#00ee13" : "transparent")};
  outline: none;
  border: 1px solid #00ee13;
  color: ${(props) => (props.selected ? "black" : "#00ee13")};
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 500;
  cursor: grab;
  margin-left: 10px;
`;

//image 클릭시 file upload > MyProfileImg와 연관

export const ImageClickLabel = styled.label``;

export const ImageClickInput = styled.input`
  display: none;
`;

export const ImageClickBox = styled.div`
  /* width: 80%; */
  /* height: 50%; */
`;

//  User Detail

export const UserDetail = styled.div`
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const UserContainer = styled.div`
  width: 100%;
  /* height: 80%; */
  display: flex;
  /* justify-content: space-around;
  align-items: center; */
`;

export const UserLeftBox = styled.div`
  /* width: 30%;
  height: 80%; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const UserRightBox = styled.div`
  /* width: 50%; */
  /* height: 80%; */
`;
export const UserUnderBackBtn = styled.div`
  /* width: 90px; */
  /* height: 40px; */
  /* margin: 0 auto; */
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: 600;
  color: #00ee13;
  border: 1px solid #00ee13;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #00ee13;
    color: #1a1a1a;
    cursor: grab;
  }
`;
export const UserLeftImg = styled.div`
  /* width: 150px; */
  /* height: 150px; */
  /* width: 100%; */
  /* height: 70%; */
  background-image: ${(props) =>
    props.currentImg
      ? `url(${props.currentImg})`
      : `url(${DefaultProfileImg})`};
  /* background-size: contain; */
  /* background-size: ${(props) =>
    props.currentDevice ? "contain" : "center"}; */
  background-repeat: no-repeat;
  background-position: center;
`;
export const UserHeader = styled.div`
  font-family: "Alfredo";
  /* font-size: 26px; */
  font-weight: 600;
  color: #00ee13;
`;
export const UserRightListBox = styled.ul`
  /* width: 90%; */
  height: 100%;
`;
export const UserRightList = styled.li`
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  /* &:not(:last-child) {
    margin-bottom: 20px;
  } */
`;
export const UserRightColumn = styled.div`
  /* width: 90px; */
  /* height: 40px; */
  /* margin: 0 auto; */
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: 600;
  border: 1px solid #00ee13;
  background-color: transparent;
  color: #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const UserRightInput = styled.div`
  /* width: 60%; */
  /* height: 40px; */
  color: ${(props) => (props.activated ? "#00ee13" : "#ff02ff")};
  outline: none;
  background-color: transparent;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  /* font-weight: 600; */
  opacity: 1;
  /* overflow-x: scroll; */
  /* white-space: nowrap; */
`;
export const UserGetHomePage = styled.div`
  /* width: 60%; */
  /* height: 40px; */
  color: ${(props) => (props.activated ? "#00ee13" : "#ff02ff")};
  /* border-bottom: ${(props) =>
    props.activated ? "1px dashed #ff02ff" : "none"}; */
  outline: none;
  background-color: transparent;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const UserGetHomePageSpan = styled.span`
  border-bottom: ${(props) =>
    props.activated ? "1px dashed #ff02ff" : "none"};
  outline: none;
  background-color: transparent;
  font-family: "Noto Sans KR";
  /* font-weight: 600; */
  display: flex;
  align-items: center;
`;

export const FlexBox = styled.div`
  /* width: 100%;
  margin-top: 20px; */
  display: flex;
  /* justify-content: center; */
`;
export const PortFolioInfo = styled.div`
  /* width: 90px; */
  /* height: 40px; */
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  /* font-weight: 600; */
  border: 1px solid #00ee13;
  background-color: transparent;
  color: #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PortFolioState = styled.div`
  /* width: 20%; */
  /* height: 40px; */
  color: #ff02ff;
  outline: none;
  background-color: transparent;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: 600;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* margin-left: 20px; */
`;

//UpdatePost
export const UP_POST_IntroDetail = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const UP_POST_UploadContainer = styled.div`
  /* width: 90%; */
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const UP_POST_UploadBox = styled.div`
  /* width: 80%; */
  /* height: 70%; */
`;

export const UP_POST_UploadColumn = styled.div`
  /* width: 60px; */
  /* height: 20px; */
  color: #1a1a1a;
  background-color: #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: bold;
  display: ${(props) => (props.freecategory ? "none" : "flex")};
  justify-content: center;
  align-items: center;
`;

export const UP_POST_UploadListContainer = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const UP_POST_UploadList = styled.li`
  width: 100%;
  /* height: 10%; */
  display: ${(props) => (props.freecategory ? "none" : "flex")};
  justify-content: flex-start;
`;

export const UP_POST_UploadTitle = styled.input`
  /* width: 80%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px dashed #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  /* margin-left: 20px; */
`;
export const UP_POST_UploadWriter = styled.div`
  /* width: 20%; */
  /* height: 20px; */
  background-color: transparent;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* margin: 0px 15px; */
`;
export const UP_POST_UploadWriterContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
export const UP_POST_UploadWriterImg = styled.div`
  /* width: 20px; */
  /* height: 20px; */
  /* border-radius: 20px; */
  background-image: ${(props) =>
    props.fetchImg ? `url(${props.fetchImg})` : `url(${DefaultProfileImg})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
export const UP_POST_UploadWriterNickName = styled.div`
  /* width: 80%; */
  /* margin-left: 10px; */
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  color: #00ee13;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const UP_POST_UploadLink = styled.input`
  /* width: 20%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px dashed #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* margin: 0px 15px; */
  border-radius: 0;
  -webkit-appearance: none;
  /* text-align: center; */
`;

export const UP_POST_UploadField = styled.input`
  /* width: 30%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px dashed #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  /* margin: 0px 15px; */
  display: ${(props) => (props.freecategory ? "none" : "")};
`;

export const UP_POST_UploadCost = styled.input`
  /* width: 30%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px dashed #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  /* margin: 0px 15px; */
  display: ${(props) => (props.freecategory ? "none" : "")};
`;
export const UP_POST_UploadDescription = styled.textarea`
  /* width: 80%; */
  /* height: 300px; */
  background-color: transparent;
  border: none;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  resize: none;
  /* margin-left: 20px; */
`;
export const UP_POST_UploadFile = styled.input`
  display: none;
`;
export const UP_POST_FileBox = styled.div`
  /* width: 30%; */
  height: 20px;
`;

export const UP_POST_UploadAttachLabel = styled.label`
  display: flex;
`;
export const UP_POST_UploadAttachContent = styled.div`
  /* width: 60px; */
  /* height: 20px; */
  color: #00ee13;
  background-color: transparent;
  border: 2px solid #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: bold;
`;
export const UP_POST_UploadAttachSpan = styled.span`
  /* width: 100px; */
  /* height: 20px; */
  /* margin-top: 5px; */
  /* margin-left: 15px; */
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: 300;
`;

export const UP_POST_FlexBox = styled.div`
  /* width: 30%; */
  display: flex;
  justify-content: flex-start;
`;
export const UP_POST_BackBtn = styled.div`
  /* width: 100px; */
  /* height: 40px; */
  /* margin-top: 20px; */
  color: #00ee13;
  background-color: transparent;
  border: 2px solid #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #00ee13;
    color: #1a1a1a;
    cursor: grab;
    font-weight: bold;
  }
`;
export const UP_POST_ErrMsg = styled.div`
  width: 100%;
  background-color: transparent;
  color: #ff02ff;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
`;
export const UP_POST_CategoryCircle = styled.span`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #ff02ff;
  /* margin-left: 20px; */
`;

// QuestionDetail

export const Q_Detail_IntroDetail = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const Q_Detail_MyQuestionContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Q_Detail_MyQuestionBox = styled.div`
  width: 100%;
  height: 35%;
  margin-left: 30px;
`;
export const Q_Detail_QuestionHeader = styled.div`
  width: 100%;
  height: 30%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-family: "Alfredo";
  font-size: 28px;
  font-weight: 600;
  color: #ff02ff;
  background-color: transparent;
`;
export const Q_Detail_MyQuestionTitle = styled.textarea`
  width: 50%;
  height: 15%;
  background-color: transparent;
  border: 1px solid #ff02ff;
  outline: none;
  margin-bottom: 10px;
  color: gray;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 400;
`;
export const Q_Detail_MyQuestionDescription = styled.textarea`
  width: 80%;
  height: 50%;
  margin-bottom: 10px;
  background-color: transparent;
  border: 1px solid #ff02ff;
  outline: none;
  color: gray;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 500;
`;
export const Q_Detail_AnswerSubmitBtn = styled.div`
  width: 100px;
  height: 35px;
  background-color: transparent;
  border: 2px solid #00ee13;
  margin: 0 auto;
  color: #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 600;
  cursor: grab;
  &:hover {
    color: #1a1a1a;
    background-color: #00ee13;
  }
`;

export const Q_Detail_MyAnswerBox = styled.div`
  width: 100%;
  height: 35%;
  margin-top: 80px;
  margin-left: 30px;
`;
export const Q_Detail_AnswerHeader = styled.div`
  width: 100%;
  height: 30%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-family: "Alfredo";
  font-size: 28px;
  font-weight: 600;
  background-color: transparent;
  color: #00ee13;
`;

export const Q_Detail_MyAnswerTitle = styled.textarea`
  width: 50%;
  height: 15%;
  background-color: transparent;
  border: 1px solid #00ee13;
  outline: none;
  margin-bottom: 10px;
  color: gray;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 400;
`;
export const Q_Detail_MyAnswerDescription = styled.textarea`
  width: 80%;
  height: 50%;
  margin-bottom: 10px;
  background-color: transparent;
  border: 1px solid #00ee13;
  outline: none;
  color: gray;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 500;
`;
export const Q_Detail_AnswerBtnBox = styled.div`
  width: 70%;
  display: flex;
`;

export const Q_Detail_ClientInfoBox = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
`;

export const Q_Detail_ClientColumn = styled.div`
  width: 50px;
  height: 30px;
  background-color: #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 12px;
  font-weight: 500;
  color: #1a1a1a;
  margin: 0px 20px;
`;

export const Q_Detail_ClientContent = styled.div`
  font-family: "Noto Sans KR";
  font-size: 12px;
  font-weight: 500;
  color: #00ee13;
`;

// My Questions
export const My_Q_IntroDetail = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const My_Q_MyQuestionContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const My_Q_MyQuestionBox = styled.div`
  width: 100%;
  height: 35%;
  /* margin-top: 60px; */
  /* margin-left: 30px; */
`;
export const My_Q_QuestionHeader = styled.div`
  width: 100%;
  height: 30%;
  /* margin-bottom: 10px; */
  display: flex;
  align-items: center;
  font-family: "Alfredo";
  /* font-size: 28px; */
  font-weight: 600;
  color: #00ee13;
  background-color: transparent;
`;
export const My_Q_MyQuestionTitle = styled.textarea`
  width: 50%;
  /* height: 15%; */
  background-color: transparent;
  border: 1px solid #00ee13;
  outline: none;
  /* margin-bottom: 10px; */
  color: #00ee13;
  font-family: "Noto Sans KR";
  white-space: nowrap;
  resize: none;
  /* font-size: 16px; */
  font-weight: 400;
  border-radius: 0;
  -webkit-appearance: none;
`;
export const My_Q_MyQuestionDescription = styled.textarea`
  width: 80%;
  /* height: 50%; */
  /* margin-bottom: 10px; */
  background-color: transparent;
  border: 1px solid #00ee13;
  outline: none;
  resize: none;
  color: #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: 500;
  border-radius: 0;
  -webkit-appearance: none;
`;
export const My_Q_QuestionSubmitBtn = styled.div`
  /* width: 100px; */
  /* height: 35px; */
  background-color: transparent;
  border: 2px solid #00ee13;
  color: #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: 500;
  cursor: grab;
  &:hover {
    color: #1a1a1a;
    background-color: #00ee13;
  }
`;

export const My_Q_MyAnswerBox = styled.div`
  width: 100%;
  height: 35%;
  /* margin-top: 80px; */
  /* margin-left: 30px; */
`;
export const My_Q_AnswerHeader = styled.div`
  width: 100%;
  height: 30%;
  /* margin-bottom: 10px; */
  display: flex;
  align-items: center;
  font-family: "Alfredo";
  /* font-size: 28px; */
  font-weight: 600;
  background-color: transparent;
  color: #00ee13;
`;
export const My_Q_MyAnswerTitle = styled.textarea`
  width: 50%;
  /* height: 15%; */
  background-color: transparent;
  border: 1px solid #00ee13;
  outline: none;
  /* margin-bottom: 10px; */
  color: #00ee13;
  font-family: "Noto Sans KR";
  resize: none;
  /* font-size: 16px; */
  font-weight: 400;
`;
export const My_Q_SubmitCon = styled.div`
  width: 80%;
  height: 10%;
  display: flex;
  justify-content: center;
`;

export const My_Q_MyAnswerDescription = styled.textarea`
  width: 80%;
  /* height: 50%; */
  /* margin-bottom: 10px; */
  background-color: transparent;
  border: 1px solid #00ee13;
  outline: none;
  resize: none;
  color: #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: 500;
  overflow: scroll;
`;
export const My_Q_HoldingBox = styled.div`
  /* width: 50%; */
  /* height: 60%; */
  background-image: url(${fff_qnaImg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* margin: 0 auto; */
`;
export const My_Q_HoldingText = styled.div`
  /* width: 50%; */
  /* height: 10%; */
  /* margin: 0 auto; */
  text-align: center;
  font-family: "Noto Sans KR";
  /* font-size: 16px; */
  font-weight: 500;
  color: #00ee13;
  /* margin-top: 15px; */
  white-space: pre-line;
  line-height: 1.5rem;
`;

// My posts

export const MyPost_IntroDetail = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const MyPost_MyPostContainer = styled.div`
  width: 90%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
export const MyPost_MyPostHeader = styled.div`
  width: 100%;
  height: 10%;
  /* margin-top: 50px; */
  font-family: "Alfredo";
  /* font-size: 28px; */
  font-weight: 600;
  color: #00ee13;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const MyPost_MyPostBox = styled.ul`
  width: 100%;
  height: 80%;
  overflow: scroll;
`;
export const MyPost_MyPostList = styled.li`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #00ee13;
  &:first-child {
    border-top: 1px solid #00ee13;
  }
`;
export const MyPost_MyPostColumn = styled.div`
  /* width: 10%; */
  /* height: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: 600;
  color: #00ee13;
  background-color: transparent;
  border: 1px solid #00ee13;
`;
export const MyPost_MyPostTitle = styled.div`
  /* width: 70%; */
  /* height: 30px; */
  display: flex;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: 600;
  color: #00ee13;
  white-space: nowrap;
  overflow-x: scroll;
  background-color: transparent;
`;
export const MyPost_MyPostBtn = styled.div`
  /* width: 10%; */
  /* height: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: 600;
  color: #00ee13;
  background-color: transparent;
  border: 1px solid #00ee13;
  &:hover {
    background-color: #00ee13;
    color: #1a1a1a;
    cursor: grab;
  }
`;

//Post Detail

export const Post_Detail_IntroDetail = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const Post_Detail_UploadContainer = styled.div`
  /* width: 90%; */
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Post_Detail_UploadBox = styled.div`
  /* width: 90%; */
  height: 100%;
`;

export const Post_Detail_UploadColumn = styled.div`
  /* width: 60px; */
  /* height: 20px; */
  color: #1a1a1a;
  background-color: #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.freecategory ? "none" : "flex")};
`;

export const Post_Detail_UploadListContainer = styled.ul`
  width: 100%;
  /* height: 55%; */
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
`;

export const Post_Detail_IsLikedContainer = styled.div`
  /* width: 100%; */
  /* height: 7%; */
  display: flex;
  justify-content: flex-end;
  /* margin-bottom: 10px; */
`;
export const Post_Detail_IsLikedBox = styled.div`
  /* width: 10%; */
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Post_Detail_IsLikedCount = styled.div`
  /* width: 20%; */
  /* height: 100%; */
  color: #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Post_Detail_IsLikedImg = styled.div`
  /* width: 60%; */
  /* height: 100%; */
  background-image: ${(props) =>
    props.isliked ? `url(${fff_pressed_like})` : `url(${fff_holdImg})`};
  background-repeat: no-repeat;
  background-size: contain;
`;

export const Post_Detail_CommentListContainer = styled.ul`
  /* width: 100%; */
  /* height: 20%; */
  /* padding-top: 10px; */
  display: flex;
  border-bottom: 1px solid #00ee13;
  border-top: 1px solid #00ee13;
  flex-direction: column;
  overflow: scroll;
`;
export const Post_Detail_CommentList = styled.li`
  width: 100%;
  /* height: 50px; */
  /* margin-bottom: 5px; */
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
`;
export const Post_Detail_CommentContent = styled.div`
  /* width: 50%; */
  /* height: 80%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 11px; */
  font-weight: 300;
  color: #00ee13;
  /* white-space: nowrap; */
  /* overflow-x: scroll; */
  /* margin-left: ${(props) => (props.current ? "40px" : "")}; */
`;
export const Post_Detail_CommentCreatedAt = styled.div`
  /* width: ${(props) => (props.current ? "8%" : "15%")}; */
  /* height: 80%; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Noto Sans KR";
  /* font-size: 11px; */
  font-weight: 300;
  overflow-x: scroll;
  white-space: nowrap;
  color: #00ee13;
  /* margin-left: ${(props) => (props.current ? "40px" : "")}; */
  /* margin-right: ${(props) => (props.current ? "" : "20px")}; */
`;

export const Post_Detail_CommentWriteContainer = styled.div`
  /* width: 100%; */
  /* height: 5%; */
  /* padding-top: 5px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Post_Detail_CommentWriterProfile = styled.div`
  /* width: 85px; */
  /* height: 80%; */
  /* font-size: 11px; */
  background-color: transparent;
  color: #00ee13;
  font-family: "Noto Sans KR";
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* overflow-x: scroll; */
  /* white-space: nowrap; */
`;
export const Post_Detail_CommentWriteInput = styled.input`
  /* width: 80%; */
  /* height: 80%; */
  background-color: transparent;
  border-bottom: 1px dashed #00ee13;
  border-radius: 0;
  -webkit-appearance: none;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 500;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* margin: 0px 15px; */
`;
export const Post_Detail_CommentWriteBtn = styled.div`
  /* width: 7%; */
  /* height: 80%; */
  color: #1a1a1a;
  background-color: #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
`;

export const Post_Detail_UploadList = styled.li`
  /* width: 100%; */
  /* height: 10%; */
  display: ${(props) => (props.freecategory ? "none" : "flex")};
  margin-top: 20px;
  justify-content: flex-start;
`;

export const Post_Detail_UploadTitle = styled.div`
  /* width: 45%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px solid #00ee13;
  border-spacing: 2px 3px;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 900;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* margin: 0px 15px; */
  /* white-space: nowrap;
  overflow-x: scroll; */
`;
export const Post_Detail_UploadWriterImg = styled.div`
  /* width: 18px; */
  /* height: 18px; */
  /* margin-right: 10px; */
  /* border-radius: 20px; */
  background-image: ${(props) =>
    props.current ? `url(${props.current})` : `url(${DefaultProfileImg})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
export const Post_Detail_UploadWriter = styled.div`
  /* width: 20%; */
  /* height: 20px; */
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  /* font-weight: bold; */
  text-align: left;
  color: #00ee13;
  border-bottom: 1px solid #00ee13;
  outline: none;
  white-space: nowrap;
  overflow-x: scroll;
  /* margin: 0px 15px; */
`;

export const Post_Detail_UploadTime = styled.div`
  width: 150px;
  height: 29px;
  background-color: transparent;
  border-bottom: 1px solid #00ee13;
  font-family: "Noto Sans KR";
  font-size: 12px;
  margin: 0 5px;
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* margin: 0px 15px; */
  /* overflow-x: scroll; */
  white-space: nowrap;
  /* text-align: center; */
`;
export const Post_Detail_UploadLink = styled.div`
  /* width: 20%; */
  /* height: 20px; */
  background-color: transparent;
  font-family: "Noto Sans KR";
  display: flex;
  align-items: center;
  /* font-size: 13px; */
  font-weight: 300;
  color: #00ee13;
  outline: none;
  /* overflow-x: scroll;
  white-space: nowrap; */
  line-height: 1.1rem;
  border: ${(props) => (props.current ? "" : "none")};
  width: ${(props) => (props.freecategory ? "auto" : "")};
  /* margin: 0px 15px; */
`;

export const Post_Detail_UploadField = styled.div`
  /* width: 30%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px solid #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* overflow-x: scroll;
  white-space: nowrap; 
  /* margin: 0px 15px; */
  display: ${(props) => (props.freecategory ? "none" : "")};
`;

export const Post_Detail_UploadCost = styled.div`
  /* width: 20%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px solid #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* overflow-x: scroll;
  white-space: nowrap; */
  /* margin: 0px 15px; */
  display: ${(props) => (props.freecategory ? "none" : "")};
`;
export const Post_Detail_UploadDescription = styled.div`
  /* width: 80%; */
  /* height: 200px; */
  background-color: transparent;
  /* border: none; */
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  resize: none;
  /* margin-left: 15px; */
  /* 여기 html으로 뿌리긴 해야겠다 */
  white-space: pre-line;
  /* overflow: scroll; */
  /* line-height: 1.3rem; */
`;
export const Post_Detail_UploadImgView = styled.div`
  /* width: 300px; */
  /* height: 200px; */
  /* margin: 0 auto; */
  background-image: ${(props) =>
    props.current ? `url(${props.current})` : ""};
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Post_Detail_UploadAttachSpan = styled.span`
  /* width: 100px; */
  /* height: 20px; */
  /* margin-top: 5px; */
  /* margin-left: 10px; */
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: 300;
`;

export const Post_Detail_FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Post_Detail_BackBtn = styled.div`
  /* width: 100px; */
  /* height: 40px; */
  /* margin-top: 20px; */
  color: #00ee13;
  background-color: transparent;
  /* border: 2px solid #00ee13; */
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #00ee13;
    color: #1a1a1a;
    cursor: grab;
  }
`;

export const Post_Detail_AdditonalCommentBtn = styled.div`
  /* width: 5%; */
  /* height: 80%; */
  /* font-size: 12px; */
  color: #00ee13;
  font-family: "Noto Sans KR";
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  /* margin-left: ${(props) => (props.current ? "45px" : "")}; */
`;
export const Post_Detail_RenewalBtn = styled.div`
  /* width: 10px; */
  /* height: 80%; */
  color: #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 10px; */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: ${(props) => (props.additional ? "" : "20px")}; */
  /* margin-left: ${(props) => (props.additional ? "70px" : "40px")}; */
  cursor: grab;
`;
export const Post_Detail_AdditionalCommentContent = styled.div`
  /* width: 45%; */
  /* height: 100%; */
  /* font-size: 11px; */
  color: #00ee13;
  font-family: "Noto Sans KR";
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* white-space: nowrap; */
  /* overflow-x: scroll; */
`;

export const Post_Detail_AdditionalCommentImg = styled.div`
  /* width: 3%; */
  /* height: 40%; */
  /* margin-left: 30px; */
  background-image: url(${AdditonalReply});
  /* margin-right: 3px; */
  background-size: contain;
  background-repeat: no-repeat;
`;
export const Post_Detail_AdditionalCommentList = styled.li`
  /* width: 90%; */
  /* height: 40px; */
  /* margin: 0 auto; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const Post_Detail_AdditionalCommentCreatedAt = styled.div`
  /* width: 8%; */
  /* height: 100%; */
  background-color: transparent;
  color: #00ee13;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Noto Sans KR";
  overflow-x: scroll;
  white-space: nowrap;
  /* font-size: 11px; */
  font-weight: 300;
  /* margin-left: 100px; */
`;

// Post( Create Post )

export const Post_IntroDetail = styled.div`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const Post_UploadContainer = styled.div`
  /* width: 90%; */
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Post_UploadBox = styled.div`
  /* width: 80%; */
  /* height: 70%; */
`;

export const Post_UploadColumn = styled.div`
  /* width: 60px; */
  /* height: 20px; */
  color: #1a1a1a;
  background-color: #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: bold;
  display: ${(props) => (props.freecategory ? "none" : "flex")};
  justify-content: center;
  align-items: center;
`;

export const Post_UploadListContainer = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Post_UploadList = styled.li`
  width: 100%;
  /* height: 10%; */
  display: flex;
  justify-content: flex-start;
`;

export const Post_SelectorOptions = styled.option``;

export const Post_UploadCategory = styled.select`
  /* width: 10%; */
  /* height: 20px; */
  background-color: transparent;
  color: #00ee13;
  border: none;
  outline: none;
  appearance: none;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  /* margin: 0px 15px; */
`;

export const Post_UploadTitle = styled.input`
  /* width: 80%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px dashed #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  /* margin-left: 20px; */
`;
export const Post_UploadWriter = styled.div`
  /* width: 20%; */
  /* height: 20px; */
  background-color: transparent;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* margin: 0px 15px; */
`;
export const Post_UploadWriterContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  /* overflow-x: scroll;
  white-space: nowrap; */
`;
export const Post_UploadWriterImg = styled.div`
  /* width: 20px; */
  /* height: 20px; */
  /* border-radius: 20px; */
  background-image: ${(props) =>
    props.fetchImg ? `url(${props.fetchImg})` : `url(${DefaultProfileImg})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
export const Post_UploadWriterNickName = styled.div`
  /* width: 80%; */
  /* margin-left: 10px; */
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  color: #00ee13;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Post_UploadLink = styled.input`
  /* width: 20%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px dashed #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* margin: 0px 15px; */
  /* text-align: center; */
  border-radius: 0;
  -webkit-appearance: none;
`;

export const Post_UploadField = styled.input`
  /* width: 30%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px dashed #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  /* margin: 0px 15px; */
  border-radius: 0;
  -webkit-appearance: none;
  display: ${(props) => (props.freecategory ? "none" : "")};
`;

export const Post_UploadCost = styled.input`
  /* width: 30%; */
  /* height: 20px; */
  background-color: transparent;
  border-bottom: 1px dashed #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
  /* margin: 0px 15px; */
  display: ${(props) => (props.freecategory ? "none" : "")};
`;
export const Post_UploadDescription = styled.textarea`
  /* width: 80%; */
  /* height: 300px; */
  background-color: transparent;
  border: none;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  text-align: left;
  color: #00ee13;
  outline: none;
  resize: none;
  /* margin-left: 20px; */
`;
export const Post_UploadFile = styled.input`
  display: none;
`;
export const Post_FileBox = styled.div`
  /* width: 30%; */
  height: 20px; ;
`;

export const Post_UploadAttachLabel = styled.label`
  display: flex;
`;
export const Post_UploadAttachContent = styled.div`
  /* width: 60px; */
  /* height: 20px; */
  background-color: transparent;
  border: 2px solid #00ee13;
  display: flex;
  color: #00ee13;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: bold;
`;
export const Post_UploadAttachSpan = styled.span`
  /* width: 100px; */
  /* height: 20px; */
  /* margin-top: 5px; */
  /* margin-left: 15px; */
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: 300;
`;

export const Post_FlexBox = styled.div`
  /* width: 30%; */
  display: flex;
  justify-content: flex-start;
`;
export const Post_BackBtn = styled.div`
  /* width: 100px; */
  /* height: 40px; */
  /* margin-top: 20px; */
  color: #00ee13;
  background-color: transparent;
  border: 2px solid #00ee13;
  font-family: "Noto Sans KR";
  /* font-size: 14px; */
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #00ee13;
    color: #1a1a1a;
    cursor: grab;
    font-weight: bold;
  }
`;
export const Post_ErrMsg = styled.div`
  width: 100%;
  background-color: transparent;
  color: #ff02ff;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
`;
export const Post_CategoryCircle = styled.span`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #ff02ff;
  margin-left: 20px;
`;

//portFolio Detail
export const IntroVerticalLayout = styled.div`
  position: relative;
  /* top: 100px; */
  width: 75vw;
  max-height: 80vh;
  background-color: #1a1a1a;
  margin: 0 auto;
  overflow: scroll;
  border: solid 5px #00ee13;
`;
export const PF_Detail_Container = styled.div`
  width: 95%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 5vh;
`;
export const PF_Detail_Header_Box = styled.div`
  width: 100%;
  /* height: 35vh; */
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
`;

export const PF_Detail_Header_Left = styled.div`
  /* width: 50%; */
  /* height: 80%; */
  background-image: ${(props) =>
    props.thumbnail_url ? `url(${props.thumbnail_url})` : ""};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const PF_Detail_Header_Right = styled.div`
  display: flex;
  flex-direction: column;

  /* justify-content: space-between; */
`;

export const PF_Detail_Header_Right_Tag_Box = styled.div`
  width: 100%;
  /* height: 25%; */
  display: flex;
`;
export const PF_Detail_Header_Right_Tags = styled.div`
  /* width: 30%; */
  /* height: 100%; */
  /* border: ${(props) => (props.current ? " 1px solid #00ee13" : "none")}; */
  color: ${(props) => (props.current ? "#00ee13" : "transparent")};
  background-color: transparent;
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
`;

export const PF_Detail_Header_Right_Tags_Span = styled.span`
  /* border: ${(props) => (props.current ? " 1px solid #00ee13" : "none")}; */
  color: ${(props) => (props.current ? "#1a1a1a" : "transparent")};
  background-color: ${(props) => (props.current ? "#00ee13" : "transparent")};
  /* padding: 3px; */
`;

export const PF_Detail_Header_Right_CompanyName = styled.div`
  width: 100%;
  /* height: 15%; */
  display: flex;
  align-items: center;
  color: #00ee13;
  font-family: "Noto Sans KR";
  overflow-x: scroll;
  white-space: nowrap;
`;
export const PF_Detail_Header_Right_OneLineDes = styled.div`
  width: 100%;
  /* height: 15%; */
  display: flex;
  align-items: center;
  color: #00ee13;
  font-family: "Noto Sans KR";
  overflow-x: scroll;
  white-space: nowrap;
`;
export const PF_Detail_Header_Right_Web = styled.div`
  width: 100%;
  /* height: 15%; */
  /* border-bottom: 1px dashed #ff02ff; */
  display: flex;
  align-items: center;
  color: #00ee13;
  font-family: "Noto Sans KR";
  overflow-x: scroll;
  white-space: nowrap;
`;

export const PF_Detail_Web_Span = styled.span`
  border-bottom: 1px dashed #ff02ff;
`;

export const PF_Detail_Header_Right_Email = styled.div`
  width: 100%;
  /* height: 15%; */
  display: flex;
  align-items: center;
  color: #00ee13;
  font-family: "Noto Sans KR";
  overflow-x: scroll;
  white-space: nowrap;
`;
export const PF_Detail_Header_Right_Business = styled.div`
  width: 100%;
  /* height: 15%; */
  display: flex;
  align-items: center;
  color: #00ee13;
  font-family: "Noto Sans KR";
  overflow-x: scroll;
  white-space: nowrap;
`;

export const PF_Detail_Contents_Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-left: 10px; */
`;

export const PF_Detail_Contents_Box_Header = styled.div`
  /* 반응형 설계 */
  /* width: 20%;
  height: 5vh; */
  /* background-color: #00ee13; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-bottom: 20px; */
  color: #1a1a1a;
`;
export const PF_Detail_Contents_Box_Content = styled.div`
  width: 85%;
  height: 45vh;
  margin-bottom: 20px;
  background-image: ${(props) =>
    props.current ? `url(${props.current})` : ""};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const PF_Detail_Contents_Box_FullDes = styled.div`
  /* width: 85%; */
  /* height: 30vh; */
  margin-bottom: 20px;
  color: #00ee13;
  overflow: scroll;
  font-family: "Noto Sans KR";
`;

export const PF_Detail_Nav_Box = styled.div`
  font-weight: bold;
  /* width: 85%; */
  /* display: flex; */
  /* justify-content: flex-end; */
`;

export const PF_Detail_Nav_Btn = styled.div`
  color: #00ee13;

  /* width: 10%; */
  /* height: 10vh; */
  /* background-image: url(${backImg}); */
  /* background-size: contain; */
  /* background-repeat: no-repeat; */
  cursor: grab;
  /* position: fixed; */
  /* bottom: 200px; */
  /* right: 200px; */
`;

export const PortFolioDetailHeader = styled.div`
  width: 80%;
  font-family: "Alfredo";
  font-weight: 600;
`;

// portfolioPresenter (list view)

export const PF_PortfolioMenuContents = styled.div`
  font-family: "Noto Sans KR";
  font-weight: 600;
  /* font-size: 18px; */
  opacity: ${(props) => (props.current ? "1" : "0.5")};

  /* &:not(:last-child) {
    margin-right: 70px;
  } */

  &:hover {
    animation: ${opacity} 0.4s ease-in-out;
    opacity: 1;
  }
  cursor: grab;
`;

export const PF_PortfolioSearchDropBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* background-color: #1a1a1a; */
  /* width: 160px; */
  /* height: 400px; */
  /* right: -30px; */
  /* bottom: 200px; */
  /* border: solid 6px #00ee13; */
`;

export const PF_PortfolioSearchDropText = styled.span`
  font-family: "Alfredo";
  /* font-size: 20px; */
  /* margin-left: 20px; */
  /* &:first-child { */
  /* margin-top: 15px; */
  /* } */
`;
export const PF_OverFlowBox = styled.div`
  /* height: 150px; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: space-around;
`;
export const PF_TagForm = styled.form``;
export const PF_TagRadioSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PF_TagRadioLabel = styled.label`
  /* width: 100px; */
  /* height: 30px; */
  /* margin-bottom: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Noto Sans KR";
  font-weight: 600;
  /* font-size: 13px; */
  cursor: grab;
  background-color: ${(props) => (props.selected ? "#00ee13" : "transparent")};
  color: ${(props) => (props.selected ? "black" : "#00ee13")};
  /* &:not(:first-child) {
    margin-top: 3px;
  } */
`;

export const PF_PortfolioSearchInput = styled.input`
  /* width: 60%; */
  /* height: 30px; */
  border: solid 5px #00ee13;
  background-color: #1a1a1a;
  color: #00ee13;
  outline: none;
  /* margin-bottom: 20px; */
  /* margin-left: 20px; */
`;
export const PF_PortfolioDetail = styled.div`
  /* width: 1050px; */
  /* height: 80%; */
`;
export const PF_PortfolioInnerContainer = styled.div`
  width: 90%;
  /* margin-left: 50px; */
  height: 100%;
  overflow: scroll;
  /* margin-top: 60px; */
`;
export const PF_GreedBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
`;
export const PF_BoxLayout = styled.div`
  /* width: 400px; */
  /* height: 330px; */
  /* &:not(:nth-child(0)) {
    margin-top: 50px;
  } */
`;

export const PF_TagBox = styled.div`
  /* width: 80%; */
  /* height: 8%; */
  display: flex;
  background-color: transparent;
`;

export const PF_TagContent = styled.div`
  width: auto;
  height: 100%;
  /* background-color: ${(props) =>
    props.contents ? "#00ee13" : "transparent"}; */
  color: ${(props) => (props.contents ? "black" : "transparent")};
  font-family: "Noto Sans KR";
  font-weight: 600;
  /* font-size: 13px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:not(:first-child) {
    margin-left: 10px;
  }
`;
export const PF_TagSpan = styled.span`
  background-color: ${(props) => (props.contents ? "#00ee13" : "transparent")};
  padding: 3px;
`;

export const PF_ThumbnailBox = styled.div`
  /* width: 100%; */
  /* height: 65%; */
  /* margin-top: 15px; */
`;
export const PF_ThumbnailContent = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props) =>
    props.thumbnail ? `url(${props.thumbnail})` : ""};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const PF_PortfolioDescriptionContainer = styled.div`
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
`;

export const PF_PortfolioCompanyBox = styled.div`
  width: 100%;
  height: 40%;
  /* margin-top: 15px; */
`;
export const PF_PortfolioCompanyContent = styled.div`
  width: 100%;
  font-family: "Noto Sans KR";
  /* font-size: 16px; */
  /* font-weight: 600; */
  background-color: transparent;
  /* margin-top: 5px; */
`;
export const PF_PortfolioOneDescriptionBox = styled.div`
  width: 100%;
  height: 35%;
`;
export const PF_PortfolioOneDescriptionContent = styled.div`
  width: 100%;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  /* font-weight: 400; */
`;

// JBoard (Community)

export const Board_JBoardDropBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #1a1a1a;
  width: 160px;
  height: 300px;
  right: -30px;
  bottom: 250px;
  border: solid 6px #00ee13;
`;
export const Board_JBoardDropBoxText = styled.span`
  font-family: "Alfredo";
  font-size: 17px;
  margin-left: 20px;
  margin-top: 10px;
`;
export const Board_OverFlowBox = styled.div`
  height: 100px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: space-around;
`;

export const Board_JBoardDropBoxContent = styled.div`
  font-family: "Noto Sans KR";
  font-weight: 600;
  font-size: 12px;
  margin-left: 30px;
  width: 50px;
  text-align: center;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &:nth-child(1) {
    background-color: #00ee13;
    padding: 5px 2px;
    color: #1a1a1a;
  }
`;

export const Board_JBoardDropBoxInput = styled.input`
  width: 60%;
  height: 30px;
  border: solid 5px #00ee13;
  background-color: #1a1a1a;
  color: #00ee13;
  outline: none;
  margin-bottom: 20px;
  margin-left: 20px;
`;

export const Board_JBoardSearchSelector = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
`;
export const Board_JBoardSearchOption = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-left: 10px;
`;
export const Board_IntroNavIcon = styled(DownArrow)`
  width: 8px;
  height: 8px;
  margin-left: 10px;
  margin-top: 2px;
  color: #00ee13;
`;

export const Board_BoardDetail = styled.div`
  width: 80%;
  height: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

export const Board_BoardContainer = styled.div`
  width: 90%;
  height: 90%;
  margin: 0 auto;
  border-top: 1px solid #00ee13;
`;
export const Board_PostContentBox = styled.div`
  width: 100%;
  /* height: 10%; */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #00ee13;
`;
export const Board_PostIndex = styled.div`
  /* width: 10%; */
  height: 100%;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  color: #00ee13;
  /* display: flex; */
  justify-content: center;
  align-items: center;
`;
export const Board_PostTitle = styled.div`
  /* width: 50%; */
  font-family: "Noto Sans KR";
  /* font-size: ${(props) => (props.isAdmin ? "14px" : "13px")}; */
  font-weight: ${(props) => (props.isAdmin ? "bold" : "300")};
  color: #00ee13;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 100%; */
  /* white-space: nowrap; */
  /* overflow-x: scroll; */
`;
export const Board_PostAuthor = styled.div`
  /* width: 15%; */
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  color: #00ee13;
  display: flex;
  /* display: inline-block; */
  justify-content: flex-start;
  align-items: center;
  /* height: 100%; */
  /* white-space: nowrap; */
  /* overflow-x: scroll; */
`;
export const Board_PostCreatedAt = styled.div`
  /* width: 15%; */
  height: 100%;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  color: #00ee13;
  /* display: flex; */
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow-x: scroll;
`;
export const Board_PostReadCount = styled.div`
  /* width: 5%; */
  height: 100%;
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 300;
  color: #00ee13;
  /* margin-left: 30px; */
  /* display: flex; */
  justify-content: flex-start;
  align-items: center;
`;

export const Board_BoardCategoryBar = styled.div`
  /* width: 13%;
  height: 50%; */
  background-color: #1a1a1a;
  border: 4px solid #00ee13;
  position: absolute;
  /* right: -50px; */
  /* bottom: 150px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around; ;
`;
export const Board_CategoryHeader = styled.div`
  color: #00ee13;
  font-family: "Alfredo";
  /* font-size: 16px; */
  font-weight: 600;
`;
export const Board_BoardCategoryContainer = styled.div`
  width: 80%;
  height: 40%;
  /* margin-left: 20px; */
`;
export const Board_BoardCategoryBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

export const Board_BoardSearchContainer = styled.div`
  width: 80%;
  height: 30%;
  margin-left: 20px;
`;
export const Board_BoardSearchBox = styled.select`
  width: 100%;
  height: 20%;
  background-color: transparent;
  color: #00ee13;
  border: none;
  outline: none;
  appearance: none;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 600;
`;
export const Board_FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Board_BoardSearchSelector = styled.option``;

export const Board_SelectorArrow = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${ArrowImg});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
  margin-bottom: 2px;
  transform: rotate(180deg);
`;
export const Board_BoardSearchContent = styled.input`
  width: 90%;
  /* height: 30%; */
  /* margin-top: 10px; */
  outline: none;
  color: #00ee13;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 400;
  background-color: transparent;
  border: 3px solid #00ee13;
`;

// 하단부
export const Board_BoardPageCountBox = styled.div`
  width: 100%;
  height: 5%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00ee13;
`;

export const Board_BoardBtnBox = styled.div`
  /* width: 70%; */
  /* height: 5%; */
  /* margin: 0 auto; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const Board_BoardWriteBtn = styled.div`
  /* width: 8%; */
  /* height: 80%; */
  font-family: "Noto Sans KR";
  /* font-size: 12px; */
  font-weight: 600;
  color: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00ee13; ;
`;

export const Board_UploadRecentIcon = styled.div`
  /* width: 6px; */
  /* height: 6px; */
  /* margin-left: 10px; */
  /* border-radius: 10px; */
  background-image: ${(props) => (props.current ? `url(${Required})` : "")};
  background-repeat: no-repeat;
  background-size: contain;
`;
export const Board_TagForm = styled.form``;
export const Board_TagRadioSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Board_TagRadioLabel = styled.label`
  /* width: 90%; */
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Noto Sans KR";
  font-weight: 600;
  /* font-size: 14px; */
  cursor: grab;
  background-color: ${(props) => (props.selected ? "#00ee13" : "transparent")};
  color: ${(props) => (props.selected ? "black" : "#00ee13")};
`;

//Intro Page < 호출되는 경우의 수가 많아서 자체 렌더

//Admin - QuestionView

export const Admin_Question_AdminDetailContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
`;

export const Admin_Question_NeedHeader = styled.div`
  width: 80%;
  height: 15%;
  display: flex;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 28px;
  font-weight: 600;
  color: #ff02ff;
  background-color: transparent;
`;

export const Admin_Question_FinishedHeader = styled.div`
  width: 80%;
  height: 15%;
  display: flex;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 28px;
  font-weight: 600;
  background-color: transparent;
  color: #00ee13;
`;

export const Admin_Question_NeedContainer = styled.div`
  width: 45%;
  height: 100%;
`;

export const Admin_Question_NeedBox = styled.div`
  width: 100%;
  height: 80%;
  background-color: transparent;
  overflow: scroll;
  border: 2px solid #ff02ff;
`;

export const Admin_Question_FinishedContainer = styled.div`
  width: 45%;
  height: 100%;
`;

export const Admin_Question_FinishedBox = styled.div`
  width: 100%;
  height: 80%;
  background-color: transparent;
  overflow: scroll;
  border: 2px solid #00ee13;
`;

export const Admin_Question_ContentBox = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Admin_Question_NrealNameInfo = styled.div`
  width: 30%;
  height: 50%;
  border-bottom: 1px solid #ff02ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 500;
  color: #ff02ff;
`;
export const Admin_Question_NContactInfo = styled.div`
  width: 40%;
  height: 50%;
  border-bottom: 1px solid #ff02ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 500;
  color: #ff02ff; ;
`;

export const Admin_Question_NActionBtn = styled(Link)`
  width: 20%;
  height: 50%;
  border: 2px solid #ff02ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 600;
  color: #ff02ff;
  cursor: grab;
  &:hover {
    background-color: #ff02ff;
    color: #1a1a1a;
  }
`;

export const Admin_Question_FrealNameInfo = styled.div`
  width: 30%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 13px;
  color: #00ee13;
  font-weight: 500;
  border-bottom: 1px solid #00ee13;
`;
export const Admin_Question_FContactInfo = styled.div`
  width: 40%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  color: #00ee13;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid #00ee13;
`;

export const Admin_Question_FActionBtn = styled(Link)`
  width: 20%;
  height: 50%;
  border: 2px solid #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 600;
  color: #00ee13;
  cursor: grab;
  &:hover {
    background-color: #00ee13;
    color: #1a1a1a;
  }
`;

//Admin - PortFolio

export const Admin_PF_AdminDetailContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
`;

export const Admin_PF_HoldingHeader = styled.div`
  width: 80%;
  height: 15%;
  display: flex;
  align-items: center;
  font-family: "Alfredo";
  font-size: 28px;
  font-weight: 600;
  color: #ff02ff;
  background-color: transparent;
`;

export const Admin_PF_UploadedHeader = styled.div`
  width: 80%;
  height: 15%;
  display: flex;
  align-items: center;
  font-family: "Alfredo";
  font-size: 28px;
  font-weight: 600;
  background-color: transparent;
  color: #00ee13;
`;

export const Admin_PF_HoldingContainer = styled.div`
  width: 45%;
  height: 100%;
`;

export const Admin_PF_HoldingBox = styled.div`
  width: 100%;
  height: 80%;
  background-color: transparent;
  overflow: scroll;
  border: 2px solid #ff02ff;
`;

export const Admin_PF_UploadedContainer = styled.div`
  width: 45%;
  height: 100%;
`;

export const Admin_PF_UploadedBox = styled.div`
  width: 100%;
  height: 80%;
  background-color: transparent;
  overflow: scroll;
  border: 2px solid #00ee13;
`;

export const Admin_PF_ContentBox = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Admin_PF_HCompanyInfo = styled.div`
  width: 30%;
  height: 50%;
  border-bottom: 1px solid #ff02ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 500;
  color: #ff02ff;
`;
export const Admin_PF_HContactInfo = styled.div`
  width: 40%;
  height: 50%;
  border-bottom: 1px solid #ff02ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 500;
  color: #ff02ff; ;
`;

export const Admin_PF_HActionBtn = styled.div`
  width: 20%;
  height: 50%;
  border: 2px solid #ff02ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 600;
  color: #ff02ff;
  cursor: grab;
  &:hover {
    background-color: #ff02ff;
    color: #1a1a1a;
  }
`;

export const Admin_PF_UCompanyInfo = styled.div`
  width: 30%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 13px;
  color: #00ee13;
  font-weight: 500;
  border-bottom: 1px solid #00ee13;
`;
export const Admin_PF_UContactInfo = styled.div`
  width: 40%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  color: #00ee13;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid #00ee13;
`;

export const Admin_PF_UActionBtn = styled.div`
  width: 20%;
  height: 50%;
  border: 2px solid #00ee13;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 600;
  color: #00ee13;
  cursor: grab;
  &:hover {
    background-color: #00ee13;
    color: #1a1a1a;
  }
`;

export const MobileInfoBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileInfoLeftBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileInfoLogoutLeftImg = styled.div`
  /* width: 40px; */
  /* height: 40px; */
  /* margin-left: 10px; */
  /* border-radius: 40px; */
  background-image: ${(props) =>
    props.avatar_url
      ? `url(${props.avatar_url})`
      : `url(${DefaultProfileImg})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #00ee13;
`;

export const MobileInfoLogoutLeftContent = styled.div`
  font-family: "Noto Sans KR";
  font-weight: 600;
`;

export const MobileInfoLogoutRightBox = styled.div`
  /* width: 40px; */
  /* height: 45px; */
  /* font-family: "Alfredo"; */
  /* font-size: 13px; */
  margin-top: 2px;
  background-image: url(${LogOutImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: grab;
`;
