import React, { useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { SLink } from "../../Components/Layout";
import MainLogo from "../../assets/00_index/logo_index.png";
import Header from "../../Components/Header";
import "./responsive.css";
import MoveBg from "../../assets/all/pc_bg_move.gif";
// ** Prop-types 설계 잘해야 나중에 편하다
// import PropTypes from "prop-types";
// import { device } from "../../Devices/devices";

const IndexBox = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

const IndexMainLogo = styled.div`
  background-image: url(${MainLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const MIndexMainLogo = styled.div`
  position: absolute;
  background-image: url(${MainLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
`;

const MIndexLinkBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const MIndexLinkContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
  color: #00ee13;
  font-family: "Noto Sans KR";
  font-weight: 600;
`;
const CommunityOptionText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00ee13;
  font-family: "Noto Sans KR";
  font-weight: 600;
  color: #1a1a1a;
`;
const bodyselecotr = document.getElementsByTagName("body")[0];

function HomePresenter() {
  const [mobilebranch, setmobilebranch] = useState(window.innerWidth);
  let mobilebranchbool;
  window.addEventListener("resize", function () {
    setmobilebranch(window.innerWidth);
  });
  bodyselecotr.style.backgroundImage = `url(${MoveBg})`;
  // mobilebranch = window.innerWidth;
  if (mobilebranch < 1280) {
    mobilebranchbool = true;
  } else if (mobilebranch >= 1280) {
    mobilebranchbool = false;
  }
  if (mobilebranchbool) {
    return (
      <>
        <Helmet>
          <title> Project | FFF</title>
        </Helmet>
        <IndexBox className="homeContainer">
          <MIndexMainLogo className="homeIcon" />
        </IndexBox>

        <MIndexLinkBox className="MIndexLinkBox">
          <MIndexLinkContent className="MindexContent">
            <SLink to="/intro">프프프 알아보기</SLink>
          </MIndexLinkContent>
          <MIndexLinkContent className="MindexContent">
            <SLink to="/portfolio">포트폴리오</SLink>
          </MIndexLinkContent>

          <MIndexLinkContent className="MindexContent">
            <SLink to="/jboard/index=?1"> 커뮤니티</SLink>
            <CommunityOptionText className="MindexOptTxt">
              회원만
            </CommunityOptionText>
          </MIndexLinkContent>
        </MIndexLinkBox>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title> Project | FFF </title>
        </Helmet>
        <Header />
        <IndexBox className="homeContainer">
          <IndexMainLogo className="homeIcon" />
        </IndexBox>
      </>
    );
  }
}

export default HomePresenter;
