import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import HeaderImg from "../assets/all/newlogo.png";
import { opacity, turn_y_axis } from "../Animations";
import TextData from "../Json/headerJson.json";
import { SLink } from "./Layout";
import "./mHeaderres.css";

// 반응형 고민
// import { device } from "../Devices/devices";

const HeaderOptions = styled.ul`
  background-color: #1a1a1a;
  opacity: 0;
  z-index: 2;
`;

// 이미지 fit 적용
const HeaderLogo = styled.div`
  background-image: url(${HeaderImg});
  background-repeat: no-repeat;
  z-index: 5;
`;

const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
`;

const MobileHeader = withRouter(({ location: { pathname }, isadmin, info }) => {
  if (isadmin) {
    return (
      <HeaderContainer className="HeaderContainer">
        <LogoBox className="logoBox">
          <SLink to="/">
            <HeaderLogo
              className="headerLogo"
              src={HeaderImg}
              alt="FFF_LOGO"
              id="FFF_LOGO"
            />
          </SLink>
        </LogoBox>
      </HeaderContainer>
    );
  } else {
    return (
      <HeaderContainer className="HeaderContainer">
        <LogoBox className="logoBox">
          <SLink to="/">
            <HeaderLogo
              className="headerLogo"
              // src={HeaderImg}
              alt="FFF_LOGO"
              id="FFF_LOGO"
            />
          </SLink>
        </LogoBox>
      </HeaderContainer>
    );
  }
});

export default MobileHeader;
