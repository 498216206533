/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        nick_name
        real_name
        avatar_url
        belong_to
        field
        permission {
          auth_realname
          auth_belongto
          auth_field
          auth_homepage
        }
        homepage
        description
        isAdmin
        phone_number
        membership
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      nick_name
      real_name
      avatar_url
      belong_to
      field
      permission {
        auth_realname
        auth_belongto
        auth_field
        auth_homepage
      }
      homepage
      description
      isAdmin
      phone_number
      membership
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        nick_name
        real_name
        avatar_url
        belong_to
        field
        permission {
          auth_realname
          auth_belongto
          auth_field
          auth_homepage
        }
        homepage
        description
        isAdmin
        phone_number
        membership
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPortfolios = /* GraphQL */ `
  query SyncPortfolios(
    $filter: ModelPortfolioFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPortfolios(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        company_name
        business_license
        portfolio_tag {
          tag_content01
          tag_content02
          tag_content03
        }
        portfolio_oneline_description
        portfolio_website
        portfolio_email
        portfolio_attach01 {
          portfolio_link01
          portfolio_url01
        }
        portfolio_attach02 {
          portfolio_link02
          portfolio_url02
        }
        portfolio_attach03 {
          portfolio_link03
          portfolio_url03
        }
        portfolio_full_description
        portfolio_thumbnail_url
        updatedAt
        pf_permission
        user_phone_number
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPortfolio = /* GraphQL */ `
  query GetPortfolio($id: ID!) {
    getPortfolio(id: $id) {
      id
      company_name
      business_license
      portfolio_tag {
        tag_content01
        tag_content02
        tag_content03
      }
      portfolio_oneline_description
      portfolio_website
      portfolio_email
      portfolio_attach01 {
        portfolio_link01
        portfolio_url01
      }
      portfolio_attach02 {
        portfolio_link02
        portfolio_url02
      }
      portfolio_attach03 {
        portfolio_link03
        portfolio_url03
      }
      portfolio_full_description
      portfolio_thumbnail_url
      updatedAt
      pf_permission
      user_phone_number
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const listPortfolios = /* GraphQL */ `
  query ListPortfolios(
    $filter: ModelPortfolioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        business_license
        portfolio_tag {
          tag_content01
          tag_content02
          tag_content03
        }
        portfolio_oneline_description
        portfolio_website
        portfolio_email
        portfolio_attach01 {
          portfolio_link01
          portfolio_url01
        }
        portfolio_attach02 {
          portfolio_link02
          portfolio_url02
        }
        portfolio_attach03 {
          portfolio_link03
          portfolio_url03
        }
        portfolio_full_description
        portfolio_thumbnail_url
        updatedAt
        pf_permission
        user_phone_number
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQuestions = /* GraphQL */ `
  query SyncQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        question_title
        question
        answer_title
        answer
        phone_number
        real_name
        email
        nick_name
        updatedAt
        holding
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question_title
      question
      answer_title
      answer
      phone_number
      real_name
      email
      nick_name
      updatedAt
      holding
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question_title
        question
        answer_title
        answer
        phone_number
        real_name
        email
        nick_name
        updatedAt
        holding
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPosts = /* GraphQL */ `
  query SyncPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPosts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        isAdmin
        category
        recruitmentField
        quotedCost
        writer
        title
        description
        file
        link
        updatedAt
        ReadCount
        isLiked {
          id
          postID
          isLiked
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          id
          postID
          nick_name
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      isAdmin
      category
      recruitmentField
      quotedCost
      writer
      title
      description
      file
      link
      updatedAt
      ReadCount
      isLiked {
        id
        postID
        isLiked
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      comments {
        id
        postID
        nick_name
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isAdmin
        category
        recruitmentField
        quotedCost
        writer
        title
        description
        file
        link
        updatedAt
        ReadCount
        isLiked {
          id
          postID
          isLiked
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        comments {
          id
          postID
          nick_name
          content
          updatedAt
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        postID
        nick_name
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      postID
      nick_name
      content
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        nick_name
        content
        updatedAt
        _version
        _deleted
        _lastChangedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdditionalComments = /* GraphQL */ `
  query SyncAdditionalComments(
    $filter: ModelAdditionalCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdditionalComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        postID
        commentID
        nick_name
        content
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdditionalComment = /* GraphQL */ `
  query GetAdditionalComment($id: ID!) {
    getAdditionalComment(id: $id) {
      id
      postID
      commentID
      nick_name
      content
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAdditionalComments = /* GraphQL */ `
  query ListAdditionalComments(
    $filter: ModelAdditionalCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postID
        commentID
        nick_name
        content
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLikeds = /* GraphQL */ `
  query SyncLikeds(
    $filter: ModelLikedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLikeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        postID
        isLiked
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLiked = /* GraphQL */ `
  query GetLiked($id: ID!) {
    getLiked(id: $id) {
      id
      postID
      isLiked
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listLikeds = /* GraphQL */ `
  query ListLikeds(
    $filter: ModelLikedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        isLiked
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
